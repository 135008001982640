import { css } from "#/css/css"
import { type RouterOutputs, trpc } from "#/trpc"
import { Button } from "@gt/ui"
import { Badge, IconButton, Tooltip } from "@radix-ui/themes"
import dayjs from "dayjs"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
	type MRT_SortingState,
} from "mantine-react-table"
import { match } from "ts-pattern"
import { openOrderDetailsModal } from "./order-details-modal"
import { EyeIcon } from "#/components"
import { reportUserSuccess } from "#/util"
import { MdOutlineDownload } from "react-icons/md"
import { S } from "#/s"

interface OrderHistorySectionProps {
	customerId: number
}

type Order =
	RouterOutputs["v2_5"]["order"]["getByUserAndPage"]["entries"][number]

export const OrderHistorySection = (props: OrderHistorySectionProps) => {
	return (
		<div
			className={css({
				marginTop: 5,
			})}
		>
			<OrderHistorySectionTable customerId={props.customerId} />
		</div>
	)
}

interface OrderHistoryTableSectionProps {
	customerId: number
}

export const OrderHistorySectionTable = (
	props: OrderHistoryTableSectionProps,
) => {
	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } = trpc.v2_5.order.getByUserAndPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				customerId: props.customerId,
			},
		},
		{
			keepPreviousData: true,
		},
	)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.order.exportOrderHistoryTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				customerId: props.customerId,
			},
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderEmptyRowsFallback() {
			return (
				<S.div
					d="flex"
					justifyContent="center"
					alignItems="center"
					h={200}
					fontSize={20}
					color="gray"
				>
					No orders found
				</S.div>
			)
		},
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

const columns: MRT_ColumnDef<Order>[] = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		enableSorting: false,
		Cell: (table) => {
			const orderId = table.row.original.id
			const receiptNumber = table.row.original.receiptNumber?.toString() ?? ""

			return (
				<div>
					<Button
						variant="secondary"
						size="icon"
						onClick={() => openOrderDetailsModal(orderId, receiptNumber)}
						aria-label="open"
					>
						<EyeIcon />
					</Button>
				</div>
			)
		},
	},
	{
		id: "receiptNumber",
		header: "Guide",
		accessorKey: "receiptNumber",
	},
	{
		id: "customer",
		header: "Customer",
		enableSorting: false,
		Cell: (table) => {
			return `${table.row.original?.customer?.firstName ?? ""} ${table.row.original.customer?.lastName ?? ""}`
		},
	},
	{
		id: "associated",
		header: "Associated",
		enableSorting: false,
		Cell: (table) => {
			return `${table.row.original?.associated?.firstName ?? ""} ${table.row.original.associated?.lastName ?? ""}`
		},
	},
	{
		id: "orderStatus",
		header: "Status",
		accessorKey: "orderStatus",
		Cell: (table) => {
			const orderStatus = table.row.original.orderStatus
			const value = `${orderStatus[0]}${orderStatus.substring(1).toLowerCase()}`
			const color = match(orderStatus)
				.with("COMPLETE", () => "green")
				.with("REVERSED", () => "red")
				.with("CANCELLED", () => "gray")
				.with("PENDING", () => "yellow")
				.with("PROCESSING", () => "yellow")
				.with("SHIPPED", () => "cyan")
				.with("READY_TO_PICKUP", () => "purple")
				.otherwise(() => "gray") as any

			return (
				<Badge size="3" radius="full" color={color}>
					{value}
				</Badge>
			)
		},
	},
	{
		id: "total",
		header: "Total",
		accessorKey: "total",
		Cell: (table) => {
			return `$${table.row.original.total.toFixed(2)}`
		},
	},
	{
		id: "createdAt",
		header: "Date",
		accessorKey: "createdAt",
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "origin",
		header: "Origin",
		enableSorting: false,
		Cell: (table) => {
			const orderType = table.row.original.orderType
			return orderType !== "IN_STORE" && orderType !== "PICKUP_FROM_STORE"
				? "E-commerce"
				: "POS"
		},
	},
]
