import GTLoader from "#/components-ng/loader.js"
import DisplayTableContainer from "#/components/DisplayTableContainer/index.jsx"
import { DisplayTable } from "#/components/index.js"
import { trpc } from "#/trpc.js"
import * as M from "@mantine/core"
import dayjs from "dayjs"
import React from "react"
import {
	MdCalendarToday,
	MdOutlineEmail,
	MdOutlineMonetizationOn,
	MdOutlinePhone,
} from "react-icons/md/index.js"
import { openNotePreviewModal } from "./note-preview-modal"

const AddNoteButton = ({
	id,
	defaultNote,
	customerName,
	updatedAt,
}: {
	id: number
	defaultNote?: string
	customerName: string
	updatedAt: Date
}) => {
	return (
		<M.Button
			onClick={() => {
				openNotePreviewModal({
					cartId: id,
					customerName,
					updatedAt,
					note: defaultNote,
				})
			}}
		>
			{defaultNote ? "See note" : "Add note to cart"}
		</M.Button>
	)
}

export const PendingCartContent = ({ id }: { id: number }) => {
	const { data, isLoading } = trpc.v2_5.cart.getCartById.useQuery(
		{
			cartId: id!,
		},
		{
			enabled: Number.isFinite(id),
			cacheTime: 0,
			refetchOnWindowFocus: false,
		},
	)

	const displayData = React.useMemo(() => data?.cartItemSku ?? [], [data])

	if (isLoading)
		return (
			<M.LoadingOverlay
				visible={isLoading}
				loader={<GTLoader width={100} height={100} />}
			/>
		)

	return (
		<M.Container size={1350}>
			<M.Group spacing="xl" align="start">
				<M.Avatar
					src={data?.customer?.avatar}
					size="xl"
					radius={48}
					color="violet"
				>
					{data?.customer?.firstName?.[0] ?? ""}
					{data?.customer?.lastName?.[0] ?? ""}
				</M.Avatar>
				<M.Stack spacing={12} sx={{ flex: "1 !important" }}>
					<M.Text weight="bold">{`${data?.customer?.firstName ?? "-"} ${
						data?.customer?.lastName ?? "-"
					}`}</M.Text>
					<M.Group align="start">
						<M.SimpleGrid
							cols={16}
							sx={(t) => ({
								color: t.colors.gray[6],
								columnGap: "2em",
								rowGap: "0.5em",
								fontSize: "0.9rem",
							})}
						>
							<M.Group noWrap sx={{ gridColumn: "span 5" }}>
								<MdOutlineEmail />
								<M.Text span>{data?.customer?.email || "--"}</M.Text>
							</M.Group>
							<M.Group noWrap sx={{ gridColumn: "span 3" }}>
								<MdOutlinePhone />
								<M.Text span>{data?.customer?.phoneNumber || "--"}</M.Text>
							</M.Group>
							<M.Group noWrap sx={{ gridColumn: "span 2" }}>
								<MdCalendarToday />
								<M.Text span title="Last update">
									{data?.updatedAt
										? dayjs(data?.updatedAt).format("MM/DD/YYYY")
										: "--"}
								</M.Text>
							</M.Group>
							<M.Group
								noWrap
								sx={(t) => ({ gridColumn: "span 2", color: t.colors.green[4] })}
							>
								<MdOutlineMonetizationOn />
								<M.Text span>${data?.total?.toFixed(2)}</M.Text>
							</M.Group>
							<M.Box sx={{ gridColumn: "span 4" }}>
								<AddNoteButton
									id={data!.id}
									defaultNote={data?.note ?? ""}
									updatedAt={data!.updatedAt}
									customerName={`${data?.customer?.firstName ?? ""} ${data?.customer?.lastName ?? ""}`}
								/>
							</M.Box>
						</M.SimpleGrid>
					</M.Group>
				</M.Stack>
			</M.Group>
			<M.Box>
				<DisplayTableContainer>
					<DisplayTable columns={columns} data={displayData} />
				</DisplayTableContainer>
			</M.Box>
		</M.Container>
	)
}

const columns = [
	{
		Header: "Image",
		accessor: "itemSku.defaultImage",
	},
	{
		Header: "SKU",
		accessor: "itemSku.sku",
	},
	{
		Header: "Product name",
		accessor: "itemSku.title",
	},
	{
		Header: "Location store",
		accessor: "storeLocation",
	},
	{
		Header: "QTY",
		accessor: "quantity",
	},
	{
		Header: "Discount",
		accessor: "discount.reference",
	},
	{
		Header: "Subtotal",
		accessor: "subtotal",
		isNumeric: true,
		Cell: ({ value }) => `$${value.toFixed(2)}`,
	},
	{
		Header: "Total",
		accessor: "total",
		isNumeric: true,
		Cell: ({ value }) => `$${value.toFixed(2)}`,
	},
]
