import * as M from "@mantine/core"
import { ChevronBackOutline } from "#/components-ng"
import { css } from "#/s"

export function PreviousButton({
	step,
	previousStep,
}: { step: number; previousStep: () => void }) {
	return (
		<M.Button
			leftIcon={<ChevronBackOutline />}
			disabled={step === 0}
			color="slate.1"
			c="slate.7"
			onClick={previousStep}
			className={css({
				display: step === 2 ? "none" : undefined,
			})}
		>
			Previous
		</M.Button>
	)
}
