import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import {
	C,
	type FormValues,
	generateSalesByCountriesReportSchema,
} from "./types"
import { useState, useEffect, useRef } from "react"
import { trpc } from "#/trpc"
import { reportUserError } from "#/util"
import { css, S } from "#/s"
import { Button } from "@gt/ui"
import { FormProvider, useFormContext } from "react-hook-form"
import { DatePickerInput } from "@mantine/dates"
import axios from "axios"
import { topojson } from "chartjs-chart-geo"
import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"
import Chart from "chart.js/auto"
import {
	ChoroplethController,
	GeoFeature,
	ColorScale,
	ProjectionScale,
} from "chartjs-chart-geo"
import {
	MantineReactTable,
	MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import { Select } from "@mantine/core"
import { MultiSelect } from "#/components-ng"

Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale)

countries.registerLocale(enLocale)

type ProcessedDataItem = {
	feature: {
		type: string
		properties: {
			name: string
			[key: string]: any
		}
		geometry: {
			type: string
			coordinates: any[]
		}
	}
	countryCode: string | null
	countryName: string
	sales: number
	totalOrder: number
}

export const SalesByCountriesReport = () => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	])

	const [processedData, setProcessedData] = useState<ProcessedDataItem[]>([])
	const [isLoadingGeoData, setIsLoadingGeoData] = useState(false)

	// map ref to the canvas element
	const chartRef = useRef(null)
	const ordersChartRef = useRef(null)

	// chart instance ref to store the chart instance
	const chartInstance = useRef(null)
	const ordersChartInstance = useRef(null)

	const form = C.useForm({
		resolver: zodResolver(generateSalesByCountriesReportSchema),
		shouldUnregister: false,
		defaultValues: {
			dateRange: [dateRange[0]!, dateRange[1]!],
			filter: {
				by: "entity",
				ids: [1],
			},
		},
	})

	const { data: entities } = trpc.entity.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})
	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getSalesByCountriesReport.useMutation()

	const entityOptions = useMemo(
		() =>
			entities?.map((entity) => ({
				name: entity.name,
				id: entity.id,
			})) ?? [],
		[entities],
	)

	const filialOptions = useMemo(
		() =>
			filials?.map((filial) => ({
				name: filial.name,
				id: filial.id,
			})) ?? [],
		[filials],
	)

	useEffect(() => {
		if (!report) return

		const processData = async () => {
			setIsLoadingGeoData(true)
			try {
				const geoCountries = await getCountries()

				const geoCountries_ = topojson.feature(
					geoCountries,
					geoCountries.objects.countries,
					//@ts-ignore
				).features

				const countriesData = countries.getNames("en", { select: "official" })
				const countryNameToCode = {}

				Object.entries(countriesData).forEach(([code, name]) => {
					countryNameToCode[name] = code
				})

				const additionalMappings: Record<string, string> = {
					"Dominican Republic": "DO",
					"Dominican Rep.": "DO",
					Dominicana: "DO",
					"United States of America": "US",
					"United States": "US",
					USA: "US",
					"United Kingdom": "GB",
					"Great Britain": "GB",
					UK: "GB",
					Russia: "RU",
					"Russian Federation": "RU",
					China: "CN",
					"People's Republic of China": "CN",
					"South Korea": "KR",
					"Republic of Korea": "KR",
					"North Korea": "KP",
					"Democratic People's Republic of Korea": "KP",
					Iran: "IR",
					"Islamic Republic of Iran": "IR",
					Venezuela: "VE",
					"Bolivarian Republic of Venezuela": "VE",
					Bolivia: "BO",
					"Plurinational State of Bolivia": "BO",
					Syria: "SY",
					"Syrian Arab Republic": "SY",
					Tanzania: "TZ",
					"United Republic of Tanzania": "TZ",
					Vietnam: "VN",
					"Viet Nam": "VN",
				}

				Object.entries(additionalMappings).forEach(([name, code]) => {
					countryNameToCode[name] = code
				})

				const result = geoCountries_.map((country) => {
					let countryCode = countryNameToCode[country.properties.name]

					if (!countryCode) {
						const normalizedCountryName = country.properties.name
							.toLowerCase()
							.trim()

						for (const [name, code] of Object.entries(countryNameToCode)) {
							const normalizedName = name.toLowerCase().trim()

							if (
								normalizedCountryName === normalizedName ||
								normalizedCountryName.includes(normalizedName) ||
								normalizedName.includes(normalizedCountryName) ||
								normalizedCountryName.split(" ")[0] ===
									normalizedName.split(" ")[0]
							) {
								countryCode = code
								break
							}
						}

						if (!countryCode) {
							console.log(`No mapping found for: "${country.properties.name}"`)
						}
					}

					return {
						feature: country,
						countryCode: countryCode,
						countryName: country.properties.name,
						sales: (countryCode && report?.salesByCountries[countryCode]) ?? 0,
						totalOrder:
							(countryCode && report?.totalOrdersByCountries[countryCode]) ?? 0,
					}
				})

				setProcessedData(result)
			} catch (error) {
				reportUserError({
					title: "Error processing geographic data",
				})
			} finally {
				setIsLoadingGeoData(false)
			}
		}

		processData()
	}, [report])

	// Render maps
	useEffect(() => {
		if (!processedData.length) return

		// Function to create the chart map with the provided data
		const createChart = (
			chartRef: React.MutableRefObject<HTMLCanvasElement | null>,
			chartInstanceRef: React.MutableRefObject<Chart | null>,
			valueType: "sales" | "orders",
			title: string,
			colorScheme: [number, number, number],
		) => {
			if (!chartRef.current) return

			// Destroy the chart instance if it exists
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy()
			}

			// get the maximum value of the data
			const maxValue = Math.max(
				...processedData.map((d) =>
					valueType === "sales" ? d.sales : d.totalOrder,
				),
			)

			// Data for the chart
			const data = {
				labels: processedData.map((d) => d.feature.properties.name),
				datasets: [
					{
						label: title,
						data: processedData.map((d) => ({
							feature: d.feature,
							value: valueType === "sales" ? d.sales : d.totalOrder,
						})),
						borderColor: "#FFF",
						backgroundColor: (context) => {
							// Verify that the context is valid
							if (
								!context ||
								!context.dataset ||
								!context.dataset.data ||
								context.dataIndex === undefined ||
								!context.dataset.data[context.dataIndex]
							) {
								return "rgba(220, 220, 220, 0.8)" // Default color
							}

							// Get the value of the data point
							const value = context.dataset.data[context.dataIndex].value || 0

							// Use a logarithmic scale to improve the visualization of small values
							if (value === 0) return "rgba(220, 220, 220, 0.8)"

							// Calculate the intensity of the color based on the value
							const logBase = Math.log(maxValue || 1) / Math.log(10)
							const intensity =
								value === 0
									? 0
									: 0.2 +
										(0.8 * (Math.log(value) / Math.log(10))) / (logBase || 1)

							// Return the color based on the intensity
							const [r, g, b] = colorScheme
							return `rgba(${r}, ${g}, ${b}, ${Math.max(0.1, intensity)})`
						},
					},
				],
			}

			// Configuration for the chart
			const config = {
				type: "choropleth",
				data: data,
				options: {
					showOutline: true,
					showGraticule: true,
					plugins: {
						legend: {
							display: false,
						},
						title: {
							display: false,
							text: title,
							font: {
								size: 16,
							},
						},
					},
					scales: {
						projection: {
							axis: "x",
							projection: "equalEarth",
						},
						color: {
							axis: "x",
							quantize: 7,
							legend: {
								position: "bottom-right",
								align: "bottom",
							},
							interpolate: (v) => {
								const [r, g, b] = colorScheme
								return `rgba(${r}, ${g}, ${b}, ${Math.max(0.1, 0.2 + v * 0.8)})`
							},
						},
					},
				},
			}

			// Create the chart instance
			chartInstanceRef.current = new Chart(chartRef.current, config as any)
		}

		// sales chart
		createChart(
			chartRef,
			chartInstance,
			"sales",
			"Sales by Country",
			[65, 105, 225],
		)

		// total orders chart
		createChart(
			ordersChartRef,
			ordersChartInstance,
			"orders",
			"Orders by Country",
			[46, 139, 87],
		)
	}, [processedData])

	const handleSubmit = (values: FormValues) => {
		generateReport({
			dateRange: {
				from: values.dateRange[0],
				to: values.dateRange[1],
			},
			filter: {
				by: values.filter.by,
				ids: values.filter.ids,
			},
		})
	}

	const filterBy = form.watch("filter.by")

	return (
		<S.div gap="1rem" d="flex" flexDir="column">
			<S.h2>Sales By Countries Report </S.h2>
			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.stopPropagation()
						form.handleSubmit(handleSubmit)(e)
					}}
				>
					<S.div
						display="flex"
						gap="1rem"
						flexDir={{
							base: "column",
							md: "row",
						}}
					>
						<C.M
							as={Select}
							label="Report type"
							name="filter.by"
							data={[
								{ label: "Entity", value: "entity" },
								{ label: "Filial", value: "filial" },
								{ label: "E-commerce", value: "ecommerce" },
							]}
							required
						/>
						{filterBy !== "ecommerce" && (
							<EntityFilialMultiselect
								label={
									filterBy === "entity" ? "Select entity" : "Select filial"
								}
								options={filterBy === "entity" ? entityOptions : filialOptions}
								placeholder={
									filterBy === "entity"
										? "Select entity..."
										: "Select filial..."
								}
							/>
						)}
						<C.M
							as={DatePickerInput}
							label="Date range"
							name="dateRange"
							type="range"
							onChange={(v) => {
								if (!v) return
								if (v[0] == null || v[1] == null) return
								setDateRange([v[0], v[1]])
							}}
							numberOfColumns={2}
							popoverProps={{
								zIndex: 9000,
							}}
							w={300}
							required
						/>
						<Button
							type="submit"
							isLoading={isLoading}
							className={css({
								marginTop: "1.25rem",
								paddingX: "3rem",
							})}
						>
							Generate
						</Button>
					</S.div>
				</form>
			</FormProvider>
			<S.hr />

			{isLoadingGeoData ? (
				<S.div p="2rem" textAlign="center">
					Loading geographic data...
				</S.div>
			) : !report ? (
				<S.div p="2rem" textAlign="center">
					Please select a date range to generate the report
				</S.div>
			) : (
				<S.div>
					<S.div d="flex" gap="3" mb="1rem">
						<S.div>
							<S.p fw="600">
								Total sales:{" "}
								<S.span fw="400">${report.totalSales.toFixed(2)}</S.span>
							</S.p>
						</S.div>
						<S.p>|</S.p>
						<S.div>
							<S.p fw="600">
								Total orders: <S.span fw="400">{report.totalOrders}</S.span>
							</S.p>
						</S.div>
					</S.div>
					<S.hr />

					<S.div
						d="grid"
						gridTemplateColumns={{
							base: "1fr",
							md: "1fr 1fr",
						}}
						my="2rem"
						gridGap="2"
					>
						<S.div
							h="400px"
							w="100%"
							maxW="650px"
							position="relative"
							d="flex"
							justifyContent="center"
							alignItems="center"
							flexDir="column"
						>
							<S.h3>Sales by country</S.h3>
							<canvas ref={chartRef}></canvas>
						</S.div>

						<S.div
							h="400px"
							w="100%"
							maxW="650px"
							position="relative"
							d="flex"
							justifyContent="center"
							alignItems="center"
							flexDir="column"
						>
							<S.h3>Total orders by country</S.h3>
							<canvas ref={ordersChartRef}></canvas>
						</S.div>
					</S.div>

					<SalesReportByCountriesTable
						data={
							processedData
								.filter((d) => d.sales > 0 || d.totalOrder > 0)
								.sort((a, b) => b.sales - a.sales) ?? []
						}
					/>
				</S.div>
			)}
		</S.div>
	)
}

const getCountries = async () => {
	try {
		const response = await axios.get(
			"https://unpkg.com/world-atlas/countries-50m.json",
		)
		return response.data
	} catch (error) {
		reportUserError({
			title: "Error fetching countries",
		})
		throw error
	}
}

interface SalesReportByCountriesTableProps {
	data: ProcessedDataItem[]
}

const SalesReportByCountriesTable = (
	props: SalesReportByCountriesTableProps,
) => {
	const { data } = props

	const table = useMantineReactTable({
		columns,
		data: data ?? [],
		enableTopToolbar: false,
	})

	return (
		<S.div mt="2rem">
			<MantineReactTable table={table} />
		</S.div>
	)
}

const columns: MRT_ColumnDef<ProcessedDataItem>[] = [
	{
		id: "countryName",
		header: "Country",
		accessorKey: "countryName",
	},
	{
		id: "sales",
		header: "Sales (USD)",
		accessorKey: "sales",
		Cell: (table) => {
			const original = table.row.original
			return <p>${original.sales.toFixed(2)}</p>
		},
	},
	{
		id: "totalOrder",
		header: "Orders",
		accessorKey: "totalOrder",
		Cell: (table) => {
			const original = table.row.original
			return <p>{original.totalOrder}</p>
		},
	},
]

interface OptionMultiselect {
	id: number
	name: string
}

interface EntityFilialMultiselectProps {
	label: string
	options: OptionMultiselect[]
	placeholder?: string
}

const EntityFilialMultiselect = (props: EntityFilialMultiselectProps) => {
	const form = useFormContext()
	const ids = form.watch("filter.ids")
	const filterBy = form.watch("filter.by")

	const [selectedIds, setSelectedIds] = useState<OptionMultiselect[]>(
		ids.map((id) => ({ id, name: id.toString() })) ?? [],
	)

	useEffect(() => {
		if (filterBy === "entity") {
			setSelectedIds([{ id: 1, name: "1" }])
		}
	}, [filterBy])

	const handleEntityFilialChange = (ids: OptionMultiselect[]) => {
		form.setValue(
			"filter.ids",
			ids.map((id) => id.id),
		)
		setSelectedIds(ids)
	}

	return (
		<MultiSelect
			label={props.label}
			data={props.options}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleEntityFilialChange}
			placeholder={props.placeholder}
			value={selectedIds}
		/>
	)
}
