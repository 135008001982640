import Card from "#/components/Card/index.js"
import CategoryGridLayout from "#/components/CategoryGridLayout"
import { CustomersIcon } from "#/components/icons"
import { useAuth } from "#/context/AuthContext"
import * as M from "@mantine/core"
import { AiOutlineLineChart } from "react-icons/ai/index.js"
import { MdOutlineNotificationsActive } from "react-icons/md"
import PricetagIcon from "~icons/ion/pricetag-outline"

export const Reports = () => {
	const [{ auth }] = useAuth()

	return (
		<M.Stack spacing={20}>
			<CategoryGridLayout category="Global">
				<M.Group spacing={50}>
					<Card
						icon={<AiOutlineLineChart size={24} />}
						text="Sales"
						path="sales"
						module="reports.salesReports"
					/>
					<Card
						icon={<CustomersIcon size="big" />}
						text="New Customers"
						path="new-customers"
						module="reports.newCustomersReports"
					/>
					<Card
						icon={<AiOutlineLineChart size={24} />}
						text="Sales Report By Customer"
						path="sales-report-by-customer"
						module="reports.salesReportByCustomer"
					/>
					<Card
						icon={<AiOutlineLineChart size={24} />}
						text="Sales By Countries"
						path="sales-by-countries-report"
						module="reports.salesByCountriesReport"
					/>
					<Card
						icon={<AiOutlineLineChart size={24} />}
						text="Performance Report"
						path="performance-report"
						module="reports.performanceReport"
					/>
					{auth &&
						(auth.role.name === "ROOT" ||
							auth.role.name === "ENTITY MANAGER") && (
							<>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="E-commerce Sales"
									path="ecommerce-sales"
									module="reports.ecommerceSalesReports"
								/>
								<Card
									icon={<MdOutlineNotificationsActive size={24} />}
									text="Notification upsell"
									path="notification-upsell"
									module="reports.notificationUpsellReports"
								/>
								<Card
									icon={<PricetagIcon fontSize={24} />}
									text="Promotion report"
									path="promotion-report"
									module="reports.promotionReport"
								/>
								<Card
									icon={<AiOutlineLineChart fontSize={24} />}
									text="General sales report"
									path="general-sales-report"
									module="reports.generalSalesReport"
								/>
								<Card
									icon={<CustomersIcon size="big" />}
									text="Customers report"
									path="customers-report"
									module="reports.customersReport"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Products Most Sold"
									path="products-most-sold"
									module="reports.productsMostSoldReports"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Products Most Sold E-commerce"
									path="products-most-sold-ecommerce"
									module="reports.productsMostSoldReportsEcommerce"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Beta: Products Segmentation"
									path="products-segmentation"
									module="reports.productsSegmentationReports"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Products With Most Gross Profit"
									path="products-with-most-gp"
									module="reports.productsWithMostGpReports"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Products With Most Net Profit"
									path="products-with-most-np"
									module="reports.productsWithMostNpReports"
								/>
							</>
						)}
					{auth &&
						auth.role.name === "ROOT" &&
						auth.user.email === "userrootprivate@email.com" && (
							<>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Customers With Most Orders"
									path="customers-with-most-orders"
									module="reports.customersWithMostOrdersReports"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Customers With Most Gross Profit"
									path="customers-with-most-gp"
									module="reports.customersWithMostGpReports"
								/>
								<Card
									icon={<AiOutlineLineChart size={24} />}
									text="Customers With Most Lifetime Value"
									path="customers-with-most-lv"
									module="reports.customersWithMostLvReports"
								/>
							</>
						)}
				</M.Group>
			</CategoryGridLayout>
		</M.Stack>
	)
}
