import * as M from "@mantine/core"
import { trpc } from "#/trpc"
import { useState } from "react"
import { css, S } from "#/s"
import { customerAtom } from "../state"
import { Button } from "@gt/ui"
import { SurveySentModal } from "./survey-sent-modal"

type SurveyListModalProps = {
	setOpened: (opened: boolean) => void
} & M.ModalProps

type I18NTitle = {
	en: string
	es: string
}

export function SurveyListModal(props: SurveyListModalProps) {
	const customer = useAtomValue(customerAtom)
	const { data: surveys } = trpc.v2_5.form.getManyBy.useQuery(
		{
			submitted: false,
			userId: customer?.id!,
		},
		{
			enabled: customer != null,
		},
	)
	const [selectedFormUuid, setSelectedFormUuid] = useState<string>()
	const [sentEmail, setSentEmail] = useState<boolean>(false)

	function handleSelectForm(formUuid: string) {
		setSelectedFormUuid(formUuid)
	}

	function handleClose() {
		setSelectedFormUuid(undefined)
		setSentEmail(false)
		props.onClose?.()
	}

	const { mutate: sendEmail, isLoading } =
		trpc.v2_5.form.sendFormEmail.useMutation({
			onSuccess() {
				props?.onClose?.()
				setSentEmail(true)
			},
		})

	return (
		<>
			<M.Modal
				title="Available Surveys"
				centered
				{...props}
				onClose={handleClose}
				classNames={{
					title: css({
						fontWeight: "medium",
					}),
				}}
			>
				<S.div d="grid" gap="4">
					<S.p c="#535862" fs="15px">
						Select a survey to send to your customer. The link will be emailed
						to the address.
					</S.p>
					{surveys?.map((survey) => {
						const title = survey.title as I18NTitle
						return (
							<M.Paper
								component="button"
								key={survey.uuid}
								p="md"
								withBorder
								radius="md"
								onClick={() => {
									if (selectedFormUuid) {
										setSelectedFormUuid(undefined)
										return
									}
									handleSelectForm(survey.uuid)
								}}
								className={css({
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "sky.100",
									},
									borderColor:
										selectedFormUuid === survey.uuid ? "sky.400" : "gray.300",
									backgroundColor:
										selectedFormUuid === survey.uuid ? "sky.100" : "white",
									borderWidth: selectedFormUuid === survey.uuid ? "2px" : "1px",
								})}
							>
								<S.div
									d="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<S.p fw="medium">{title?.en ?? "Untitled Survey"}</S.p>
									<M.Checkbox
										checked={selectedFormUuid === survey.uuid}
										radius="xl"
									/>
								</S.div>
							</M.Paper>
						)
					})}
					<S.div d="flex" gap="4" w="100%">
						<Button
							className={css({
								flex: 1,
							})}
							variant="secondary"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button
							className={css({
								flex: 1,
							})}
							disabled={!selectedFormUuid}
							isLoading={isLoading}
							onClick={() => {
								if (selectedFormUuid && customer) {
									sendEmail({
										uuid: selectedFormUuid,
										userId: customer.id,
									})
								}
							}}
						>
							Send survey
						</Button>
					</S.div>
				</S.div>
			</M.Modal>

			{sentEmail && (
				<SurveySentModal
					opened={selectedFormUuid != null}
					onClose={handleClose}
					backToAvailableSurveys={() => {
						props.setOpened(true)
						setSentEmail(false)
						setSelectedFormUuid(undefined)
					}}
				/>
			)}
		</>
	)
}
