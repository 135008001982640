import { S } from "#/s"
import { type RouterOutputs, trpc } from "#/trpc"
import { reportUserSuccess } from "#/util"
import { Button } from "@gt/ui"
import { NumberInput } from "@mantine/core"
import { modals } from "@mantine/modals"
import { lastEntryAddedIndexAtom } from "./state"
import { useSetAtom } from "jotai/react"

type CartItemSku =
	RouterOutputs["v2_5"]["cart"]["getCartItemSkus"]["cartItemSku"][number]

interface VerifyItemManuallyModalProps {
	cartItemSku: CartItemSku
}

export const openVerifyItemManuallyModal = (
	props: VerifyItemManuallyModalProps,
) => {
	modals.open({
		children: <VerifyItemManuallyModal {...props} />,
		size: "md",
		centered: true,
		modalId: "modal-verify-item-manually",
		zIndex: 1300,
	})
}

const VerifyItemManuallyModal = (props: VerifyItemManuallyModalProps) => {
	const ctx = trpc.useContext()
	const [verifiedQuantity, setVerifiedQuantity] = useState<number>(
		() => props?.cartItemSku?.verifiedQuantity ?? 0,
	)
	const setLastEntryAddedIndex = useSetAtom(lastEntryAddedIndexAtom)

	const { mutate: verifyItem, isLoading } =
		trpc.v2_5.cart.verifyItem.useMutation({
			onSuccess() {
				ctx.v2_5.cart.getCartItemSkus.invalidate()
				reportUserSuccess({
					title: "Item verified",
				})
				modals.close("modal-verify-item-manually")

				setLastEntryAddedIndex({
					sku: props.cartItemSku.itemSku.sku,
				})
			},
		})

	const handleSave = () => {
		if (verifiedQuantity < 0) {
			modals.close("modal-verify-item-manually")
			return
		}

		verifyItem({
			cartId: props.cartItemSku.cartId,
			cartItemSkuId: props.cartItemSku.id,
			verifiedQuantity,
		})
	}

	return (
		<div>
			<S.div d="flex" flexDir="column" gap="2">
				<S.p c="neutral.700" fw="500">
					SKU: {props.cartItemSku?.itemSku?.sku}
				</S.p>
				<S.p c="neutral.700" fw="500">
					Verified quantity{" "}
					<S.span>
						{props.cartItemSku?.verifiedQuantity ?? 0}/
						{props.cartItemSku.quantity}
					</S.span>
				</S.p>
				<S.hr />
				<NumberInput
					value={verifiedQuantity}
					onChange={(value) => setVerifiedQuantity(Number(value ? value : 0))}
					min={0}
					label="Enter verified quantity"
					placeholder="Enter verified quantity"
				/>
				<S.div d="flex" alignItems="center" justifyContent="flex-end">
					<Button onClick={handleSave} isLoading={isLoading}>
						Save
					</Button>
				</S.div>
			</S.div>
		</div>
	)
}
