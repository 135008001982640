import { S } from "#/s"

export function TwoColumnsLayout({ left, right }) {
	return (
		<S.div d="grid" gridTemplateColumns="1fr auto 1fr">
			<S.div>{left}</S.div>
			<S.div w="2px" h="100%" bg="slate.200" mx="8" />
			<S.div>{right}</S.div>
		</S.div>
	)
}
