import { S } from "#/s"
import { useMolecule } from "bunshi/react"
import { CustomerInfo } from "../../customer-info"
import { formMolecule } from "../../state"
import { TwoColumnsLayout } from "../../two-columns-layout"

export function VerifyStep() {
	return (
		<S.div>
			<TwoColumnsLayout
				left={
					<S.div>
						<S.p bg="#568f64" c="white" p="2" fs="sm" rounded="md">
							This account will be kept and will contain the data from the
							secondary account
						</S.p>
						<S.div h="5" />
						<PrimaryAccountInfo />
					</S.div>
				}
				right={
					<S.div>
						<S.p bg="#E14F50" c="white" p="2" fs="sm" rounded="md">
							This account will be deleted after merging its data into the
							primary account
						</S.p>
						<S.div h="5" />
						<SecondaryAccountInfo />
					</S.div>
				}
			/>
		</S.div>
	)
}

function PrimaryAccountInfo() {
	const form = useMolecule(formMolecule)
	const primaryAccount = useAtomValue(form.primaryAccountAtom)

	if (primaryAccount == null) return null

	return <CustomerInfo customer={primaryAccount} />
}

function SecondaryAccountInfo() {
	const form = useMolecule(formMolecule)
	const secondaryAccount = useAtomValue(form.secondaryAccountAtom)

	if (secondaryAccount == null) return null

	return <CustomerInfo customer={secondaryAccount} dimmed />
}
