import { css } from "#/css/css"
import { type RouterOutputs, trpc } from "#/trpc"
import { Button } from "@gt/ui"
import dayjs from "dayjs"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
	type MRT_SortingState,
} from "mantine-react-table"
import { match } from "ts-pattern"
import { EyeIcon } from "#/components"
import { UserCreditsEdit } from "./edit-user-credits-modal"
import { openOrderDetailsModal } from "../order-history/order-details-modal"
import { openReturnDetailsModal } from "../return-history/return-details-modal"
import { reportUserSuccess } from "#/util"
import { IconButton, Tooltip } from "@radix-ui/themes"
import { MdOutlineDownload } from "react-icons/md"
import { S } from "#/s"

type CreditLog = NonNullable<
	RouterOutputs["v2_5"]["user"]["getCreditsLogsByUserAndPage"]["entries"][number]
>

interface CreditsHistorySectionProps {
	customerId: number
}

export const CreditsHistorySection = (props: CreditsHistorySectionProps) => {
	return (
		<div
			className={css({
				marginTop: 5,
				width: "100%",
			})}
		>
			<div
				className={css({
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "10px",
				})}
			>
				<UserCreditsEdit />
			</div>
			<UserCreditsHistoryTable customerId={props.customerId} />
		</div>
	)
}

interface UserCreditsHistoryTableProps {
	customerId: number
}

const UserCreditsHistoryTable = (props: UserCreditsHistoryTableProps) => {
	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } =
		trpc.v2_5.user.getCreditsLogsByUserAndPage.useQuery(
			{
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				sorting: querySorting,
				filters: {
					customerId: props.customerId,
				},
			},
			{
				keepPreviousData: true,
			},
		)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.user.exportUserCreditsHistoryByUserTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				customerId: props.customerId,
			},
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderEmptyRowsFallback() {
			return (
				<S.div
					d="flex"
					justifyContent="center"
					alignItems="center"
					h={200}
					fontSize={20}
					color="gray"
				>
					No user credits history found
				</S.div>
			)
		},
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

const columns: MRT_ColumnDef<CreditLog>[] = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		enableSorting: false,
		Cell: (table) => {
			const type = table.row.original.type
			const guide = table.row.original.guide

			const handleOpenModal = () => {
				console.log(guide)

				if (!guide) {
					return
				}

				if (type === "PURCHASE") {
					const orderId = table.row.original.info[0].order?.id

					if (!orderId) {
						return
					}

					openOrderDetailsModal(orderId, guide.toString())
					return
				}

				if (type === "REFUND" || type === "REVERSE_REFUND") {
					const refundId = table.row.original.info[0].refund?.id

					if (!refundId) {
						return
					}

					openReturnDetailsModal(guide)
					return
				}
			}

			return (
				<div>
					{type !== "ADJUST" && (
						<Button
							variant="secondary"
							size="icon"
							onClick={handleOpenModal}
							aria-label="open"
						>
							<EyeIcon />
						</Button>
					)}
				</div>
			)
		},
	},
	{
		id: "date",
		header: "Date",
		accessorKey: "createdAt",
		Cell: (table) => {
			const createdAt = table.row.original?.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "guide",
		header: "Guide",
		accessorKey: "guide",
		Cell: (table) => {
			const text = match(table.row.original?.type)
				.with("PURCHASE", () => table.row.original?.guide)
				.with("REFUND", () => `R${table.row.original?.guide}`)
				.with("REVERSE_REFUND", () => `R${table.row.original?.guide}`)
				.with("ADJUST", () => table.row.original?.guide)
				.otherwise(() => "")

			return text
		},
	},
	{
		id: "type",
		header: "Type",
		accessorKey: "type",
	},
	{
		id: "total",
		header: "Total",
		accessorKey: "total",
		Cell: (table) => {
			return `$${table.row.original?.total?.toFixed(2) ?? ""}`
		},
	},
]
