import { trpc } from "#/trpc.js";
import { reportUserError, useWindowFocus } from "#/util/index.js";
import { addProductAtom, searchAtom, addProductsAtom } from "../state.js";
import * as M from "@mantine/core";
import { useAtom, useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import useScanDetection from "use-scan-detection/dist/index.js";
import SearchIcon from "~icons/ic/baseline-search";

export const AddItemsSection = () => {
  const windowsFocused = useWindowFocus();
  const addProduct = useSetAtom(addProductAtom);
  const [search, setSearch] = useAtom(searchAtom);

  return (
    <>
      <M.Group grow noWrap position="apart">
        <ProductInput
          onChange={(product) => {
            addProduct({
              id: product.id,
              sku: product.sku,
              title: product.title,
              availQty: product.stock,
              newQty: product.stock,
              cost: product.cost ?? 0,
              diffQty: 0,
              department: product.department,
              category: product.category,
              storeLocation: product.storeLocation,
              warehouseLocation: product.warehouseLocation,
            });
          }}
        />
        <CategoryDepartmentSelect />
        <LocationSelect />
        <M.Group position="right" className="w-24">
          <M.TextInput
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value.toLowerCase())}
            icon={<SearchIcon />}
            classNames={{
              input: "rounded-lg",
            }}
            placeholder="Search"
            label=" "
          />
        </M.Group>
      </M.Group>
      {!windowsFocused && (
        <M.Dialog
          opened={true}
          sx={(t) => ({ borderTop: `solid 3px ${t.colors.yellow[4]}` })}
        >
          <M.Text>Window must be focused for the scanner to work.</M.Text>
        </M.Dialog>
      )}
    </>
  );
};

const ProductInput = ({ onChange, ...props }) => {
  const form = useFormContext();
  const filialId = form.watch("filial");
  const [query, setQuery] = useState<string>("");
  const { data } = trpc.itemSku.search.useQuery(
    { query: `\\"${query}\\"` },
    {
      enabled: query.length > 0,
      onError: (err) => {
        reportUserError({
          title: "Failed to get products",
          message: err.message,
        });
      },
    }
  );

  const productOptions = useMemo(
    () =>
      data?.map((e) => ({
        label: `${e?.sku} - ${e?.title}`,
        value: e?.id.toString() ?? "",
        product: e,
      })) ?? [],
    [data]
  );
  useScanDetection({
    onComplete: (dataPreprocessed: string) => {
      (async () => {
        const dataSkuNumber = Number.parseInt(dataPreprocessed.toString());
        if(Number.isSafeInteger(dataSkuNumber)){
          setQuery(dataSkuNumber.toString());
        }
      })();
    },
    stopPropagation: true,
  });

  return (
    <>
      <M.Select
        label="Add item(s)"
        classNames={{
          input: "rounded-lg",
        }}
        placeholder="Enter item"
        required
        data={productOptions}
        searchable
        onInput={(e) => setQuery(e.currentTarget.value)}
        zIndex={9998}
        filter={() => true}
        value={null}
        onChange={(id) => {
          const newProduct =
            productOptions.find((o) => o.value === id)?.product ?? null;

          if (newProduct) {
            onChange({
              id: newProduct.id,
              sku: newProduct.sku,
              title: newProduct.title,
              stock:
                newProduct.itemSkuStock?.find(
                  (s) => s.filialId === Number.parseInt(filialId)
                )?.quantity ?? 0,
              cost: newProduct?.cost?.toNumber() ?? 0,
              department: newProduct.item.department.name,
              category: newProduct.item.category.name,
              storeLocation:
                newProduct.itemSkuStock?.find(
                  (s) => s.filialId === Number.parseInt(filialId)
                )?.storeLocation ?? "",
              warehouseLocation:
                newProduct.itemSkuStock?.find(
                  (s) => s.filialId === Number.parseInt(filialId)
                )?.warehouseLocation ?? "",
            });
          }
        }}
        {...props}
      />
    </>
  );
};

const CategoryDepartmentSelect = ({ ...props }) => {
  const form = useFormContext();
  const filialId = form.watch("filial");
  const addProducts = useSetAtom(addProductsAtom);

  const { data: categoryData } = trpc.category.getAll.useQuery();
  const { data: departmentData } = trpc.department.getAll.useQuery();

  const options = useMemo(() => {
    const categories =
      categoryData?.map((e) => ({
        label: e.name,
        value: `C${e.id.toString()}`, // `C` for `Category
        group: "Category",
      })) ?? [];
    const departments =
      departmentData?.map((e) => ({
        label: e.name,
        value: `D${e.id.toString()}`, // `D` for `Department
        group: "Department",
      })) ?? [];
    return [...categories, ...departments];
  }, [categoryData, departmentData]);

  const { mutate: getItemSkusCategory } =
    trpc.category.getItemSkusAssociated.useMutation({
      onError: (err) => {
        reportUserError({
          title: "Failed to get products",
          message: err.message,
        });
      },
      onSuccess: (data) => {
        addProducts(
          data?.map((e) => {
            const itemSkuStock = e.itemSkuStock?.find(
              (s) => s.filialId === Number.parseInt(filialId)
            );
            return {
              id: e.id,
              sku: e.sku,
              title: e.title,
              cost: e?.cost?.toNumber() ?? 0,
              department: e.item.department.name,
              category: e.item.category.name,
              storeLocation: itemSkuStock?.storeLocation ?? "",
              warehouseLocation: itemSkuStock?.warehouseLocation ?? "",
              availQty: itemSkuStock?.quantity ?? 0,
              diffQty: 0,
              newQty: itemSkuStock?.quantity ?? 0,
            };
          }) ?? []
        );
      },
    });

  const { mutate: getItemSkusDepartment } =
    trpc.department.getItemSkusAssociated.useMutation({
      onError: (err) => {
        reportUserError({
          title: "Failed to get products",
          message: err.message,
        });
      },
      onSuccess: (data) => {
        addProducts(
          data?.map((e) => {
            const itemSkuStock = e.itemSkuStock?.find(
              (s) => s.filialId === Number.parseInt(filialId)
            );
            return {
              id: e.id,
              sku: e.sku,
              title: e.title,
              cost: e?.cost?.toNumber() ?? 0,
              department: e.item.department.name,
              category: e.item.category.name,
              storeLocation: itemSkuStock?.storeLocation ?? "",
              warehouseLocation: itemSkuStock?.warehouseLocation ?? "",
              availQty: itemSkuStock?.quantity ?? 0,
              diffQty: 0,
              newQty: itemSkuStock?.quantity ?? 0,
            };
          }) ?? []
        );
      },
    });

  return (
    <M.Select
      data={options}
      onChange={(e) => {
        if (!e) return;
        if (e.startsWith("C")) {
          getItemSkusCategory({
            categoryId: Number(e.slice(1)),
          });
        }
        if (e.startsWith("D")) {
          getItemSkusDepartment({
            departmentId: Number(e.slice(1)),
          });
        }
      }}
      label="Add item(s) by department or category"
      placeholder="Enter department or category"
      classNames={{
        input: "rounded-lg",
      }}
      searchable
      value={null}
      {...props}
    />
  );
};

const LocationSelect = ({ ...props }) => {
  const form = useFormContext();
  const filialId = form.watch("filial");
  const addProducts = useSetAtom(addProductsAtom);
  const { data: locationsData } = trpc.itemSkuStock.getLocations.useQuery();

  const options = useMemo(() => {
    return (
      locationsData?.map((e) => ({
        label: e!,
        value: e!,
      })) ?? []
    );
  }, [locationsData]);

  const { mutate: getItemByLocation } =
    trpc.itemSkuStock.getByLocation.useMutation({
      onError: (err) => {
        reportUserError({
          title: "Failed to get products",
          message: err.message,
        });
      },
      onSuccess: (data) => {
        addProducts(
          data?.map((e) => {
            const itemSkuStock = e.itemSkuStock?.find(
              (s) => s.filialId === Number.parseInt(filialId)
            );
            return {
              id: e.id,
              sku: e.sku,
              title: e.title,
              cost: e?.cost?.toNumber() ?? 0,
              department: e.item.department.name,
              category: e.item.category.name,
              storeLocation: itemSkuStock?.storeLocation ?? "",
              warehouseLocation: itemSkuStock?.warehouseLocation ?? "",
              availQty: itemSkuStock?.quantity ?? 0,
              diffQty: 0,
              newQty: itemSkuStock?.quantity ?? 0,
            };
          }) ?? []
        );
      },
    });

  return (
    <M.Select
      data={options}
      onChange={(e) => {
        if (!e) return;
        getItemByLocation({ location: e });
      }}
      label="Add item(s) by location"
      placeholder="Enter location"
      classNames={{
        input: "rounded-lg",
      }}
      searchable
      value={null}
      limit={20}
      {...props}
    />
  );
};
