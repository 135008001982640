import {
  FileUpload,
  UseS3UploadObject,
  UseS3UploadResult,
} from "#/components-ng/ui/index.js";
import { makeMantineController } from "#/components/Form/v3/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { cn } from "#/lib/utils";
import {
  useS3PresignedUrl,
  reportUserError,
  getTimezones,
} from "#/util/index.js";
import * as M from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import countryTelData from "country-telephone-data";
import Decimal from "decimal.js";
import { uniqBy } from "lodash";
import React from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { MdOutlineFileUpload } from "react-icons/md/index.js";
import CameraIcon from "~icons/ion/camera-outline";
import CloseIcon from "~icons/ion/close-circle-outline";
import EditIcon from "~icons/ion/create-outline";

const createInputStyles = ({ labelSize }: { labelSize: number | string }) => {
  return {
    root: { display: "flex", alignItems: "center" },
    label: { width: labelSize },
    disabled: {
      background: "transparent !important",
      color: "#222 !important",
      border: "none",
      cursor: "default !important",
    },
    input: { width: "30ch" },
  };
};

const basicInfoInputStyles = createInputStyles({
  labelSize: "20ch",
});

const settingsInputStyles = createInputStyles({
  labelSize: "20ch",
});

const addressInputStyles = createInputStyles({
  labelSize: "20ch",
});

export interface FilialFormValues {
  entityId: number;
  name: string;
  phoneNumber: string;
  disclosure: string;
  termsAndConditions: string;
  verified: boolean;
  avatar: string;
  areaCode: string;
  countryCode: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  taxRate: number | Decimal;
  about: string;
  adminPin: string;
  newArrivalSectionImage?: string | null;
  defaultImage?: string | null;
  email?: string | null;
  timezone?: string | null;
  brevis?: string | null;
  salesWithoutStock: boolean;
  notifyLowerStock: boolean;
  type: "SHOP" | "WAREHOUSE";
}

const Mc = makeMantineController({ ctx: {} as FilialFormValues });

const SectionTitle: React.FC<{
  editable?: boolean;
  onEditClick?: () => void;
  children?: any;
}> = ({ children, editable = true, onEditClick }) => {
  return (
    <M.Group noWrap w={300}>
      <M.Title order={2} size="md">
        {/* @ts-ignore */}
        {children}
      </M.Title>
      {editable && (
        <M.ActionIcon onClick={onEditClick}>
          <EditIcon />
        </M.ActionIcon>
      )}
    </M.Group>
  );
};

export type RolesAllowed = "FILIAL MANAGER" | "ROOT" | "ENTITY MANAGER";

export const fileTermsAtom = atom<{
  url: string;
  isLoading: boolean;
} | null>(null);

export const fileDisclosureAtom = atom<{
  url: string;
  isLoading: boolean;
} | null>(null);

interface FilialFormProps {
  defaultValues?: FilialFormValues;
  isLoading: boolean;
  onSubmit: (values: FilialFormValues) => void;
  rolesAllowed: RolesAllowed[];
}

export const FilialForm = (props: FilialFormProps) => {
  const form = useForm<FilialFormValues>({
    defaultValues: props.defaultValues,
  });
  const [{ auth }] = useAuth();
  const [fileTerms, setFileTerms] = useAtom(fileTermsAtom);
  const [fileDisclosure, setFileDisclosure] = useAtom(fileDisclosureAtom);

  const [uploadPhoto, { isLoading: isPhotoLoading, fileUrl: avatar }] =
    useS3PresignedUrl({
      onSuccess: ({ fileUrl }) => {
        form.setValue("avatar", fileUrl);
      },
    });

  const joinedAreaCode = `${form.watch("countryCode")}/${form.watch(
    "areaCode"
  )}`;
  const areaCodes = uniqBy(
    countryTelData.allCountries.map((c) => ({
      label: `${getUnicodeFlagIcon(c.iso2)} +${
        c.dialCode
      } (${c.iso2.toUpperCase()})`,
      value: `${c.iso2.toUpperCase()}/+${c.dialCode}`,
    })),
    (c) => c.value
  );

  const [basicInfoEditable, setBasicInfoEditable] = React.useState(false);
  const [settingsEditable, setSettingsEditable] = React.useState(false);
  const [addressEditable, setAddressEditable] = React.useState(false);

  const handleS3UploadingStartTerms = (upload: UseS3UploadObject) => {
    setFileTerms({
      url: upload.url!,
      isLoading: true,
    });
  };
  const handleS3UploadSuccessTerms = (upload: UseS3UploadResult) => {
    setFileTerms({
      url: upload.url!,
      isLoading: false,
    });
    form.setValue("termsAndConditions", upload.url!);
  };

  const handleS3UploadingStartDisclosure = (upload: UseS3UploadObject) => {
    setFileDisclosure({
      url: upload.url!,
      isLoading: true,
    });
  };
  const handleS3UploadSuccessDisclosure = (upload: UseS3UploadResult) => {
    setFileDisclosure({
      url: upload.url!,
      isLoading: false,
    });
    form.setValue("disclosure", upload.url!);
  };

  const timezones = getTimezones();

  return (
    <M.Box bg="white" py="lg" px={38}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(props.onSubmit)}>
          <M.Title order={1}>Filial</M.Title>
          <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
          <M.Stack>
            <M.Group noWrap align="start">
              <SectionTitle
                editable={props.rolesAllowed.includes(auth?.role.name as any)}
                onEditClick={() => setBasicInfoEditable((prev) => !prev)}
              >
                Basic information
              </SectionTitle>
              <M.Stack>
                <M.Box>
                  <M.Box w={84} pos="relative">
                    <M.Avatar radius={48} size="xl" src={form.watch("avatar")}>
                      GT
                    </M.Avatar>
                    <M.FileButton
                      accept={IMAGE_MIME_TYPE.join(",")}
                      onChange={(file) => {
                        if (file) {
                          uploadPhoto(file, "entities");
                        }
                      }}
                    >
                      {(props) => {
                        return (
                          <M.ActionIcon
                            color="brand"
                            variant="filled"
                            radius="xl"
                            size="sm"
                            pos="absolute"
                            bottom={0}
                            right={0}
                            {...props}
                          >
                            <CameraIcon fontSize={12} />
                          </M.ActionIcon>
                        );
                      }}
                    </M.FileButton>
                  </M.Box>
                </M.Box>
                <Mc
                  as={M.TextInput}
                  name="name"
                  label="Name"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                  required
                />
                <Mc
                  as={M.Select}
                  name="areaCode"
                  label="Area Code"
                  data={areaCodes}
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                  value={
                    areaCodes.find((c) => c.value === joinedAreaCode)?.value
                  }
                  onChange={(value) => {
                    if(!value) {
                      return
                    }
                    const parts = value!.split("/");
                    const countryCode = parts[0];
                    const areaCode = parts[1];
                    form.setValue("countryCode", countryCode);
                    form.setValue("areaCode", areaCode);
                  }}
                  searchable
                  nothingFound="No options"
                  clearable
                  required
                />
                <Mc
                  as={M.TextInput}
                  name="phoneNumber"
                  label="PhoneNumber"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                  onInput={(e) => {
                    // @ts-ignore
                    const value = e.target.value;
                    const onlyNumbers = value.replace(/[^0-9]/g, "");
                    // @ts-ignore
                    e.target.value = onlyNumbers;
                  }}
                  required
                />
                <Mc
                  as={M.TextInput}
                  name="about"
                  label="About"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                />
                <Mc
                  as={M.TextInput}
                  name="email"
                  label="Email"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                />
                <Mc
                  as={M.Select}
                  data={timezones}
                  name="timezone"
                  label="Timezone"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                  searchable
                  clearable
                />
                <Mc
                  as={M.TextInput}
                  name="brevis"
                  label="Brevis"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                />
              </M.Stack>
            </M.Group>
            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start">
              <SectionTitle
                editable={props.rolesAllowed.includes(auth?.role.name as any)}
                onEditClick={() => setSettingsEditable((prev) => !prev)}
              >
                Settings
              </SectionTitle>
              <M.Stack>
                <Mc
                  as={M.TextInput}
                  name="adminPin"
                  label="Admin PIN"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  type="password"
                />
                <Mc
                  as={M.NumberInput}
                  name="taxRate"
                  label="Tax Rate"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  precision={2}
                  min={0}
                  required
                />
                <Mc
                  as={M.Switch}
                  name="verified"
                  label="Verified"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  labelPosition="left"
                />
                <Mc
                  as={M.Switch}
                  name="salesWithoutStock"
                  label="Sales Without Stock"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  labelPosition="left"
                />
                <Mc
                  as={M.Switch}
                  name="notifyLowerStock"
                  label="Notify Lower Stock"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  labelPosition="left"
                />
                <Mc
                  as={M.Select}
                  name="type"
                  label="Type"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  data={[{ value: "SHOP", label: "SHOP"}, { value: "WAREHOUSE", label: "WAREHOUSE"}]}
                  required
                  defaultValue={"SHOP"}
                />
                <Mc
                  as={M.TextInput}
                  name="disclosure"
                  label="Disclosure"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  rightSection={
                    <M.Loader
                      size="xs"
                      visibility={
                        fileDisclosure && fileDisclosure.isLoading
                          ? "visible"
                          : "hidden"
                      }
                    />
                  }
                  className="pointer-events-none"
                />
                {settingsEditable && (
                  <FileUpload
                    directory="filials"
                    label="Disclosure File"
                    onS3UploadSuccess={handleS3UploadSuccessDisclosure}
                    onS3UploadingStart={handleS3UploadingStartDisclosure}
                  />
                )}
                <Mc
                  as={M.TextInput}
                  name="termsAndConditions"
                  label="Terms And Conditions"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  rightSection={
                    <M.Loader
                      size="xs"
                      visibility={
                        fileTerms && fileTerms.isLoading ? "visible" : "hidden"
                      }
                    />
                  }
                  className="pointer-events-none"
                />
                {settingsEditable && (
                  <FileUpload
                    directory="filials"
                    label="Terms And Conditions File"
                    onS3UploadSuccess={handleS3UploadSuccessTerms}
                    onS3UploadingStart={handleS3UploadingStartTerms}
                  />
                )}
              </M.Stack>
            </M.Group>
            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start">
              <SectionTitle
                editable={props.rolesAllowed.includes(auth?.role.name as any)}
                onEditClick={() => setAddressEditable((prev) => !prev)}
              >
                Address
              </SectionTitle>
              <M.Stack>
                <Mc
                  as={M.TextInput}
                  name="street"
                  label="Address"
                  styles={addressInputStyles}
                  disabled={!addressEditable}
                />
                <Mc
                  as={M.TextInput}
                  name="state"
                  label="State"
                  styles={addressInputStyles}
                  disabled={!addressEditable}
                />
                <Mc
                  as={M.TextInput}
                  name="city"
                  label="City"
                  styles={addressInputStyles}
                  disabled={!addressEditable}
                />
                <Mc
                  as={M.TextInput}
                  name="country"
                  label="Country"
                  styles={addressInputStyles}
                  disabled={!addressEditable}
                />
                <Mc
                  as={M.TextInput}
                  name="zip"
                  label="Zip code"
                  styles={addressInputStyles}
                  disabled={!addressEditable}
                />
              </M.Stack>
            </M.Group>

            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start">
              <SectionTitle editable={false}>Default Image</SectionTitle>
              <M.Stack>
                <DropzoneDefaultImage rolesAllowed={props.rolesAllowed} />
              </M.Stack>
            </M.Group>

            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start">
              <SectionTitle editable={false}>
                New Arrival Section Image
              </SectionTitle>
              <M.Stack>
                <DropzoneNewArrivalSectionImage
                  rolesAllowed={props.rolesAllowed}
                />
              </M.Stack>
            </M.Group>

            <M.Group noWrap align="start" mt="lg">
              <SectionTitle editable={false} />
              <M.Stack>
                {props.rolesAllowed.includes(auth?.role.name as any) ? (
                  <M.Button type="submit" px="6ch" loading={props.isLoading}>
                    Save
                  </M.Button>
                ) : null}
              </M.Stack>
            </M.Group>
          </M.Stack>
        </form>
      </FormProvider>
    </M.Box>
  );
};

interface DropzoneDefaultImageProps {
  rolesAllowed: RolesAllowed[];
}
const DropzoneDefaultImage = ({ rolesAllowed }: DropzoneDefaultImageProps) => {
  const form = useFormContext<FilialFormValues>();
  const [{ auth }] = useAuth();
  const defaultImage = form.watch("defaultImage");
  const newArrivalSectionImage = form.watch("newArrivalSectionImage");

  const [uploadDefaulImage, uploadStateDefaulImage] = useS3PresignedUrl({
    onSuccess: ({ fileUrl }) => {
      form.setValue(
        "newArrivalSectionImage",
        newArrivalSectionImage ? newArrivalSectionImage : null
      );
      form.setValue("defaultImage", fileUrl);
    },
  });
  const handleDropDefaultImage = async (files) => {
    await uploadDefaulImage(files[0], "filials");
  };

  return defaultImage ? (
    <M.Box style={{ position: "relative", width: "100%" }}>
      <M.Box style={{ width: "100%" }}>
        <M.Image src={form.watch("defaultImage")} height={300} width={380} />
        {rolesAllowed.includes(auth?.role.name as any) ? (
          <M.ActionIcon
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            name="delete-default-image"
            onClick={() => {
              form.setValue("defaultImage", null);
            }}
          >
            <CloseIcon fontSize={20} color="red" />
          </M.ActionIcon>
        ) : null}
      </M.Box>
    </M.Box>
  ) : (
    <Dropzone
      accept={IMAGE_MIME_TYPE}
      px="lg"
      py={16}
      onDrop={handleDropDefaultImage}
      sx={{ gridRowEnd: "span 3" }}
      name="defaultImage"
      disabled={!rolesAllowed.includes(auth?.role.name as any)}
      onReject={() => {
        reportUserError({
          title: "Invalid file type",
          message: "Please upload a valid image file",
        });
      }}
      className={cn(
        !rolesAllowed.includes(auth?.role.name as any) && "cursor-not-allowed"
      )}
    >
      <Dropzone.Idle>
        <M.Group align="center" spacing="sm" sx={{ position: "relative" }}>
          <M.Box sx={(t) => ({ color: t.colors.brand[4] })}>
            <MdOutlineFileUpload color="inherit" fontSize="4rem" />
          </M.Box>
          <M.Stack spacing={0}>
            <M.Text>
              Drop your image here or{" "}
              <M.Text component="span" color="brand">
                browse
              </M.Text>
            </M.Text>
            <M.Text color="gray" size="sm">
              Supports: JPG, PNG, SVG,
            </M.Text>
          </M.Stack>
          <M.LoadingOverlay visible={uploadStateDefaulImage.isLoading} />
        </M.Group>
      </Dropzone.Idle>
    </Dropzone>
  );
};

interface DropzoneNewArrivalSectionImageProps {
  rolesAllowed: RolesAllowed[];
}
const DropzoneNewArrivalSectionImage = ({
  rolesAllowed,
}: DropzoneNewArrivalSectionImageProps) => {
  const form = useFormContext<FilialFormValues>();
  const [{ auth }] = useAuth();
  const defaultImage = form.watch("defaultImage");
  const newArrivalSectionImage = form.watch("newArrivalSectionImage");

  const [uploadNewArrivalSectionImage, uploadStateNewArrivalImage] =
    useS3PresignedUrl({
      onSuccess: ({ fileUrl }) => {
        form.setValue("defaultImage", defaultImage ? defaultImage : null);
        form.setValue("newArrivalSectionImage", fileUrl);
      },
    });

  const handleDropNewArrivalSectionImage = async (files) => {
    await uploadNewArrivalSectionImage(files[0], "filials");
  };

  return newArrivalSectionImage ? (
    <M.Box style={{ position: "relative", width: "100%" }}>
      <M.Box style={{ width: "100%" }}>
        <M.Image
          src={form.watch("newArrivalSectionImage")}
          height={300}
          width={380}
        />
        {rolesAllowed.includes(auth?.role.name as any) ? (
          <M.ActionIcon
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            onClick={() => {
              form.setValue("newArrivalSectionImage", null);
            }}
            name="delete-new-arrival-section-image"
          >
            <CloseIcon fontSize={20} color="red" />
          </M.ActionIcon>
        ) : null}
      </M.Box>
    </M.Box>
  ) : (
    <Dropzone
      accept={IMAGE_MIME_TYPE}
      onReject={() => {
        reportUserError({
          title: "Invalid file type",
          message: "Please upload a valid image file",
        });
      }}
      px="lg"
      py={16}
      onDrop={handleDropNewArrivalSectionImage}
      sx={{ gridRowEnd: "span 3" }}
      name="newArrivalSectionImage"
      disabled={!rolesAllowed.includes(auth?.role.name as any)}
      className={cn(
        !rolesAllowed.includes(auth?.role.name as any) && "cursor-not-allowed"
      )}
    >
      <Dropzone.Idle>
        <M.Group align="center" spacing="sm" sx={{ position: "relative" }}>
          <M.Box sx={(t) => ({ color: t.colors.brand[4] })}>
            <MdOutlineFileUpload color="inherit" fontSize="4rem" />
          </M.Box>
          <M.Stack spacing={0}>
            <M.Text>
              Drop your image here or{" "}
              <M.Text component="span" color="brand">
                browse
              </M.Text>
            </M.Text>
            <M.Text color="gray" size="sm">
              Supports: JPG, PNG, SVG,
            </M.Text>
          </M.Stack>
          <M.LoadingOverlay visible={uploadStateNewArrivalImage.isLoading} />
        </M.Group>
      </Dropzone.Idle>
    </Dropzone>
  );
};
