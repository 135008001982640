import { modals } from "@mantine/modals"
import { OrderHistorySectionTable } from "../preview/sections"
import { S } from "#/s"

interface OpenOrdersModalByUserProps {
	userId: number
}

export const openOrdersModalByUser = (props: OpenOrdersModalByUserProps) => {
	modals.open({
		children: <OrdersModalByUser {...props} />,
		size: "auto",
		centered: true,
		modalId: "modal-orders-by-user",
	})
}

const OrdersModalByUser = (props: OpenOrdersModalByUserProps) => {
	return (
		<S.div d="flex" flexDir="column" gap="4">
			<S.h3>Orders</S.h3>
			<OrderHistorySectionTable customerId={props.userId} />
		</S.div>
	)
}
