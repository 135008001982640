import { S, css } from "#/s"
import * as M from "@mantine/core"
import { useState } from "react"
import { TutorialFormFields } from "./fields"
import type { LanguageTab } from "./types"
import { FormProvider } from "react-hook-form"
import { C, tutorialSchema, type TutorialFormSchema } from "./form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuth } from "#/context/AuthContext"

interface TutorialFormProps {
	defaultValues?: TutorialFormSchema
	onSubmit: (formData: TutorialFormSchema) => void
}

export function TutorialForm({ onSubmit, defaultValues }: TutorialFormProps) {
	const [activeTab, setActiveTab] = useState<LanguageTab>("english")

	const form = C.useForm({
		values: {
			url: {
				en: "",
				es: "",
			},
			title: {
				en: "",
				es: "",
			},
			description: {
				en: "",
				es: "",
			},
			itemSkus: [],
			updatedById: 0,
			...defaultValues,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
		resolver: zodResolver(tutorialSchema),
	})

	const [{ auth }] = useAuth()
	useEffect(() => {
		if (auth?.user != null) {
			form.setValue("updatedById", auth.user.id)
		}
	}, [auth])

	console.log(form.formState.errors)

	const handleSubmit = (values: TutorialFormSchema) => {
		console.log(values)
		onSubmit(values)
	}

	return (
		<FormProvider {...form}>
			<S.form onSubmit={form.handleSubmit(handleSubmit)}>
				<M.Tabs
					value={activeTab}
					onTabChange={(value) => setActiveTab(value as LanguageTab)}
					className={css({ mb: "2rem" })}
				>
					<M.Tabs.List>
						<M.Tabs.Tab value="english">English</M.Tabs.Tab>
						<M.Tabs.Tab value="spanish">Spanish</M.Tabs.Tab>
					</M.Tabs.List>
				</M.Tabs>

				<TutorialFormFields activeLanguage={activeTab} />

				<S.div>
					<M.Button type="submit" color="blue" className={css({ px: "2rem" })}>
						Save tutorial
					</M.Button>
				</S.div>
			</S.form>
		</FormProvider>
	)
}
