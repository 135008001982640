import { Field as BasicField, FieldProps as BasicFieldProps } from "./field.js";
import { InputStateWrapper } from "./input-state-wrapper.js";
import { Input, InputProps } from "./input.js";
import {
  MantineController,
  MantineControllerProps,
} from "./mantine-controller.js";
import { Switch } from "./switch.js";
import { Textarea, TextareaProps } from "./textarea.js";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select.js";
import { SelectProps } from "@radix-ui/react-select";
import dayjs from "dayjs";
import { get } from "lodash";
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useState,
} from "react";
import {
  ArrayPath,
  Control,
  FieldPath,
  FieldValues,
  useController,
  useFieldArray,
  UseFieldArrayProps,
  useForm,
  useFormContext,
  UseFormProps,
  useWatch,
  Controller as ControllerPrimitive,
  ControllerProps as ControllerPrimitiveProps,
  UseWatchProps,
  UseFormReturn,
  SubmitHandler,
  FormProvider,
  UseControllerProps,
} from "react-hook-form";

export interface ControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext extends object = any,
  Props extends object = any
> {
  // Written in PascalCase to avoid conflict with props from the wrapped component
  As: React.ComponentType<Props>;
  name: TName;
  defaultValue?: any;
  value?: any;
  map?: (v: any) => any;
  unmap?: (v: any) => any;
  control?: Control<TFieldValues, TContext>;
  ctx?: TFieldValues;
  noNullish?: boolean;
}

export const Controller = <
  Props extends object,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TContext extends object = any
>(
  props: ControllerProps<TFieldValues, TName, TContext, Props> &
    Omit<Props, "name">
) => {
  // We provide `as` as an alternative because `wrap` is a prop in some elements (e.g. textarea)
  const Child = props.As;
  const context = useFormContext<TFieldValues>();
  if (!context) {
    throw new Error("component must be used under a FormProvider");
  }

  const {
    field,
    fieldState: { error },
  } = useController({
    control: props.control ?? context.control,
    name: props.name,
    defaultValue: props.defaultValue,
    rules: {
      required:
        ((props as any)["isRequired"] || (props as any)["required"]) &&
        "Field required",
    },
  });

  const {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    defaultValue,
    ctx,
    name,
    control,
    As: as,
    noNullish,
    map,
    unmap,
    ...childProps
  } = props;

  const onChange = field.onChange;
  const valueProps: any = {
    value: props.value ?? (unmap ? unmap(field.value) : field.value),
  };
  //if (Child === Switch || Child === Checkbox) {
  //  onChange = (e) => field.onChange(e.currentTarget.checked);
  //  valueProps = { checked: props.value ?? field.value };
  //} else if (Child === NumberInput) {
  //  onChange = (v?: number) => {
  //    if (v == null && noNullish) {
  //      field.onChange(0);
  //    } else {
  //      field.onChange(v);
  //    }
  //  };
  //}

  return (
    // @ts-ignore
    <Child
      {...field}
      {...childProps}
      error={error?.message}
      onChange={(v: any) => {
        onChange(map ? map(v) : v);
        // @ts-ignore
        if (props.onChange) {
          // @ts-ignore
          props.onChange(v);
        }
      }}
      {...valueProps}
    />
  );
};

/**
 *
 * @example
 * const Mc = makeController({ ctx: {} as InputProduct });
 * // error: `descriatiption` is not a name in `InputProduct`
 * <Mc as={M.TextInput} name="descriatiption" />
 */
export const makeController = <
	TFieldValues extends FieldValues = FieldValues,
>() => ({
	C: <Props extends object = any>(
		props: ControllerProps<TFieldValues, FieldPath<TFieldValues>, any, Props> &
			Omit<Props, "name">,
	) => <Controller {...props} />,
	useForm: (props?: UseFormProps<TFieldValues, any>) =>
		useForm<TFieldValues>(props),
	useFormContext: () => useFormContext<TFieldValues>(),
	useFieldArray: <TKeyName extends string = "key">(
		props: UseFieldArrayProps<TFieldValues, ArrayPath<TFieldValues>, TKeyName>,
	) =>
		useFieldArray<TFieldValues, ArrayPath<TFieldValues>, TKeyName>({
			...props,
			keyName: props.keyName ?? ("key" as TKeyName),
		}),
	useWatch: ((props: UseWatchProps<TFieldValues>) =>
		useWatch(props as any)) as typeof useWatch,
	useController: (<TName extends FieldPath<TFieldValues>>(
		props: UseControllerProps<TFieldValues, TName>,
	) => useController(props)) as typeof useController,
	Controller: <TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
		props: ControllerPrimitiveProps<TFieldValues, TName>,
	) => <ControllerPrimitive {...props} />,
	SelectField,
	SwitchField,
	InputField,
	TextareaField,
	NumberInputField,
	Field,
	Form,
	M: <Props extends object = any>(
		props: MantineControllerProps<
			TFieldValues,
			FieldPath<TFieldValues>,
			any,
			Props
		> &
			Omit<Props, "name">,
	) => {
		return <MantineController {...props} />;
	},
});

function SelectField<TFieldValues extends FieldValues>(
  props: {
    label?: string;
    name: FieldPath<TFieldValues>;
    data: Array<any>;
    fromId?: (id: string) => any;
    toId?: (value: any) => string;
    classNames?: FieldProps<TFieldValues>["classNames"] & { content?: string };
    placeholder?: string;
  } & SelectProps
) {
  const { label, name, ...selectProps } = props;
  const controller = useController<TFieldValues>({
    name,
  });

  return (
    <Field label={label} name={name} classNames={props.classNames}>
      {({ id }) => (
        <Select
          {...selectProps}
          onValueChange={(selectedId) =>
            controller.field.onChange(
              props.fromId ? props.fromId(selectedId) : selectedId
            )
          }
          name={controller.field.name}
          // @ts-ignore
          value={
            props.toId
              ? props.toId(controller.field.value)
              : controller.field.value
          }
        >
          <SelectTrigger id={id}>
            <SelectValue placeholder={props.placeholder} />
          </SelectTrigger>
          <SelectContent className={props.classNames?.content}>
            {props.data.map(({ id, label }) => (
              <SelectItem key={id} value={id}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    </Field>
  );
}

function SwitchField<TFieldValues extends FieldValues>(props: {
  name: FieldPath<TFieldValues>;
  label?: string;
  classNames?: FieldProps<TFieldValues>["classNames"] & {
    switch?: string;
  };
}) {
  const controller = useController<TFieldValues>({
    name: props.name,
  });
  return (
    <Field label={props.label} name={props.name} classNames={props.classNames}>
      {({ id }) => (
        <Switch
          id={id}
          onCheckedChange={(v) => {
            controller.field.onChange(v as any);
          }}
          name={controller.field.name}
          checked={controller.field.value}
          className={props.classNames?.switch}
        />
      )}
    </Field>
  );
}

function InputField<TFieldValues extends FieldValues>(
  props: {
    label?: string;
    name: FieldPath<TFieldValues>;
    orientation?: "vertical" | "horizontal";
    classNames?: FieldProps<TFieldValues>["classNames"] & {
      input?: string;
    };
  } & InputProps
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, name, onChange, ...inputProps } = props;
  const controller = useController<TFieldValues>({
    name,
  });

  return (
    <Field
      label={label}
      name={name}
      orientation={props.orientation}
      classNames={props.classNames}
    >
      {({ id }) => (
        <Input
          id={id}
          type="text"
          {...inputProps}
          className={props.classNames?.input}
          onChange={controller.field.onChange}
          onBlur={controller.field.onBlur}
          name={controller.field.name}
          value={
            inputProps.type === "date"
              ? dayjs(controller.field.value).format("YYYY-MM-DD")
              : controller.field.value
          }
        />
      )}
    </Field>
  );
}

function TextareaField<TFieldValues extends FieldValues>(
  props: {
    label?: string;
    name: FieldPath<TFieldValues>;
    orientation?: "vertical" | "horizontal";
    classNames?: FieldProps<TFieldValues>["classNames"] & {
      input?: string;
    };
  } & TextareaProps
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, name, onChange, ...inputProps } = props;
  const controller = useController<TFieldValues>({
    name,
  });

  return (
    <Field
      label={label}
      name={name}
      orientation={props.orientation}
      classNames={props.classNames}
    >
      {({ id }) => (
        <Textarea
          id={id}
          {...inputProps}
          className={props.classNames?.input}
          onChange={controller.field.onChange}
          onBlur={controller.field.onBlur}
          name={controller.field.name}
          value={controller.field.value}
        />
      )}
    </Field>
  );
}

function NumberInputField<TFieldValues extends FieldValues>(
  props: {
    label?: string;
    name: FieldPath<TFieldValues>;
    orientation?: "vertical" | "horizontal";
    classNames?: FieldProps<TFieldValues>["classNames"] & {
      input?: string;
    };
  } & InputProps
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, name, onChange, ...inputProps } = props;
  const [numberValue, setNumberValue] = useState<number | null>();
  const [stringValue, setStringValue] = useState("");
  const controller = useController<TFieldValues>({
    name,
  });
  const stringifiedValue = controller.field.value.toString();
  if (stringifiedValue !== stringValue) {
    if (typeof controller.field.value === "number") {
      setNumberValue(controller.field.value);
    }
    setStringValue(stringifiedValue);
  }

  return (
    <Field
      label={label}
      name={name}
      orientation={props.orientation}
      classNames={props.classNames}
    >
      {({ id }) => (
        <Input
          id={id}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          {...inputProps}
          className={props.classNames?.input}
          onChange={(e) => {
            setStringValue(e.target.value);
            const numberifiedValue = Number(e.target.value);
            if (!Number.isNaN(numberifiedValue)) {
              setNumberValue(numberifiedValue);
              controller.field.onChange(numberifiedValue as any);
            }
          }}
          onBlur={() => {
            controller.field.onBlur();
            controller.field.onChange(numberValue as any);
          }}
          name={controller.field.name}
          value={stringValue}
        />
      )}
    </Field>
  );
}

type FieldProps<TFieldValues extends FieldValues> = BasicFieldProps & {
  name: FieldPath<TFieldValues>;
  label?: string;
  classNames?: {
    field?: string;
    inputWrapper?: string;
  };
};

function Field<TFieldValues extends FieldValues>(
  props: FieldProps<TFieldValues>
) {
  const form = useFormContext();
  const error = get(form.formState.errors, `${props.name}.message`);

  return (
    <BasicField label={props.label} className={props.classNames?.field}>
      {(field) => (
        <InputStateWrapper
          error={error?.toString()}
          className={props.classNames?.inputWrapper}
        >
          {props.children(field)}
        </InputStateWrapper>
      )}
    </BasicField>
  );
}

export type FormProps<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  children?: ReactNode;
} & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  "onSubmit"
>;
function Form<TFieldValues extends FieldValues>(
  props: FormProps<TFieldValues>
) {
  return (
    <form {...props} onSubmit={props.form.handleSubmit(props.onSubmit)}>
      <FormProvider {...props.form}>{props.children}</FormProvider>
    </form>
  );
}
