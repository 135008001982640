import { trpc, type RouterOutputs } from "#/trpc"
import { Button, Combobox, Dropdown } from "@gt/ui"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import { Flex } from "#/css/jsx"
import { IconButton } from "@radix-ui/themes"
import { EllipsisHorizontal } from "#/components-ng"
import placeholderImage from "#/placeholder-image.jpg"
import { css } from "#/css/css"
import dayjs from "dayjs"
import { reportUserError, reportUserSuccess } from "#/util"
import { CatalogImageFormModal } from "./catalog-image-form-modal"
import type { CatalogImageFormValues } from "./types"
import { v4 as uuidv4 } from "uuid"
import { modals } from "@mantine/modals"
import { CreateCatalogImageInput } from "./create-catalog-image"
import { S } from "#/s"
import { openSortCatalogImagesModal } from "./sort-catalog-images-modal"

interface CatalogImagesTableProps {
	catalogId: number
}

export const CatalogImagesTable = (props: CatalogImagesTableProps) => {
	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})

	const { data, isLoading, isFetchedAfterMount } =
		trpc.v2_5.catalog.getCatalogImagesByPage.useQuery(
			{
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				filters: {
					catalogId: props.catalogId,
				},
			},
			{
				cacheTime: 0,
			},
		)

	const catalogImages = useMemo(() => data?.entries ?? [], [data?.entries])

	const table = useMantineReactTable({
		data: catalogImages ?? [],
		columns: columns,
		manualPagination: true,
		enableTopToolbar: false,
		enableSorting: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			isLoading: isLoading || !isFetchedAfterMount ? true : false,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onPaginationChange: setPagination,
	})

	const { mutate: getAllCatalogImages } =
		trpc.v2_5.catalog.getAllCatalogImages.useMutation({
			onSuccess: (data) => {
				openSortCatalogImagesModal({
					catalogImages: data,
				})
			},
		})

	return (
		<>
			<S.div
				bg="white"
				p="40px"
				rounded="md"
				border="1px solid #E0E0E0"
				mb={10}
				height="auto"
			>
				<Flex direction="column" gap="4">
					<S.h2 mb="1">Catalog images</S.h2>
					<S.div d="flex" justifyContent="space-between" alignItems="center">
						<S.p lineHeight="20px" fontSize="sm" color="#757575">
							Add images to your catalog
						</S.p>
						<Button
							variant="outline"
							onClick={() => {
								getAllCatalogImages({
									catalogId: props.catalogId,
								})
							}}
						>
							Sort Images
						</Button>
					</S.div>
					<CreateCatalogImageInput
						catalogId={props.catalogId}
						textButtonDelete="Discard image"
					/>
					<MantineReactTable table={table} />
				</Flex>
			</S.div>
		</>
	)
}

type CatalogImage =
	RouterOutputs["v2_5"]["catalog"]["getCatalogImagesByPage"]["entries"][number]

const columns: MRT_ColumnDef<CatalogImage>[] = [
	{
		id: "actions",
		header: "Actions",
		Cell(table) {
			const ctx = trpc.useContext()
			const [openModal, setOpenModal] = useState<boolean>(false)

			const { mutate: createCatalogImagesTags, isLoading } =
				trpc.v2_5.catalog.createCatalogImagesTags.useMutation({
					onError(error) {
						reportUserError({
							title: "Error updating catalog image tags",
							message: error.message,
						})
					},
					onSuccess() {
						ctx.v2_5.catalog.getCatalogImagesByPage.invalidate()
						setOpenModal(false)
						reportUserSuccess({
							title: "Catalog image tags updated",
							message: "Catalog image tags have been updated successfully",
						})
					},
				})

			const { mutate: deleteCatalogImage } =
				trpc.v2_5.catalog.deleteCatalogImage.useMutation({
					onError(error) {
						reportUserError({
							title: "Error deleting catalog image",
							message: error.message,
						})
					},
					onSuccess() {
						ctx.v2_5.catalog.getCatalogImagesByPage.invalidate()
						setOpenModal(false)
						reportUserSuccess({
							title: "Catalog image deleted",
							message: "Catalog image has been deleted successfully",
						})
					},
				})

			const handleSubmit = (values: CatalogImageFormValues) => {
				if (!values.image || !values.image.url) {
					reportUserError({
						title: "Error creating catalog image",
						message: "Image is required",
					})
					return
				}

				if (values.image.tags.length === 0) {
					reportUserError({
						title: "Error creating catalog image",
						message: "At least one tag is required",
					})
					return
				}

				createCatalogImagesTags({
					catalogItemImageId: table.row.original.id,

					tags: values.image!.tags.map((tag) => ({
						title: tag.title,
						url: tag.url,
						x: tag.x,
						y: tag.y,
						itemSkuId: tag.itemSkuId,
					})),
				})
			}

			const handleDelete = () => {
				deleteCatalogImage({
					id: table.row.original.id,
				})
			}

			const defaultValues = useMemo(() => {
				return {
					image: {
						url: table.row.original.url,
						isLoading: false,
						uid: uuidv4(),
						tags: table.row.original.tags.map((tag) => ({
							title: tag.title,
							url: tag.url,
							x: tag.x,
							y: tag.y,
							itemSkuId: tag.itemSkuId,
							sku: tag.itemSku?.sku ?? null,
						})),
					},
				}
			}, [table.row.original])

			return (
				<>
					{openModal && (
						<CatalogImageFormModal
							opened={openModal}
							setOpened={setOpenModal}
							onSubmit={handleSubmit}
							defaultValues={defaultValues}
							isLoading={isLoading}
							textButtonDelete="Delete Image"
							catalogItemImageId={table.row.original.id}
						/>
					)}
					<Dropdown.Root>
						<Dropdown.Trigger>
							<S.div display="flex" justifyContent="center" alignItems="center">
								<IconButton variant="ghost" color="gray" size="1">
									<EllipsisHorizontal />
								</IconButton>
							</S.div>
						</Dropdown.Trigger>
						<Dropdown.Content>
							<Dropdown.Item
								onClick={() => {
									setOpenModal(true)
								}}
							>
								Edit
							</Dropdown.Item>
							<Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									openMoveCatalogImageModal(table.row.original.id)
								}}
							>
								Move to another catalog
							</Dropdown.Item>
						</Dropdown.Content>
					</Dropdown.Root>
				</>
			)
		},
	},
	{
		id: "image",
		header: "Image",
		Cell(table) {
			const image = table.row.original.url ?? placeholderImage

			return (
				<img
					src={image}
					className={css({
						width: "50px",
						height: "50px",
					})}
				/>
			)
		},
	},
	{
		id: "tags",
		header: "Tags",
		accessorKey: "tags",
		Cell(table) {
			return table.row.original.tags.length
		},
	},
	{
		id: "createdAt",
		header: "Date",
		accessorKey: "createdAt",
		Cell(table) {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
]

const openMoveCatalogImageModal = (catalogImageId: number) => {
	modals.open({
		modalId: "MOVE_CATALOG_IMAGE",
		centered: true,
		size: "auto",
		children: <MoveCatalogImageChildren catalogImageId={catalogImageId} />,
		zIndex: 40,
	})
}

interface MoveCatalogImageChildrenProps {
	catalogImageId: number
}

const MoveCatalogImageChildren = (props: MoveCatalogImageChildrenProps) => {
	const [query, setQuery] = useState<string>("")
	const [catalogIdToMove, setCatalogIdToMove] = useState<number | null>(null)
	const ctx = trpc.useContext()

	const { data: searchData } = trpc.v2_5.catalog.search.useQuery(
		{ query: query, type: "BY_CATALOG_IMAGE", limit: 6 },
		{
			onError: (err) => {
				reportUserError({
					title: "Failed to get products",
					message: err.message,
				})
			},
		},
	)

	const options = useMemo(() => {
		return (
			searchData?.map((catalog) => ({
				label: catalog.title,
				value: catalog,
				id: catalog.id.toString(),
			})) ?? []
		)
	}, [searchData, query])

	const { mutate: moveCatalogImage } =
		trpc.v2_5.catalog.moveCatalogImage.useMutation({
			onError(error) {
				reportUserError({
					title: "Failed to move catalog image",
					message: error.message,
				})
			},
			onSuccess() {
				modals.close("MOVE_CATALOG_IMAGE")
				setCatalogIdToMove(null)
				ctx.v2_5.catalog.invalidate()
				reportUserSuccess({
					title: "Catalog image moved",
				})
			},
		})

	return (
		<S.div display="flex" flexDirection="column" gap="1rem" width="100%">
			<S.h2 fontWeight={600}>Move Catalog Image</S.h2>
			<S.p>Move this catalog image to another catalog</S.p>
			<Combobox
				data={options}
				placeholders={{
					selectValue: "Select catalog",
					searchValue: "Search catalog",
					valueNotFound: "No catalog found",
				}}
				onSearchChange={setQuery}
				onChange={(value) => setCatalogIdToMove(value.value.id ?? null)}
			/>
			<S.div display="flex" alignContent="space-around" gap="1rem" width="100%">
				<Button
					disabled={!catalogIdToMove}
					onClick={() => {
						if (!catalogIdToMove) {
							return
						}

						moveCatalogImage({
							catalogImageId: props.catalogImageId,
							catalogToMoveId: catalogIdToMove,
						})
					}}
				>
					Move
				</Button>
				<Button
					variant="ghost"
					onClick={() => modals.close("MOVE_CATALOG_IMAGE")}
					className={css({
						backgroundColor: "red !important",
						color: "white",
					})}
				>
					Cancel
				</Button>
			</S.div>
		</S.div>
	)
}
