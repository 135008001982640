import {
	ActionsMenuIcon,
	ChevronDownOutline,
	EditIcon,
	SearchOutline,
	TrashFilledIcon,
} from "#/components-ng"
import { css, S } from "#/s"
import { trpc, type RouterOutputs } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import * as M from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import dayjs from "dayjs"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table"
import { Link } from "react-router-dom"
import { useState, useMemo } from "react"

export function TutorialsScene() {
	const [searchQuery, setSearchQuery] = useState("")

	return (
		<S.div css={{ bg: "white", rounded: "0.25rem", p: "1.5rem" }}>
			<Header searchQuery={searchQuery} onSearchQueryChange={setSearchQuery} />
			<TutorialTable searchQuery={searchQuery} />
		</S.div>
	)
}

interface HeaderProps {
	onSearchQueryChange: (query: string) => void
	searchQuery: string
}

function Header(props: HeaderProps) {
	return (
		<S.div
			css={{ display: "flex", justifyContent: "space-between", mb: "2rem" }}
		>
			<M.Menu>
				<M.Menu.Target>
					<M.Button rightIcon={<ChevronDownOutline />}>I want to...</M.Button>
				</M.Menu.Target>
				<M.Menu.Dropdown>
					<Link to="create-tutorial/new">
						<M.Menu.Item>Create new tutorial</M.Menu.Item>
					</Link>
				</M.Menu.Dropdown>
			</M.Menu>

			<S.div css={{ width: "300px" }}>
				<M.TextInput
					placeholder="Search tutorials"
					icon={<SearchOutline />}
					value={props.searchQuery}
					onChange={(e) => props.onSearchQueryChange(e.currentTarget.value)}
					classNames={{
						input: css({
							rounded: "0.5rem",
						}),
					}}
				/>
			</S.div>
		</S.div>
	)
}

type Tutorial = NonNullable<RouterOutputs["v2_5"]["tutorial"]["getByPage"]>

const columns: MRT_ColumnDef<Tutorial["entries"][number]>[] = [
	{
		id: "actions",
		header: "Actions",
		Cell: (table) => {
			const tctx = trpc.useContext()

			const { mutate } = trpc.v2_5.tutorial.delete.useMutation({
				onSuccess: () => {
					reportUserSuccess({ title: "Tutorial deleted successfully" })
					tctx.v2_5.tutorial.invalidate()
				},
				onError: (error) => {
					reportUserError({
						title: "Failed to delete tutorial",
						message: error.message,
					})
				},
			})

			const handleDeleteClick = () => {
				openConfirmModal({
					title: <S.p css={{ fw: "600" }}>Careful!</S.p>,
					children: "Are you sure you want to delete this tutorial?",
					labels: { confirm: "Confirm", cancel: "Cancel" },
					confirmProps: { color: "red" },
					onConfirm: () => {
						mutate({
							id: table.row.original.id,
						})
					},
				})
			}

			return (
				<M.Menu withinPortal>
					<M.Menu.Target>
						<M.ActionIcon>
							<ActionsMenuIcon />
						</M.ActionIcon>
					</M.Menu.Target>
					<M.Menu.Dropdown>
						<M.Menu.Item
							component={Link}
							to={`edit-tutorial/${table.row.original.id}`}
							icon={<EditIcon />}
						>
							Edit tutorial
						</M.Menu.Item>
						<M.Menu.Item onClick={handleDeleteClick} icon={<TrashFilledIcon />}>
							Delete tutorial
						</M.Menu.Item>
					</M.Menu.Dropdown>
				</M.Menu>
			)
		},
	},
	{
		id: "title",
		header: "Tutorial Title",
		accessorKey: "title.en",
		enableSorting: false,
	},
	{
		id: "createdAt",
		header: "Date",
		accessorKey: "createdAt",
		enableSorting: true,
		Cell: (table) => {
			const dateStr = dayjs(table.row.original.createdAt).format("MM/DD/YYYY")
			return dateStr
		},
	},
	{
		id: "uploadedBy",
		header: "Uploaded By",
		enableSorting: false,
		Cell: (table) => {
			const row = table.row.original
			const firstName = row.updatedBy.firstName
			const lastName = row.updatedBy.lastName

			let fullName = firstName
			if (lastName != null && lastName.length > 0) {
				fullName += ` ${lastName}`
			}

			return fullName
		},
	},
]

interface TableProps {
	searchQuery: string
}

function TutorialTable(props: TableProps) {
	// Pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	})
	// Sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } = trpc.v2_5.tutorial.getByPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			search: props.searchQuery,
			sorting: querySorting,
		},
		{
			keepPreviousData: true,
		},
	)

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns: columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
	})

	return <MantineReactTable table={table} />
}
