import * as M from "@mantine/core"
import { styled as s } from "#/css/jsx"
import { FormProvider, useFieldArray, useWatch } from "react-hook-form"
import type { Schema } from "./types";
import { C, Controller, useForm, useFormContext } from "./common"
import { FormQuestion } from "./question"
import { S, css, Stack, stack, wrap } from "#/s"
import { PlaceholderButton } from "./placeholder-button"
import { useHydrateAtoms } from "jotai/utils"
import { ScopeProvider, useMolecule } from "bunshi/react"
import { formScope, ModeMolecule } from "./state"
import { Select } from "#/components-ng"
import { trpc } from "#/trpc"
import { getDiscountLabel } from "#/util/discounts"

export interface FormFormProps {
	id?: number
	onSubmit: (values: Schema) => void
	defaultValues?: Schema
	mode?: "create" | "edit"
}

function _FormForm(props: FormFormProps) {
	const modeAtom = useMolecule(ModeMolecule)
	useHydrateAtoms([[modeAtom, props.mode ?? "create"]])
	const form = useForm({
		values: {
			title: {
				en: "",
				es: "",
			},
			discount: null,
			questions: [
				{
					title: {
						en: "How was your experience?",
						es: "¿Cómo fue su experiencia?",
					},
					answerType: "MULTI_OPTION",
					multiOptions: [
						{
							en: "Bad",
							es: "Malo",
						},
						{
							en: "Good",
							es: "Bueno",
						},
					],
				},
			],
			...props.defaultValues,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	return (
		<div>
			<FormProvider {...form}>
				<S.form
					onSubmit={form.handleSubmit(props.onSubmit)}
					className={css({
						"& label": {
							c: "slate.500",
						},
					})}
				>
					<FormProvider {...form}>
						<Stack>
							<C $as={M.TextInput} name="title.en" label="Title" />
							<DiscountField disabled={props.mode === "edit"} />
							<s.div>
								<s.p fw="medium" c="slate.500" fs="lg" mt={8} mb={4}>
									Questions
								</s.p>
								<FormQuestions />
							</s.div>
							<S.div
								d="flex"
								justifyContent="space-between"
								alignItems="start"
								gapX={12}
							>
								<S.p c="slate.500" fs="sm" maxW="600px">
									* You can only update the title of the form to prevent
									existing submissions from going out-of-date. We are working on
									lifting this limitation.
								</S.p>
								<M.Button type="submit">
									{props.mode === "create" ? "Create form" : "Update form"}
								</M.Button>
							</S.div>
						</Stack>
					</FormProvider>
				</S.form>
			</FormProvider>
		</div>
	)
}

export function FormForm(props: FormFormProps) {
	return (
		<ScopeProvider scope={formScope} value={props.id ?? "new"}>
			<_FormForm {...props} />
		</ScopeProvider>
	)
}

function FormQuestions() {
	const form = useFormContext()
	const fieldArray = useFieldArray({
		control: form.control,
		name: "questions",
		keyName: "k",
	})
	const modeAtom = useMolecule(ModeMolecule)
	const mode = useAtomValue(modeAtom)

	function handleRemove(index: number) {
		fieldArray.remove(index)
	}

	function handleAdd() {
		fieldArray.append({
			title: {
				en: "",
				es: "",
			},
			answerType: "TEXT",
			multiOptions: [
				{
					en: "",
					es: "",
				},
			],
		})
	}

	return (
		<Stack>
			<S.ol
				d="grid"
				gridTemplateColumns="repeat(auto-fill, minmax(max(300px, 30%), 1fr))"
				gap={4}
			>
				{fieldArray.fields.map((question, i) => (
					<FormQuestion key={question.k} index={i} onRemove={handleRemove} />
				))}
				<S.div flex="1 1 300px">
					<PlaceholderButton
						onClick={handleAdd}
						disabled={mode === "edit"}
						css={{
							w: "full",
						}}
					>
						{mode === "create"
							? "Add question"
							: "You cannot add questions to an existing form"}
					</PlaceholderButton>
				</S.div>
			</S.ol>
		</Stack>
	)
}

export type DiscountFieldProps = {
	disabled: boolean
}

export const DiscountField = (props: DiscountFieldProps) => {
	const { data } = trpc.v2_5.discounts.getAll.useQuery({
		mode: "SURVEY_REWARD",
	})

	return (
		<Controller
			name="discount"
			render={(c) => (
				<Select
					data={data ?? []}
					label="Discount"
					entryId={(d) => d.id.toString()}
					entryLabel={(d) => `${d.reference} - ${getDiscountLabel(d)}`}
					searchable
					className="justify-self-start"
					disabled={props.disabled}
					{...c.field}
				/>
			)}
		/>
	)
}
