import { trpc } from "#/trpc"
import { useMemo, useState } from "react"
import { C, type FormValues, generatePerformanceReportSchema } from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useFormContext } from "react-hook-form"
import { LoadingOverlay, Select } from "@mantine/core"
import { Button } from "@gt/ui"
import { LinearScaleChart, MultiSelect } from "#/components-ng"
import GTLoader from "#/components-ng/loader"
import { generateRandomHexColor } from "#/util/color"
import { S, css } from "#/s"

type OptionMultiselect = {
	id: number
	name: string
}

export const PerformanceReport = () => {
	const { data: entities } = trpc.entity.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})
	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})

	const form = C.useForm({
		resolver: zodResolver(generatePerformanceReportSchema),
		shouldUnregister: false,
		defaultValues: {
			filter: {
				by: "entity",
				ids: [1],
			},
			month: new Date().getMonth() + 1,
			years: [new Date().getFullYear()],
		},
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getPerformanceReport.useMutation()

	const entityOptions = useMemo(
		() =>
			entities?.map((entity) => ({
				name: entity.name,
				id: entity.id,
			})) ?? [],
		[entities],
	)

	const filialOptions = useMemo(
		() =>
			filials?.map((filial) => ({
				name: filial.name,
				id: filial.id,
			})) ?? [],
		[filials],
	)

	const salesData = useMemo(() => {
		if (report) {
			return report.reportByYear.map((r) => {
				const color = generateRandomHexColor()
				return {
					label: r.year.toString(),
					backgroundColor: color,
					borderColor: color,
					data: r.days.map((m) => Number(m.totalSales) ?? 0),
				}
			})
		}

		return []
	}, [report])

	const totalOrdersData = useMemo(
		() =>
			report?.reportByYear.map((r) => {
				const color = generateRandomHexColor()
				return {
					label: r.year.toString(),
					backgroundColor: color,
					borderColor: color,
					data: r.days.map((m) => m.orderCount ?? 0),
				}
			}) ?? [],
		[report],
	)

	const handleSubmit = (values: FormValues) => {
		generateReport({
			filter: {
				by: values.filter.by,
				ids: values.filter.ids,
			},
			month: values.month,
			years: values.years,
		})
	}

	const filterBy = form.watch("filter.by")

	return (
		<S.div gap="1rem" display="flex" flexDirection="column">
			<S.h2>Performance Report</S.h2>

			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.stopPropagation()
						form.handleSubmit(handleSubmit)(e)
					}}
				>
					<S.div display="flex" gap="1rem">
						<C.M
							as={Select}
							label="Report type"
							name="filter.by"
							data={[
								{ label: "Entity", value: "entity" },
								{ label: "Filial", value: "filial" },
								{ label: "Ecommerce", value: "ecommerce" },
							]}
							required
						/>
						{filterBy !== "ecommerce" && (
							<EntityFilialMultiselect
								label={
									filterBy === "entity" ? "Select entity" : "Select filial"
								}
								options={filterBy === "entity" ? entityOptions : filialOptions}
								placeholder={
									filterBy === "entity"
										? "Select entity..."
										: "Select filial..."
								}
							/>
						)}
						<MonthSelect />
						<YearsMultiselect />

						<Button
							type="submit"
							isLoading={isLoading}
							className={css({
								marginTop: "1.25rem",
								paddingX: "3rem",
							})}
						>
							Generate
						</Button>
					</S.div>
				</form>
			</FormProvider>
			{isLoading && (
				<LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			)}
			{salesData.length > 0 && !isLoading && (
				<>
					<S.hr />
					<S.h3>Sales by day</S.h3>
					<LinearScaleChart
						datasets={salesData}
						labels={
							report?.reportByYear[0].days.map((d) => d.day.toString()) ?? []
						}
						includeTitle={false}
						includeLegend={true}
					/>
					<S.hr />
					<S.h3>Total orders by day</S.h3>
					<LinearScaleChart
						datasets={totalOrdersData}
						labels={
							report?.reportByYear[0].days.map((d) => d.day.toString()) ?? []
						}
						includeTitle={false}
						includeLegend={true}
					/>
					<S.hr />
					<S.h3>Key Performance Indicators</S.h3>
					<S.div d="grid" gridTemplateColumns="1fr 1fr" gap="1rem">
						{Array.from({ length: 2 }).map((_, i) => (
							<PerformanceKPI key={i} />
						))}
					</S.div>
				</>
			)}
		</S.div>
	)
}

const YearsMultiselect = () => {
	const form = useFormContext()
	const years = form.watch("years")

	const [selectedYears, setSelectedYears] = useState<OptionMultiselect[]>(
		years.map((year) => ({
			id: year,
			name: year.toString(),
		})) ?? [],
	)

	const yearOptions = useMemo(() => {
		const currentYear = new Date().getFullYear()
		const years: OptionMultiselect[] = []
		for (let i = 2022; i <= currentYear; i++) {
			years.push({
				id: i,
				name: i.toString(),
			})
		}
		return years
	}, [])

	const handleYearsChange = (years: OptionMultiselect[]) => {
		form.setValue(
			"years",
			years.map((year) => year.id),
		)
		setSelectedYears(years)
	}

	return (
		<MultiSelect
			label="Years"
			data={yearOptions}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleYearsChange}
			placeholder="Select years..."
			value={selectedYears}
		/>
	)
}

const MonthSelect = () => {
	const form = useFormContext()
	const month = form.watch("month")

	const handleMonthChange = (e: string) => {
		form.setValue("month", parseInt(e))
	}

	return (
		<Select
			label="Month"
			value={month?.toString()}
			onChange={handleMonthChange}
			required
			data={months.map((month, i) => ({
				label: month,
				value: `${i + 1}`,
			}))}
		/>
	)
}

interface EntityFilialMultiselectProps {
	label: string
	options: OptionMultiselect[]
	placeholder?: string
}

const EntityFilialMultiselect = (props: EntityFilialMultiselectProps) => {
	const form = useFormContext()
	const ids = form.watch("filter.ids")
	const filterBy = form.watch("filter.by")

	const [selectedIds, setSelectedIds] = useState<OptionMultiselect[]>(
		ids.map((id) => ({ id, name: id.toString() })) ?? [],
	)

	useEffect(() => {
		if (filterBy === "entity") {
			setSelectedIds([{ id: 1, name: "1" }])
		}
	}, [filterBy])

	const handleEntityFilialChange = (ids: OptionMultiselect[]) => {
		form.setValue(
			"filter.ids",
			ids.map((id) => id.id),
		)
		setSelectedIds(ids)
	}

	return (
		<MultiSelect
			label={props.label}
			data={props.options}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleEntityFilialChange}
			placeholder={props.placeholder}
			value={selectedIds}
		/>
	)
}

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]

const PerformanceKPI = () => {
	return (
		<S.div
			d="flex"
			flexDirection="column"
			gap="1rem"
			alignContent="center"
			justifyContent="center"
			border="1px solid"
			borderColor="gray.300"
			px="1rem"
			py="1rem"
		>
			<S.h4>Coming soon...</S.h4>
		</S.div>
	)
}
