import { reportUserError } from "#/util/index.js";
import { shippingCostAtom } from "./state/cost-breakdown.js";
import * as M from "@mantine/core";
import Decimal from "decimal.js";
import { useAtom } from "jotai/react"
import React from "react";
import { MdCheck } from "react-icons/md/index.js";

export function ShippingCost() {
  const [shippingCost, setShippingCost] = useAtom(shippingCostAtom);
  const [tempShippingCost, setTempShippingCost] = React.useState(() =>
    shippingCost.toString()
  );
  const isDisabled = React.useMemo(() => {
    try {
      return (
        new Decimal(tempShippingCost).toString() === shippingCost.toString()
      );
    } catch (e) {
      return false;
    }
  }, [tempShippingCost, shippingCost]);

  const setShippingCostWithValidation = (value: string) => {
    try {
      let parsedValue = value;
      if (value.trim().length === 0) {
        setTempShippingCost("0");
        parsedValue = "0";
      }
      const newShippingCost = new Decimal(parsedValue);
      if (newShippingCost.decimalPlaces() > 2) {
        throw new Error(
          "Shipping cost cannot have more than two decimal places."
        );
      }
      setShippingCost(newShippingCost);
    } catch (e) {
      reportUserError({
        title: "Invalid shipping cost",
        message: e.message ?? "Make sure you are entering a valid number.",
      });
    }
  };

  return (
    <>
      <M.Text>Shipping</M.Text>
      <M.Group noWrap spacing={0} position="right">
        <M.TextInput
          inputMode="numeric"
          pattern="[0-9]*"
          size="xs"
          value={tempShippingCost.toString()}
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") {
              setShippingCostWithValidation(tempShippingCost);
            }
          }}
          onChange={(v) => {
            setTempShippingCost(v.currentTarget.value);
          }}
          styles={{
            root: { width: "8ch" },
            input: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
          }}
        />
        <M.ActionIcon
          color="green.6"
          variant="filled"
          size={30}
          disabled={isDisabled}
          onClick={() => setShippingCostWithValidation(tempShippingCost)}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <MdCheck />
        </M.ActionIcon>
      </M.Group>
    </>
  );
}
