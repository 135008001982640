import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const generateSalesByCountriesReportSchema = z.object({
	dateRange: z.array(z.date()),
	filter: z
		.discriminatedUnion("by", [
			z.object({
				by: z.literal("entity"),
				ids: z.array(z.number()),
			}),
			z.object({
				by: z.literal("filial"),
				ids: z.array(z.number()),
			}),
			z.object({
				by: z.literal("ecommerce"),
        ids: z.array(z.number()),
			}),
		])
		.default({ by: "entity", ids: [1] }),
})

export type FormValues = z.infer<typeof generateSalesByCountriesReportSchema>

export const C = makeController<FormValues>()
