import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const generatePerformanceReportSchema = z.object({
	filter: z
		.discriminatedUnion("by", [
			z.object({
				by: z.literal("entity"),
				ids: z.array(z.number()),
			}),
			z.object({
				by: z.literal("filial"),
				ids: z.array(z.number()),
			}),
			z.object({
				by: z.literal("ecommerce"),
				ids: z.array(z.number()),
			}),
		])
		.default({ by: "entity", ids: [1] }),
  month: z.number().min(1).max(12),
	years: z.array(z.number().int().min(2023).max(new Date().getFullYear())),
})

export type FormValues = z.infer<typeof generatePerformanceReportSchema>
export const C = makeController<FormValues>()
