import logoSrc from "#/images/monochromatic-logo-ticket.png";
import { RouterOutputs } from "#/trpc.js";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import EscPosEncoder from "esc-pos-encoder";
import { PaymentType } from "server";

type EndOfDayOuput = RouterOutputs["report"]["getEndOfDay"];

const paymentCards: PaymentType[] = [
  "MASTERCARD",
  "VISA",
  "DISCOVER",
  "AMERICAN_EXPRESS",
];

const columns = 48;
const CTL_VT = new Uint8Array([0x1b, 0x64, 0x04]);
const HW_INIT = new Uint8Array([0x1b, 0x40]);
const divider = "".padStart(columns, "-");

const paymentMethod = (enc, endOfDay, header, filter) => {
  return enc
    .bold(true)
    .line(header)
    .bold(false)
    .line(
      leftRight(
        "Total paid in",
        `$${endOfDay.paymentsMethodsSummary?.paymentMethods
          .filter(filter)
          .map((o) => o.totalPaidIn)
          .reduce((a, b) => a.plus(b), new Decimal(0))
          .toNumber()
          .toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total paid out",
        `$${endOfDay.paymentsMethodsSummary?.paymentMethods
          .filter(filter)
          .map((o) => o.totalPaidOut)
          .reduce((a, b) => a.plus(b), new Decimal(0))
          .toNumber()
          .toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total amount",
        `$${endOfDay.paymentsMethodsSummary?.paymentMethods
          .filter(filter)
          .map((o) => o.totalAmount)
          .reduce((a, b) => a.plus(b), new Decimal(0))
          .toNumber()
          .toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total return",
        `$${endOfDay.paymentsMethodsSummary?.paymentMethods
          .filter(filter)
          .map((o) => o.totalReturned)
          .reduce((a, b) => a.plus(b), new Decimal(0))
          .toNumber()
          .toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total activity",
        `$${endOfDay.paymentsMethodsSummary?.paymentMethods
          .filter(filter)
          .map((o) => o.totalActivity)
          .reduce((a, b) => a.plus(b), new Decimal(0))
          .toNumber()
          .toFixed(2)}`
      )
    );
};

//TODO: Backend doesn't separate sales from refunds
export const printEndOfDayEpson = async (
  endOfDay: NonNullable<EndOfDayOuput>
) => {
  const logo = await new Promise<HTMLImageElement>((resolve) => {
    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoSrc;
    logo.onload = () => resolve(logo);
  });

  let enc = new EscPosEncoder();

  // Initialize
  enc = enc.initialize();

  // Title
  enc = enc.bold(true).line("End of day").bold(false);

  // Subtitle
  enc = enc
    .line(
      `Created at ${dayjs(endOfDay.from).format("MM/DD/YYYY")} - ${dayjs(
        endOfDay.to
      ).format("MM/DD/YYYY")}`
    )
    .image(logo, 280, 120);

  enc = enc.line(divider);

  // Orders
  enc = enc
    .bold(true)
    .line("Orders")
    .bold(false)
    .line(
      leftRight(
        "Completed orders",
        endOfDay.ordersHistory.orderCount.toString()
      )
    )
    .line(leftRight("Reversed orders", "--"));

  enc = enc.line(divider);

  // Inventory movement
  enc = enc
    .bold(true)
    .line("Inventory movement")
    .bold(false)
    .line(`Count        Sold       Returned`)
    .line(
      `${endOfDay.inventoryMovement.count
        .toString()
        .padEnd(
          12
        )} ${endOfDay.inventoryMovement.noReturned.totalCountProductsTransacted
        .toString()
        .padEnd(
          12
        )} ${endOfDay.inventoryMovement.returned.totalCountProductsReturned
        .toString()
        .padEnd(12)}`
    );

  enc = enc.line(divider);

  // Activity
  enc = enc
    .bold(true)
    .line("Activity")
    .bold(false)
    .line(
      leftRight(
        "Total order",
        `$${endOfDay.totalOrderAmount.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total tax",
        `$${endOfDay.totalTaxAmount.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total returned",
        `$${endOfDay.totalReturnedAmount.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total shipping",
        `$${endOfDay.totalShipping.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total activity",
        `$${endOfDay.totalActivityAmount.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total available for deposit",
        `$${endOfDay.totalAvaliableForDeposit.toNumber().toFixed(2)}`
      )
    );

  enc = enc.line(divider);

  // summary by order Type
  enc = enc.bold(true).line("Sale Summary by order type").bold(false);
  enc = enc.line(divider);

  // orderType = ONLINE
  enc = enc
    .bold(true)
    .line("Online")
    .bold(false)
    .line(
      leftRight(
        "Total paid in",
        `$${
          endOfDay.summaryByOrderType
            ?.find((o) => o.orderType === "ONLINE")
            ?.totalPaidIn?.toNumber()
            ?.toFixed(2) ?? 0
        }`
      )
    );

  enc = enc.line(divider);

  // orderType = IN_STORE
  enc = enc
    .bold(true)
    .line("In store")
    .bold(false)
    .line(
      leftRight(
        "Total paid in",
        `$${
          endOfDay.summaryByOrderType
            ?.find((o) => o.orderType === "IN_STORE" || o.orderType === "PICKUP_FROM_STORE")
            ?.totalPaidIn?.toNumber()
            ?.toFixed(2) ?? 0
        }`
      )
    );

  enc = enc.line(divider);

  // orderType = PICKUP
  enc = enc
    .bold(true)
    .line("Pickup")
    .bold(false)
    .line(
      leftRight(
        "Total paid in",
        `$${
          endOfDay.summaryByOrderType
            ?.find((o) => o.orderType === "PICKUP")
            ?.totalPaidIn?.toNumber()
            ?.toFixed(2) ?? 0
        }`
      )
    );

  enc = enc.line(divider);

  // Payments
  enc = enc
    .bold(true)
    .line("Payments")
    .bold(false)
    .line(
      leftRight(
        "Total paid out",
        `$${endOfDay.payments.totalPaidIn.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total paid out",
        `$${endOfDay.payments.totalPaidOut.toNumber().toFixed(2)}`
      )
    )
    .line(
      leftRight(
        "Total amount",
        `$${endOfDay.payments.totalAmount.toNumber().toFixed(2)}`
      )
    );

  enc = enc.line(divider);

  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "Cash", (o) => o.method === "CASH");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "All credit/debit cards", (o) => paymentCards.includes(o.method));

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "Visa", (o) => o.method === "VISA");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "MasterCard", (o) => o.method === "MASTERCARD");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "American Express", (o) => o.method === "AMEX");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "Discover", (o) => o.method === "DISCOVER");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "Bank Transfer", (o) => o.method === "BANK_TRANSFER");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "Paypal", (o) => o.method === "PAYPAL");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "ZELLE", (o) => o.method === "ZELLE");

  enc = enc.line(divider);
  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "Other", (o) => o.method === "OTHER");

  enc = enc.line(divider);

  // prettier-ignore
  enc = paymentMethod(enc, endOfDay, "User credits", (o) => o.method === "USER_CREDITS");

  enc = enc.line(divider);

  // Payment methods: credit/debit card
  enc = enc.bold(true).line("Payment methods: credit/debit card").bold(false);
  for (const o of endOfDay.payments.orders
    .filter((o) => o.method !== "CASH" && o.method !== "USER_CREDITS")
    .sort((a, b) =>
      a.orderType === "IN_STORE" || a.orderType === "PICKUP_FROM_STORE"
        ? -1
        : a.orderType === "ONLINE"
        ? 0
        : a.orderType === "PICKUP"
        ? 1
        : 0
    )) {
    enc = enc
      .line(leftRight(`#${o.orderId}`, o.customerFullName))
      .line(
        leftRight(o.method, `Total: $${o.totalAmount.toNumber().toFixed(2)}`)
      )
      .line(leftRight("Location", o.orderType));
    enc = enc.line(divider);
  }

  // Payment methods: cash
  enc = enc.bold(true).line("Payment methods: cash").bold(false);
  for (const o of endOfDay.payments.orders.filter(
    (o) => o.method === "CASH" && o.totalAmount.toNumber() > 0
  )) {
    enc = enc
      .line(leftRight(`#${o.orderId}`, o.customerFullName))
      .line(
        leftRight(o.method, `Total: $${o.totalAmount.toNumber().toFixed(2)}`)
      );
    enc = enc.line(divider);
  }

  // Payment method: user credits
  enc = enc.bold(true).line("Payment methods: user credits").bold(false);
  for (const o of endOfDay.payments.orders.filter(
    (o) => o.method === "USER_CREDITS" && o.totalAmount.toNumber() > 0
  )) {
    enc = enc
      .line(leftRight(`#${o.orderId}`, o.customerFullName))
      .line(
        leftRight(o.method, `Total: $${o.totalAmount.toNumber().toFixed(2)}`)
      );
    enc = enc.line(divider);
  }

  const buf = enc.raw(CTL_VT).raw(CTL_VT).raw(HW_INIT).cut().encode();

  await fetch("http://localhost:4455/print", {
    method: "POST",
    headers: {
      "Content-Type": "application/octet-stream",
    },
    body: buf,
  }).catch((e) => {
    throw new Error(
      "Could not connect to the printer server. Make sure it's running."
    );
  });
};

function leftRight(left: string, right: string): string {
  const len = left.length + right.length;
  return `${left}${"".padEnd(columns - len)}${right}`;
}
