import { useState, useRef, useEffect, useCallback } from "react"
import { Camera } from "lucide-react"
import { reportUserError } from "#/util"
import { Button } from "@gt/ui"
import { css, S } from "#/s"

interface MobileScannerVerifyItems {
	onScan: (data: string) => void
	minLength: number
}

declare global {
	interface Window {
		BarcodeDetector: {
			new (options?: { formats: string[] }): BarcodeDetector
		}
	}
}

interface BarcodeDetector {
	detect: (image: HTMLVideoElement) => Promise<
		Array<{
			boundingBox: DOMRectReadOnly
			cornerPoints: Array<{ x: number; y: number }>
			format: string
			rawValue: string
		}>
	>
}

export const MobileScannerVerifyItems = ({
	onScan,
	minLength,
}: MobileScannerVerifyItems) => {
	const [isScanning, setIsScanning] = useState<boolean>(false)
	const videoRef = useRef<HTMLVideoElement | null>(null)
	const streamRef = useRef<MediaStream | null>(null)

	const startScanning = async (): Promise<void> => {
		try {
			// Verify barcode detection api support
			if (!("BarcodeDetector" in globalThis)) {
				reportUserError({
					title: "Barcode Detection API is not supported on this device",
					message: "Please try again on a different device or browser.",
				})
				setIsScanning(false)
				return
			}

			// Request camera access - preferring back camera
			const stream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: "environment" },
			})

			streamRef.current = stream
			if (videoRef.current) {
				videoRef.current.srcObject = stream
				setIsScanning(true)
			}

			// Initialize barcode detector with supported formats
			const barcodeDetector = new window.BarcodeDetector({
				formats: ["ean_13", "ean_8", "code_128", "code_39", "upc_a"],
			})

			// Start continuous detection
			const detectCodes = async (): Promise<void> => {
				if (!videoRef.current || !isScanning) return

				try {
					const barcodes = await barcodeDetector.detect(videoRef.current)

					for (const barcode of barcodes) {
						const data = barcode.rawValue
						if (data && data.length >= minLength) {
							onScan(data)
							await new Promise((resolve) => setTimeout(resolve, 1000))
						}
					}
				} catch (error) {
					reportUserError({
						title: "Detecting barcodes failed",
						message: error.message,
					})
				}

				if (isScanning) {
					requestAnimationFrame(detectCodes)
				}
			}

			detectCodes()
		} catch (error) {
			reportUserError({
				title: "Failed to start scanning",
				message: error.message,
			})
			setIsScanning(false)
		}
	}

	const stopScanning = useCallback((): void => {
		if (streamRef.current) {
			streamRef.current.getTracks().forEach((track) => track.stop())
			streamRef.current = null
		}
		setIsScanning(false)
	}, [])

	useEffect(() => {
		return () => {
			stopScanning()
		}
	}, [stopScanning])

	return (
		<S.div d="flex" flexDir="column" gap="4">
			{isScanning ? (
				<>
					<S.video
						ref={videoRef}
						autoPlay
						playsInline
						w="full"
						maxW="sm"
						rounded="lg"
						borderWidth="1px"
					/>
					<Button
						variant="destructive"
						onClick={stopScanning}
						className={css({ mt: 2 })}
					>
						Stop Scanner
					</Button>
				</>
			) : (
				<Button
					onClick={startScanning}
					className={css({
						d: "flex",
						gap: 2,
						alignItems: "center",
					})}
				>
					<Camera className="h-4 w-4" />
					Scan with Camera
				</Button>
			)}
		</S.div>
	)
}
