import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import { SearchSelect } from "./SearchSelect.js";
import { customerAtom } from "./state/index.js";
import {
  waitingListsAtom,
  waitingListsCreateAtom,
  waitingListsDeleteAtom,
  waitingListsUpdateAtom,
} from "./state/waiting-list.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import { useAtomValue, useSetAtom } from "jotai/react"
import { debounce } from "lodash";
import React from "react";
import { MdCheck, MdEdit, MdOutlineWatchLater } from "react-icons/md/index.js";
import WarningIcon from "~icons/ion/warning";

// Needed to fix props typing issue
const ScrollAreaAutoResize = M.ScrollArea.Autosize as any;

export function WaitingList() {
  const [showError, setShowError] = React.useState(false);
  const customer = useAtomValue(customerAtom);
  const waitingLists = useAtomValue(waitingListsAtom);
  const waitingListsCreate = useSetAtom(waitingListsCreateAtom);
  const waitingListsDelete = useSetAtom(waitingListsDeleteAtom);
  const waitingListsUpdate = useSetAtom(waitingListsUpdateAtom);
  const deleteWaitingListMut = trpc.userWaitingList.delete.useMutation({
    onError(error) {
      reportUserError({
        title: "Failed to delete waiting list",
        message: error.message,
      });
    },
  });
  const createWaitingListMut = trpc.userWaitingList.create.useMutation({
    onError(error) {
      reportUserError({
        title: "Failed to create waiting list",
        message: error.message,
      });
    },
  });

  const handleCreateWaitingList = React.useCallback(
    (product: any) => {
      (async function () {
        if (!customer) return;
        const data = await createWaitingListMut.mutateAsync({
          userId: customer?.id ?? 0,
          email: customer?.email ?? "MISSING",
          itemSkuId: product.id,
          quantity: 1,
          status: "WAITING",
        });

        if (data) {
          waitingListsCreate({
            id: data.id,
            productName: data.itemSku.title || "",
            note: "",
            status: data.waitingListStatus ?? "WAITING",
            customer: data.userId,
            email: data.user.email ?? "MISSING",
            product: data.itemSkuId,
            qty: 1,
          });
        }
      })();
    },
    [createWaitingListMut, customer, waitingListsCreate]
  );

  const handleDeleteWaitingList = React.useCallback(
    (index: number) => {
      (async function () {
        const waitingListId = waitingLists[index].id;
        const data = await deleteWaitingListMut.mutateAsync({
          id: waitingListId,
        });

        if (data) {
          waitingListsDelete(index);
        }
      })();
    },
    [deleteWaitingListMut, waitingLists, waitingListsDelete]
  );

  return customer ? (
    <C.VStack spacing={3} w="100%">
      <C.HStack align="center">
        <MdOutlineWatchLater fontSize="small" />
        <C.Text fontWeight="bold">Waiting list</C.Text>
      </C.HStack>
      <SearchProduct
        placeholder="Search product..."
        onChange={customer ? handleCreateWaitingList : undefined}
        invalid={showError && !customer}
        error={showError && !customer ? "You must add a customer first" : null}
        icon={showError && !customer ? <WarningIcon /> : null}
        onClickCapture={() => setShowError(true)}
        sx={{ width: "100%" }}
      />
      <ScrollAreaAutoResize mah={140} offsetScrollbars sx={{ width: "100%" }}>
        <M.Stack spacing="sm" px="sm" sx={{ flexDirection: "column-reverse" }}>
          {waitingLists.map((waitingList, i) => (
            <WaitingListItem
              key={i}
              waitingList={waitingList}
              remove={() => handleDeleteWaitingList(i)}
              update={(wl: any) => {
                waitingListsUpdate({
                  index: i,
                  waitingList: wl,
                });
              }}
            />
          ))}
        </M.Stack>
      </ScrollAreaAutoResize>
    </C.VStack>
  ) : null;
}

export const WaitingListItem = ({ waitingList, remove, update }) => {
  const updateWaitingListMut = trpc.userWaitingList.update.useMutation({
    onError(error) {
      reportUserError({
        title: "Failed to create waiting list",
        message: error.message,
      });
    },
  });
  const [qty, setQty] = React.useState(1);
  const [editable, setEditable] = React.useState(true);

  const handleSave = () => {
    updateWaitingListMut.mutate({
      id: waitingList.id,
      input: {
        quantity: qty,
      },
    });
    update({
      id: waitingList.id,
      productName: waitingList.productName,
      note: waitingList.note ?? "",
      status: waitingList.status ?? "WAITING",
      createdBy: waitingList.createdBy,
      customer: waitingList.customer,
      product: waitingList.product,
      qty,
    });
    setEditable(false);
  };

  return (
    <M.Group key={waitingList.id} position="apart" noWrap>
      <M.Text sx={(t) => ({ color: t.colors.gray[7] })}>
        {waitingList.productName}
      </M.Text>
      <M.Group noWrap>
        {editable ? (
          <>
            <M.NumberInput
              min={0}
              value={qty}
              onChange={(v) => setQty(typeof v === "number" ? v : 1)}
              sx={{ width: "10ch" }}
            />
            <M.ActionIcon onClick={handleSave}>
              <MdCheck />
            </M.ActionIcon>
          </>
        ) : (
          <>
            <M.Text>{qty}</M.Text>
            <M.ActionIcon onClick={() => setEditable(true)}>
              <MdEdit />
            </M.ActionIcon>
          </>
        )}
        <M.CloseButton onClick={() => remove()} />
      </M.Group>
    </M.Group>
  );
};

function SearchProduct({ onChange, ...props }) {
  const [query, setQuery] = React.useState("");
  const { data, isInitialLoading } = trpc.itemSku.search.useQuery(
    { query: `\\"${query}\\"` },
    {
      enabled: query.length > 0,
      onError(error) {
        console.warn(error);
      },
    }
  );

  const productOptions = React.useMemo(
    () =>
      data?.map((itemSku) => ({ label: itemSku.title, value: itemSku.id })) ??
      [],
    [data]
  );

  return (
    <SearchSelect
      placeholder="Search..."
      options={productOptions}
      onInputChange={debounce((productQuery) => setQuery(productQuery), 250)}
      onChange={(product) => {
        const selectedItemSku = data!.find(
          (itemSku) => itemSku.id === product!.value
        )!;
        onChange?.(selectedItemSku);
      }}
      isLoading={isInitialLoading}
      getOptionLabel={(p) => p.label}
      getOptionValue={(p) => p.value.toString()}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "md",
        }),
        container: (p) => ({
          ...p,
          maxW: "none",
        }),
      }}
      {...props}
    />
  );
}
