import {
  OrderModalContent,
  OrdersTable,
} from "#/scenes/Customers/preview/components";
import { selectedOrderAtom } from "#/scenes/Customers/preview/components/state.js";
import { RouterOutputs, trpc } from "#/trpc.js";
import { customerAtom } from "./state/index.js";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import { useAtomValue } from "jotai/react"
import { Flipper, Flipped } from "react-flip-toolkit";

export function OrderHistoryModal(disclosure: C.UseDisclosureReturn) {
  const customer = useAtomValue(customerAtom);
  const selectedOrder = useAtomValue(selectedOrderAtom);

  const { data, isLoading } = trpc.order.getAllByCustomerId.useQuery(
    {
      id: customer?.id ?? 0,
    },
    {
      enabled: !!customer?.id,
    }
  );

  return (
    <C.Modal {...disclosure}>
      <Flipper flipKey={!!selectedOrder}>
        <C.ModalOverlay />
        <Flipped flipId="order-history-modal">
          <C.ModalContent
            maxW="initial"
            w="auto"
            overflowX="hidden"
            overflowY="auto"
            maxH="min(800px, 95vh)"
          >
            {selectedOrder ? (
              <OrderModalContent />
            ) : (
              <OrdersModalContent orders={data} isLoading={isLoading} />
            )}
          </C.ModalContent>
        </Flipped>
      </Flipper>
    </C.Modal>
  );
}

interface OrdersModalContentProps {
  orders?: RouterOutputs["order"]["getAllByCustomerId"];
  isLoading: boolean;
}

function OrdersModalContent({ orders, isLoading }: OrdersModalContentProps) {
  return (
    <Flipped inverseFlipId="order-history-modal">
      <C.Box>
        <C.ModalHeader as={C.HStack} spacing={4}>
          <C.IconButton
            aria-label="go back"
            icon={<ChevronLeftIcon w={8} h={8} />}
            variant="ghost"
            colorScheme="gray"
            size="sm"
            visibility="hidden"
            opacity="0"
          />
          <C.Text>Orders</C.Text>
        </C.ModalHeader>
        <C.Box>
          <C.ModalCloseButton />
        </C.Box>
        <C.ModalBody>
          <OrdersTable orders={orders} isLoading={isLoading} />
        </C.ModalBody>
      </C.Box>
    </Flipped>
  );
}
