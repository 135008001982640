import { S } from "#/s"
import { trpc } from "#/trpc"
import { useParams } from "react-router"
import { LoadingOverlay } from "@mantine/core"
import GTLoader from "#/components-ng/loader"
import { CustomerInfo } from "./customer-info"
import { ScanProduct } from "./scan-product"
import { VerifiedItemsPendingCartsTable } from "./table"
import { Container } from "#/css/jsx"
import { reportUserSuccess } from "#/util"
import { useState } from "react"
import { RouterPrompt } from "#/components"

export const VerifiedQuantityEcommerceOrder = () => {
	const [submitted, setSubmitted] = useState(false)
	const params = useParams()
	const id = Number(params.id)

	const { data, isLoading } = trpc.v2_5.order.getOrderItemSkus.useQuery(
		{
			orderId: id!,
		},
		{
			enabled: Number.isFinite(id),
			cacheTime: 0,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const totalItemsVerified = data?.orderItemSku
					?.map((item) => item?.verifiedQuantity ?? 0)
					.reduce((a, b) => a + b, 0)
				const totalQuantity = data?.orderItemSku
					?.map((item) => {
						const totalQuantityReturn = item?.refundOrderItemSku?.reduce(
							(acc, curr) => acc + curr.quantity,
							0,
						)
						return item?.quantity - totalQuantityReturn
					})
					.reduce((a, b) => a + b, 0)

				reportUserSuccess({
					title: `Items verified ${totalItemsVerified}/${totalQuantity}`,
				})

				if (totalItemsVerified === totalQuantity) {
					setSubmitted(true)
				} else {
					setSubmitted(false)
				}
			},
		},
	)

	const customerName = data?.customer
		? `${data.customer?.firstName ?? ""} ${data.customer?.lastName ?? ""}`
		: `${data?.userBasicInfo?.firstName ?? ""} ${data?.userBasicInfo?.lastName ?? ""}`

	const totalItemsVerified = data?.orderItemSku
		?.map((item) => item?.verifiedQuantity ?? 0)
		.reduce((a, b) => a + b, 0)
	const totalQuantity = data?.orderItemSku
		?.map((item) => {
			const totalQuantityReturn = item?.refundOrderItemSku?.reduce(
				(acc, curr) => acc + curr.quantity,
				0,
			)
			return item?.quantity - totalQuantityReturn
		})
		.reduce((a, b) => a + b, 0)

	return (
		<>
			{isLoading && (
				<LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			)}
			<RouterPrompt
				when={!submitted}
				title="You have not verified all items. Are you sure you want to leave?"
			/>
			<Container
				bg="#fff"
				p="1rem"
				rounded="md"
				borderColor="#E0E0E0"
				borderWidth="1px"
				shadow="md"
				w="full"
				maxW="1100px"
			>
				<S.div gap="1rem" d="flex" flexDir="column">
					<S.div
						d="flex"
						justifyContent="space-between"
						alignItems={{
							base: "flex-start",
							md: "center",
						}}
						flexDir={{ base: "column", md: "row" }}
					>
						<S.div d="flex" flexDir="column" gap="2">
							<S.h2>Order #{data?.receiptNumber} - Verify items</S.h2>
							<S.p color="neutral.700" fw="500">
								Scan the product to verify items
							</S.p>
							<S.p color="neutral.700" fw="500" fs="sm">
								Items verified: {totalItemsVerified}/{totalQuantity}
							</S.p>
        			<ScanProduct orderId={id} orderItemSkus={data?.orderItemSku ?? []} />
						</S.div>
						<CustomerInfo
							customerName={customerName}
							createdAt={data?.createdAt ?? new Date()}
							orderStatus={data?.orderStatus ?? "PROCESSING"}
						/>
					</S.div>
					<S.hr />
					<VerifiedItemsPendingCartsTable
						orderItemSkus={data?.orderItemSku ?? []}
					/>
				</S.div>
			</Container>
		</>
	)
}
