import { UserCreditsPaymentMethodCustomIcon } from "#/components-ng"
import { css } from "#/css/css"
import { HStack } from "#/css/jsx"
import { printTicketFromOrderV2 } from "#/modules/ticket/print"
import { S } from "#/s"
import { paymentMethodsMap } from "#/scenes/Sales/MakeASale/payment-methods-map"
import { applyDiscountV2 } from "#/scenes/Sales/util"
import { type RouterOutputs, trpc } from "#/trpc"
import { Icon } from "@chakra-ui/react"
import { Button } from "@gt/ui"
import * as M from "@mantine/core"
import { modals } from "@mantine/modals"
import { DropdownMenu, Separator, Spinner } from "@radix-ui/themes"
import dayjs from "dayjs"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { match } from "ts-pattern"

type Order = NonNullable<RouterOutputs["order"]["getById"]>

export const openOrderDetailsModal = (id: number, receiptNumber: string) => {
	modals.open({
		modalId: "ORDER-DETAILS-MODAL",
		children: <OrderDetailsModal id={id} />,
		size: "auto",
		centered: true,
		title: <h2>Order {receiptNumber}</h2>,
	})
}

interface OrderDetailsModalProps {
	id: number
}

const OrderDetailsModal = (props: OrderDetailsModalProps) => {
	const { data, isLoading, isFetchedAfterMount } = trpc.order.getById.useQuery(
		{
			id: props.id,
		},
		{
			enabled: !!props.id,
			refetchOnWindowFocus: false,
			cacheTime: 0,
		},
	)

	const orderType = match(data?.orderType)
		.with("ONLINE", () => "Shipping")
		.with("PICKUP", () => "Pickup")
		.otherwise(() => "Store")

	return isLoading || !isFetchedAfterMount ? (
		<Spinner loading={isLoading} size="2" />
	) : (
		<div
			className={css({
				padding: 2,
				maxHeight: "min(800px, 95vh)",
				width: "100%",
				maxWidth: "1100px",
			})}
		>
			<HStack width="100%" justifyContent="space-between">
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
					})}
				>
					<p className={css({ fontWeight: 700, color: "#0F1729" })}>
						{data?.customer?.firstName ?? ""} {data?.customer?.lastName ?? ""}
					</p>
					<p className={css({ color: "#4DBAFF" })}>
						+{data?.customer?.areaCode} {data?.customer?.phoneNumber}
					</p>
				</div>
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
					})}
				>
					<p className={css({ color: "#0F1729", fontWeight: 500 })}>
						Date: {dayjs(data?.createdAt).format("MM/DD/YYYY")}
					</p>
					<p className={css({ color: "#4DBAFF" })}>Type: {orderType}</p>
				</div>
			</HStack>

			<Separator className={css({ marginY: "12px" })} />
			<OrderDetailsTable orderItemSkus={data?.orderItemSku ?? []} />
			<Separator className={css({ marginY: "24px" })} />
			<HStack
				width="100%"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
						alignContent: "flex-start",
					})}
				>
					<PaymentsSection order={data} />
					<ReprintButton order={data} />
				</div>
				<CostBreakdown order={data} />
			</HStack>
		</div>
	)
}

interface OrderDetailsTableProps {
	orderItemSkus: Order["orderItemSku"]
}

const OrderDetailsTable = (props: OrderDetailsTableProps) => {
	const itemSkus = useMemo(
		() => props.orderItemSkus ?? [],
		[props.orderItemSkus],
	)

	const table = useMantineReactTable({
		columns,
		data: itemSkus ?? [],
		enableTopToolbar: false,
	})

	return <MantineReactTable table={table} />
}

const columns: MRT_ColumnDef<Order["orderItemSku"][number]>[] = [
	{
		id: "itemSkuId",
		header: "SKU",
		accessorKey: "itemSku.sku",
		size: 100,
	},
	{
		id: "title",
		header: "Product",
		accessorKey: "itemSku.title",
	},
	{
		id: "quantity",
		header: "Qty",
		accessorKey: "quantity",
		size: 100,
	},
	{
		id: "discountAmount",
		header: "Discount",
		Cell: (table) => {
			const original = table.row.original

			return (
				<p>
					{original.discountType === "AMOUNT" && "$"}
					{original.discountAmount?.toFixed(2) ?? ""}
					{original.discountType === "PERCENTAGE" && "%"}
				</p>
			)
		},
	},
	{
		id: "price",
		header: "Price",
		Cell: (table) => {
			const original = table.row.original

			const value =
				original.discountAmount && original.discountType
					? applyDiscountV2(original.price, {
							amount: original.discountAmount,
							type: original.discountType,
						})?.toFixed(2)
					: original.price.toFixed(2)

			return (
				<div
					className={css({
						display: "flex",
						flexDirection: "column",
						gap: "0.3rem",
					})}
				>
					<p className={css({ fontSize: "16px" })}>${value}</p>
					<p className={css({ fontSize: "12px" })}>
						Original price: ${original.price.toFixed(2)}
					</p>
				</div>
			)
		},
	},
	{
		id: "total",
		header: "Ext. price",
		Cell: (table) => {
			const original = table.row.original
			return `$${original.total.toFixed(2)}`
		},
	},
]

interface ReprintButtonProps {
	order?: Order | null
}

const ReprintButton = (props: ReprintButtonProps) => {
	const handleReprintEpson = async (withLocation: boolean) => {
		if (!props.order) {
			return
		}

		await printTicketFromOrderV2({
			withLocation: withLocation,
			order: props.order,
		})
	}

	return (
		<M.Menu withinPortal>
			<M.Menu.Target>
				<Button
					className={css({
						marginY: "10px",
						gap: 4,
					})}
				>
					Reprint
					<DropdownMenu.TriggerIcon />
				</Button>
			</M.Menu.Target>
			<M.Menu.Dropdown>
				<M.Menu.Item
					onClick={async () => {
						await handleReprintEpson(false)
					}}
				>
					Re-print (Epson)
				</M.Menu.Item>
				<M.Menu.Item
					component="a"
					href={`/sales/sales-history/invoice/${props.order!.id}`}
					target="_blank"
				>
					Re-print (PDF)
				</M.Menu.Item>
				<M.Menu.Item
					onClick={async () => {
						await handleReprintEpson(true)
					}}
				>
					Re-print with location (Epson)
				</M.Menu.Item>
			</M.Menu.Dropdown>
		</M.Menu>
	)
}

interface CostBreakdownProps {
	order?: Order | null
}

const CostBreakdown = (props: CostBreakdownProps) => {
	return (
		<M.Accordion radius="sm">
			<M.Accordion.Item value="cost-breakdown">
				<M.Accordion.Control
					className={css({
						backgroundColor: "#48BB78",
						color: "white",
						width: "400px",
						borderRadius: "5px",
					})}
				>
					<div
						className={css({
							display: "flex",
							justifyContent: "space-between",
							fontWeight: 500,
						})}
					>
						<p>Total</p>
						<p>${props.order?.total.toFixed(2)}</p>
					</div>
				</M.Accordion.Control>
				<M.Accordion.Panel>
					<div
						className={css({
							display: "grid",
							gap: "10px",
							color: "#718096",
						})}
					>
						<div
							className={css({
								display: "flex",
								justifyContent: "space-between",
							})}
						>
							<p>Subtotal</p>
							<p className={css({ fontWeight: 500 })}>
								${props.order?.subTotal?.toFixed(2) ?? "0.00"}
							</p>
						</div>
						<div
							className={css({
								display: "flex",
								justifyContent: "space-between",
							})}
						>
							<p>Tax</p>
							<p className={css({ fontWeight: 500 })}>
								{props.order?.taxable
									? `$${props.order?.taxTotal?.toFixed(2)}`
									: "N/A"}
							</p>
						</div>
						<div
							className={css({
								display: "flex",
								justifyContent: "space-between",
							})}
						>
							<p>Shipping</p>
							<p className={css({ fontWeight: 500 })}>
								{props.order?.orderShipping
									? `$${props.order?.orderShipping?.shippingCost?.toFixed(2)}`
									: "N/A"}
							</p>
						</div>
					</div>
				</M.Accordion.Panel>
			</M.Accordion.Item>
		</M.Accordion>
	)
}

interface PaymentsSectionProps {
	order?: Order | null
}

const PaymentsSection = (props: PaymentsSectionProps) => {
	const payments = props.order?.orderPayment
		?.map((pm) => {
			const amount =
				pm.paidIn.toNumber() > 0.001
					? pm.paidIn?.toNumber()
					: -pm.paidOut?.toNumber()

			return {
				...pm,
				amount,
			}
		})
		.filter((p) => p.amount !== 0)

	return (
		<S.div d="flex" flexDir="column" gap="0.5rem">
			<p className={css({ color: "#0F1729" })}>Payment method</p>
			{payments?.map((pm) => {
				const payment =
					pm.paymentType !== "USER_CREDITS"
						? paymentMethodsMap[pm.paymentType || "OTHER"]
						: { icon: UserCreditsPaymentMethodCustomIcon, text: "User credits" }

				return (
					<S.div key={pm.id} d="flex" flexDir="row" gap="0.5rem">
						<S.div
							width="2.25rem"
							d="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Icon as={payment?.icon} w="1.5rem" h="1.5rem" />
						</S.div>
						<S.p fw="400">
							{payment?.text ?? ""} (${pm?.amount})
						</S.p>
					</S.div>
				)
			})}
		</S.div>
	)
}
