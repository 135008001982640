import { S } from "#/s"
import { modals } from "@mantine/modals"
import dayjs from "dayjs"
import { useState } from "react"
import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { Select } from "@mantine/core"
import { Button } from "@gt/ui"

type CartHoldStatus = "HOLD" | "READY_TO_PAY" | "WAITING_FOR_PAYMENT"

interface UpdateHoldStatusModalProps {
	cartId: number
	customerName: string
	updatedAt: Date
	cartStatus: CartHoldStatus
}

export const openUpdateHoldStatusModal = (
	props: UpdateHoldStatusModalProps,
) => {
	modals.open({
		children: <UpdateHoldStatusModal {...props} />,
		size: "md",
		centered: true,
		modalId: "modal-update-hold-status",
	})
}

const UpdateHoldStatusModal = (props: UpdateHoldStatusModalProps) => {
	const ctx = trpc.useContext()
	const [status, setStatus] = useState<CartHoldStatus>(
		() => props.cartStatus ?? "HOLD",
	)

	const { mutate } = trpc.v2_5.cart.updateHoldStatus.useMutation({
		onError: (error) => {
			reportUserError({
				title: "Failed to update status",
				message: error.message,
			})
		},
		onSuccess: () => {
			ctx.cart.invalidate()
			ctx.v2_5.cart.invalidate()
			reportUserSuccess({
				title: "Status updated",
			})
			modals.close("modal-update-hold-status")
		},
	})

	const handleSave = () => {
		if (!["HOLD", "READY_TO_PAY", "WAITING_FOR_PAYMENT"].includes(status)) {
			return reportUserError({
				title: "Invalid status",
				message: "Invalid status",
			})
		}

		mutate({
			cartId: props.cartId,
			cartStatus: status,
		})
	}

	return (
		<S.div d="flex" flexDir="column" gap="2">
			<S.div
				d="flex"
				gap="2"
				justifyContent="space-between"
				alignItems="center"
			>
				<S.div d="flex" flexDir="column">
					<S.p fw="500" fs="14px" c="neutral.400">
						Update cart status
					</S.p>
					<S.p fw="500">{props.customerName}</S.p>
				</S.div>
				<S.div d="flex" flexDir="column">
					<S.p fw="500" fs="14px" c="neutral.400">
						Cart last updated:
					</S.p>
					<S.p fw="500">
						{dayjs(props.updatedAt).format("MM/DD/YYYY hh:mm A")}
					</S.p>
				</S.div>
			</S.div>
			<S.hr />
			<S.div d="flex" flexDir="column" mt="1rem" gap="2">
				<Select
					label="Status"
					value={status}
					onChange={(value: any) => setStatus(value)}
					data={[
						{ value: "HOLD", label: "On hold" },
						{ value: "READY_TO_PAY", label: "Ready to pay" },
						{ value: "WAITING_FOR_PAYMENT", label: "Waiting for payment" },
					]}
					withinPortal
				/>
				<S.div d="flex" alignItems="center" justifyContent="flex-end">
					<Button onClick={handleSave}>Save</Button>
				</S.div>
			</S.div>
		</S.div>
	)
}
