import { css, S, VStack } from "#/s"
import * as M from "@mantine/core"
import { useAtomValue } from "jotai/react"
import { match } from "ts-pattern"
import { formMolecule } from "../../state"
import { useMolecule } from "bunshi/react"
import { CheckmarkCircledIcon } from "#/components-ng"

export function CompleteStep() {
	const form = useMolecule(formMolecule)
	const status = useAtomValue(form.submissionStatusAtom)
	const setStep = useSetAtom(form.stepAtom)

	return match(status)
		.with("loading", () => {
			return (
				<VStack>
					<M.Loader />
					<S.p fw="medium" fs="xl" c="slate.600">
						Merging accounts...
					</S.p>
				</VStack>
			)
		})
		.with("success", () => {
			return (
				<VStack c="slate.600">
					<CheckmarkCircledIcon
						className={css({
							c: "green.500",
							fs: "48px",
						})}
					/>
					<S.p fw="medium" fs="xl">
						Accounts merged succesfully
					</S.p>
					<S.p fs="sm">Redirecting to customers list...</S.p>
				</VStack>
			)
		})
		.with("error", () => {
			return null
		})
		.exhaustive()
}