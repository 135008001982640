import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { CardLayout, CardTable } from "../components/index.js";
import dayjs from "dayjs";
import { useMemo } from "react";

interface OnHoldCardProps {
  itemSkuId: number;
}

export const OnHoldCard = ({ itemSkuId }: OnHoldCardProps) => {
  const [{ auth }] = useAuth();

  const { data, isLoading } = trpc.cart.getCartsOnHoldWithItemSku.useQuery(
    {
      itemSkuId: itemSkuId,
      filialId: auth?.user?.filialId,
    },
    {
      cacheTime: 0,
      enabled: !!itemSkuId,
    }
  );

  const carsInHold = useMemo(() => {
    if (!data) return [];
    return data?.map((cart) => {
      const customer =
        `${cart.customer?.firstName ?? ""} ${cart.customer?.lastName ?? ""}`;

      const qty =
        cart.cartItemSku.find(
          (cartItemSku) => cartItemSku.itemSkuId === itemSkuId
        )?.quantity ?? 0;

      const associated =
        `${cart.associated?.firstName ?? ""} ${
          cart.associated?.lastName ?? ""
        }`;

      const date = dayjs(cart.createdAt).format("MM/DD/YYYY");

      return [customer, `${qty}`, associated, date];
    });
  }, [data, itemSkuId]);

  return (
    <CardLayout title="On Hold">
      {isLoading ? (
        <GTLoader width={100} height={100} />
      ) : (
        <CardTable
          headers={["Customer", "Quantity", "Sales rep.", "Date"]}
          rows={carsInHold}
        />
      )}
    </CardLayout>
  );
};
