// import CampaignCreate from "./Campaign/Create.js";
// import CampaignList from "./Campaign/List.js";
// import CampaignPreview from "./Campaign/Preview.js";
import {
	EndOfDay,
	GenerateEndOfDay,
	PrintEndOfDayPdf,
} from "./EndOfDay/index.js"
import { PrintPdf } from "./MakeASale/PrintPdf.js"
import {
	MakeASale,
	MakeASaleWithCart,
	MakeASaleWithOrder,
} from "./MakeASale/index.js"
import { CartPdf } from "./PendingCarts/CartPdf.js"
import { PendingCarts } from "./PendingCarts/PendingCarts.js"
import { DeletePendingCartsOnHold } from "./PendingCarts/delete-on-hold.js"
import { EditEcomCartPage } from "./PendingCarts/edit-ecom-cart/index.page.js"
import { CartsNotifications } from "./PendingCarts/notifications/index.js"
import { VerifiedQuantityPendingCarts } from "./PendingCarts/verified-quantity/index.js"
import { ReturnForm } from "./Return/index.js"
import { ReturnPdf } from "./ReturnHistory/ReturnPdf.js"
import ReturnHistory from "./ReturnHistory/index.js"
import { Invoice } from "./SalesHistory/Invoice.js"
import SalesHistory from "./SalesHistory/index.js"
import { SalePreview } from "./SalesHistory/sale-preview.js"
import { EcommerceOrder } from "./ecommerce-orders/[id].js"
import { EcommerceOrderRefund } from "./ecommerce-orders/[id].refund.js"
import { EcommerceOrders } from "./ecommerce-orders/index.js"
import { VerifiedQuantityEcommerceOrder } from "./ecommerce-orders/verified-quantity/index.js"
import SalesScene from "./index.jsx"

export const salesRoutes = [
	{
		index: true,
		element: <SalesScene />,
		breadcrumb: "Sales",
	},
	{
		path: "make-a-sale",
		element: <MakeASale />,
		breadcrumb: "Make a sale",
	},
	{
		path: "make-a-sale/:id",
		element: <MakeASaleWithCart />,
		breadcrumb: "Make a sale",
		skipIndex: true,
	},
	{
		path: "make-a-sale/copy/:id",
		element: <MakeASaleWithOrder />,
		breadcrumb: "Make a sale",
		skipIndex: true,
	},
	{
		path: "sales-history",
		element: <SalesHistory />,
		breadcrumb: "Sales history",
	},
	{
		path: "sales-history/preview/:id",
		element: <SalePreview />,
		breadcrumb: "Sale preview",
	},
	{
		path: "pending-carts",
		element: <PendingCarts />,
		breadcrumb: "Pending carts",
	},
	{
		path: "pending-carts/delete-on-hold",
		element: <DeletePendingCartsOnHold />,
		breadcrumb: "Delete pending carts on hold",
	},
	{
		path: "pending-carts/notifications",
		element: <CartsNotifications />,
		breadcrumb: "Carts notifications",
	},
	{
		path: "pending-carts/edit-ecom/:cartUuid",
		element: <EditEcomCartPage />,
		breadcrumb: "Edit e-commerce cart",
	},
	{
		path: "pending-carts/verified-quantity/:id",
		element: <VerifiedQuantityPendingCarts />,
		breadcrumb: "Verified quantity",
	},
	{
		path: "end-of-day",
		element: <EndOfDay />,
		breadcrumb: "End of day",
	},
	{
		path: "end-of-day/generate/:id",
		element: <GenerateEndOfDay />,
		breadcrumb: "End of day",
		skipIndex: true,
	},
	{
		path: "return",
		element: <ReturnForm />,
		breadcrumb: "Return",
	},
	{
		path: "return-history",
		element: <ReturnHistory />,
		breadcrumb: "Return history",
	},
	// {
	//   path: "campaigns/list",
	//   element: <CampaignList />,
	//   breadcrumb: "Campaigns",
	// },
	// {
	//   path: "campaigns/list/create",
	//   element: <CampaignCreate />,
	//   breadcrumb: "Campaign: New",
	// },
	// {
	//   path: "campaigns/list/preview/:id",
	//   element: <CampaignPreview />,
	//   breadcrumb: "Campaign: Preview",
	// },
	{
		path: "ecommerce-orders",
		element: <EcommerceOrders />,
		breadcrumb: "Ecommerce orders",
	},
	{
		path: "ecommerce-orders/:id",
		element: <EcommerceOrder />,
		breadcrumb: "Ecommerce order",
	},
	{
		path: "ecommerce-orders/:id/refund",
		element: <EcommerceOrderRefund />,
		breadcrumb: "Refund",
	},
	{
		path: "verified-quantity/ecommerce-orders/:id",
		element: <VerifiedQuantityEcommerceOrder />,
		breadcrumb: "Verified quantity",
	},
]

export const salesRoutesFreestanding = [
	{
		path: "end-of-day/print/:id",
		element: <PrintEndOfDayPdf />,
		breadcrumb: "End of day",
	},
	{
		path: "end-of-day/print/:id/preview",
		element: <PrintEndOfDayPdf preview />,
		breadcrumb: "End of day",
	},
	{
		path: "sales-history/invoice/:id",
		element: <Invoice />,
	},
	{
		path: "pending-carts/pdf/:id",
		element: <CartPdf />,
	},
	{
		path: "return-history/print/:reference",
		element: <ReturnPdf />,
	},
	{
		path: "make-a-sale/pdf",
		element: <PrintPdf />,
	},
]
