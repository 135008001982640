import { S, css } from "#/s"
import type { TutorialFormSchema } from "../tutorial-form-component/form"
import { trpc } from "#/trpc"
import { TutorialForm } from "../tutorial-form-component"
import { reportUserError, reportUserSuccess } from "#/util"
import { useNavigate } from "react-router"

export function CreateTutorialScene() {
	const navigate = useNavigate()

	const { mutate } = trpc.v2_5.tutorial.create.useMutation({
		onError: (error) => {
			reportUserError({
				title: "Failed to create tutorial",
				message: error.message,
			})
		},
		onSuccess: () => {
			// Redirect to list
			navigate("/ecommerce-crm/tutorials/")
			reportUserSuccess({
				title: "Success",
				message: "Tutorial saved successfully",
			})
		},
	})

	const handleSaveTutorial = (values: TutorialFormSchema) => {
		mutate({
			...values,
			itemSkus: values.itemSkus.map((item) => item.id),
		})
	}

	return (
		<S.div
			css={{
				maxWidth: "600px",
				mx: "auto",
				px: "3rem",
				py: "2.5rem",
				bg: "white",
				rounded: "1rem",
			}}
		>
			<S.h1 css={{ fontSize: "1.75rem", fontWeight: "bold", mb: "2rem" }}>
				Create Tutorial
			</S.h1>

			<TutorialForm onSubmit={handleSaveTutorial} />
		</S.div>
	)
}
