import { BarChart } from "#/components-ng";
import GTLoader from "#/components-ng/loader";
import { trpc } from "#/trpc";
import {
  C,
  FormValues,
  generateCustomersWithMostLVReportSchema,
  customersRange,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { FormProvider } from "react-hook-form";

export const CustomersWithMostLVReport = () => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);

  const { data: entities } = trpc.entity.getAll.useQuery();
  const { data: filials } = trpc.filial.getAll.useQuery();

  const form = C.useForm({
    resolver: zodResolver(generateCustomersWithMostLVReportSchema),
    shouldUnregister: false,
    defaultValues: {
      dateRange: [dateRange[0]!, dateRange[1]!],
      customersRange: "10",
      filter: {
        by: "entity",
        id: "1",
      },
    },
  });

  const {
    data: report,
    mutate: generateReport,
    isLoading,
  } = trpc.v2_5.report.getCustomersWithMostLifetimeValue.useMutation();

  useEffect(() => {
    generateReport({
      dateRange: {
        from: dateRange[0]!,
        to: dateRange[1]!,
      },
      customersRange: 10,
      filter: {
        by: "entity",
        id: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entityOptions = useMemo(
    () =>
      entities?.map((entity) => ({
        label: entity.name,
        value: entity.id.toString(),
      })) ?? [],
    [entities]
  );

  const filialOptions = useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [filials]
  );

  const data = useMemo(() => {
    if (report) {
      return report;
    }
    return null;
  }, [report]);

  const handleSubmit = (values: FormValues) => {
    generateReport({
      dateRange: {
        from: values.dateRange[0],
        to: values.dateRange[1],
      },
      customersRange: Number(values.customersRange),
      filter: {
        by: values.filter.by,
        id: Number(values.filter.id),
      },
    });
  };

  const filterBy = form.watch("filter.by");

  return (
    <M.Container size="xl">
      <M.Stack spacing="md">
        <M.Title order={2}>
          Customers with most lifetime value{" "}
          <span className="text-sm">(ganancia bruta - numero de ordenes)</span>
        </M.Title>
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              form.handleSubmit(handleSubmit)(e);
            }}
          >
            <M.Group>
              <C.M
                as={M.Select}
                label="Report type"
                name="filter.by"
                data={[
                  { label: "Entity", value: "entity" },
                  { label: "Filial", value: "filial" },
                ]}
                required
              />
              <C.M
                as={M.Select}
                label={
                  filterBy === "entity" ? "Select entity" : "Select filial"
                }
                name="filter.id"
                data={filterBy === "entity" ? entityOptions : filialOptions}
                required
              />
              <C.M
                as={DatePickerInput}
                label="Date range"
                name="dateRange"
                type="range"
                onChange={(v) => {
                  if(!v) return;
                  if (v[0] == null || v[1] == null) return;
                  setDateRange([v[0], v[1]]);
                }}
                numberOfColumns={2}
                popoverProps={{
                  zIndex: 9000,
                }}
                w={300}
                required
              />
              <C.M
                as={M.Select}
                label="Customers range"
                name="customersRange"
                data={customersRange.map((r) => ({
                  label: r,
                  value: r,
                }))}
                className="w-[150px]"
              />
              <M.Button
                type="submit"
                loading={isLoading}
                className="mt-6 px-12 "
              >
                Generate
              </M.Button>
            </M.Group>
          </form>
        </FormProvider>
        {isLoading ? (
          <M.LoadingOverlay
            visible={isLoading}
            loader={<GTLoader width={100} height={100} />}
          />
        ) : (
          <BarChart
            pathname="/customers/list/preview/"
            enableRedirect
            textLabel="Lifetime value $"
            data={
              data?.map((p) => ({
                id: p.customerId,
                label: `${p.customer?.firstName ?? ""} ${
                  p.customer?.lastName ?? ""
                }`,
                total: p.totalLifetimeValue,
              })) ?? []
            }
          />
        )}
      </M.Stack>
    </M.Container>
  );
};
