import { createScope, molecule } from "bunshi"
import { User } from "../types"
import { atom } from "jotai/vanilla"

export const formScope = createScope<unknown>(null)

export const formMolecule = molecule(() => {
	const stepAtom = atom(0)
	const primaryAccountAtom = atom<null | User>(null)
	const secondaryAccountAtom = atom<null | User>(null)
	const areSelectAccountsStepConditionsMetAtom = atom((get) => {
		const primaryAccount = get(primaryAccountAtom)
		const secondaryAccount = get(secondaryAccountAtom)

		return primaryAccount != null && secondaryAccount != null
	})

	const areRequiredConditionsMetAtom = atom((get) => {
		const step = get(stepAtom)
		if (step === 0) {
			return get(areSelectAccountsStepConditionsMetAtom)
		}
		return true
	})

	const submittedAtAtom = atom<null | Date>(null)
	const submissionStatusAtom = atom<"loading" | "success" | "error">("loading")

	return {
		stepAtom,
		primaryAccountAtom,
		secondaryAccountAtom,
		areSelectAccountsStepConditionsMetAtom,
		areRequiredConditionsMetAtom,
		submittedAtAtom,
		submissionStatusAtom,
	}
})
