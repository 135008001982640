import GTLoader from '#/components-ng/loader.js'
import { WidgetContainer } from '#/scenes/Sales/EndOfDay/GenerateEndOfDay/common.js'
import { trpc } from '#/trpc.js'
import { CardContainer, PieChartCard } from '../../../common.js'
import { exportFilialReportToXLSX } from '../exportXlsx.js'
import { reportBaseDataAtom } from '../state/index.js'
import * as M from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import Decimal from 'decimal.js'
import { useAtomValue } from 'jotai'
import React from 'react'

export const SalesFilialInner = () => {
  const baseData = useAtomValue(reportBaseDataAtom)

  const [dateRange, setDateRange] = React.useState<[Date | null, Date | null]>([
    baseData.fromDate,
    baseData.toDate,
  ])

  const { data: dataFilial, isFetching: isFetchingFilial } =
    trpc.report.summaryByFilial.useQuery(
      {
        start: dateRange[0]!,
        end: dateRange[1]!,
        filialId: baseData.filialId as number,
      },
      {
        enabled:
          baseData.type === 'filial' &&
          baseData.filialId &&
          baseData.send &&
          dateRange[0] &&
          dateRange[1]
            ? true
            : false,
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    )

  const { data: dataEntity, isFetching: isFetchingEntity } =
    trpc.report.summaryByEntity.useQuery(
      {
        start: dateRange[0]!,
        end: dateRange[1]!,
        entityId: baseData.entityId as number,
      },
      {
        enabled:
          baseData.type === 'entity' &&
          baseData.entityId &&
          baseData.send &&
          dateRange[0] &&
          dateRange[1]
            ? true
            : false,
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    )

  const isLoading =
    baseData.type === 'entity'
      ? isFetchingEntity
      : baseData.type === 'filial'
        ? isFetchingFilial
        : false

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colors = [
    {
      color: '#4DABF7',
      used: false,
    },
    {
      color: '#9775F4',
      used: false,
    },
    {
      color: '#ED79B6',
      used: false,
    },
    {
      color: '#64C19C',
      used: false,
    },
  ]

  const data = useMemo(() => {
    if (baseData.type === 'filial' && dataFilial && !isLoading) {
      return dataFilial
    } else if (baseData.type === 'entity' && dataEntity && !isLoading) {
      return dataEntity
    } else {
      return null
    }
  }, [baseData.type, dataEntity, dataFilial, isLoading])

  const totalSalesByCashier = useMemo(() => {
    return (
      data?.usersReports?.users
        ?.map((userReport) => {
          const color = colors.find((c) => !c.used)
          if (color) color.used = true

          return {
            cashier: `${userReport?.user?.firstName} ${
              userReport?.user?.lastName ?? ''
            }`,
            totalSales: userReport?.totalSales,
            color:
              color?.color ??
              `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          }
        })
        .concat({
          cashier: 'Unassisted',
          totalSales: data?.usersReports?.unassisted?.totalSales,
          color:
            colors.find((c) => !c.used)?.color ??
            `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        }) ?? []
    )
  }, [colors, data?.usersReports])

  const totalSalesByOrderType = useMemo(() => {
    return (
      data?.salesActiviyByOrderType?.map((order) => {
        const color = colors.find((c) => !c.used)
        if (color) color.used = true

        return {
          orderType: order?.orderType,
          totalOrderAmount: order?.totalOrderAmount,
          color:
            color?.color ??
            `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        }
      }) ?? []
    )
  }, [colors, data?.salesActiviyByOrderType])

  const totalCountOrdersByOrderType = useMemo(() => {
    return (
      data?.salesActiviyByOrderType?.map((order) => {
        const color = colors.find((c) => !c.used)
        if (color) color.used = true

        return {
          orderType: order?.orderType,
          totalCountOrders: order?.orderCount,
          color:
            color?.color ??
            `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        }
      }) ?? []
    )
  }, [colors, data?.salesActiviyByOrderType])

  const totalCustomerAttendsByOrderType = useMemo(() => {
    return (
      data?.summaryCustomersByOrdersType?.map((order) => {
        return {
          orderType: order?.orderType,
          totalCustomers: order?.totalCustomerAttends + order?.guessCustomers,
          summary: [
            {
              title: 'Guess customers',
              value: order?.guessCustomers,
              color:
                colors.find((c) => !c.used)?.color ??
                `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            },
            {
              title: 'New customers',
              value: order?.newCustomers,
              color:
                colors.find((c) => !c.used)?.color ??
                `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            },
            {
              title: 'Registered customers',
              value: order?.registeredCustomers,
              color:
                colors.find((c) => !c.used)?.color ??
                `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            },
          ],
        }
      }) ?? []
    )
  }, [colors, data?.summaryCustomersByOrdersType])

  return (
    <M.Container size="lg">
      <M.Group position="apart">
        <M.Title order={2}>
          {isLoading ? '' : `${data?.name ?? ''}`} - Summary
        </M.Title>
        <DatePickerInput
          type="range"
          value={dateRange}
          onChange={setDateRange}
          numberOfColumns={2}
          popoverProps={{
            zIndex: 9000,
          }}
          w={400}
        />
      </M.Group>
      <M.Divider color="gray.2" my="lg" />
      {isLoading ? (
        <M.LoadingOverlay
          visible={isLoading}
          loader={<GTLoader width={100} height={100} />}
        />
      ) : (
        <>
          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total Sales"
              text={`$${data?.totalSales.toNumber().toFixed(2)}`}
              total={data?.totalSales.toNumber() ?? 0}
              data={
                totalSalesByCashier?.map((cashier) => ({
                  title: cashier.cashier,
                  value: cashier.totalSales.toNumber(),
                  color: cashier.color,
                })) ?? []
              }
              isMoney={true}
            />
          </M.Group>

          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total Sales by order type"
              text={`$${
                totalSalesByOrderType
                  .reduce(
                    (acc, order) => acc.plus(order.totalOrderAmount),
                    new Decimal(0),
                  )
                  ?.toNumber()
                  ?.toFixed(2) ?? 0
              }`}
              total={
                totalSalesByOrderType
                  .reduce(
                    (acc, order) => acc.plus(order.totalOrderAmount),
                    new Decimal(0),
                  )
                  .toNumber() ?? 0
              }
              data={
                totalSalesByOrderType.map((order) => ({
                  title: order.orderType,
                  value: order.totalOrderAmount.toNumber(),
                  color: order.color,
                })) ?? []
              }
              isMoney={true}
            />
            <PieChartCard
              title="Total orders by order type"
              text={`
                ${totalCountOrdersByOrderType.reduce(
                  (acc, order) => acc + order.totalCountOrders,
                  0,
                )}
              `}
              total={
                totalCountOrdersByOrderType.reduce(
                  (acc, order) => acc + order.totalCountOrders,
                  0,
                ) ?? 0
              }
              data={
                totalCountOrdersByOrderType.map((order) => ({
                  title: order.orderType,
                  value: order.totalCountOrders,
                  color: order.color,
                })) ?? []
              }
              isMoney={false}
            />
          </M.Group>

          <M.Group align="stretch" mb="lg">
            <PieChartCard
              title="Total Customers - ONLINE"
              text={`${
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === 'ONLINE',
                )?.totalCustomers
              }`}
              total={
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === 'ONLINE',
                )?.totalCustomers ?? 0
              }
              data={
                totalCustomerAttendsByOrderType
                  .find((order) => order.orderType === 'ONLINE')
                  ?.summary.map((order) => ({
                    title: order.title,
                    value: order.value,
                    color: order.color,
                  })) ?? []
              }
              isMoney={false}
            />
            <PieChartCard
              title="Total Customers - IN_STORE"
              text={`${
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === 'IN_STORE' || order.orderType === 'PICKUP_FROM_STORE',
                )?.totalCustomers
              }`}
              total={
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === 'IN_STORE' || order.orderType === 'PICKUP_FROM_STORE',
                )?.totalCustomers ?? 0
              }
              data={
                totalCustomerAttendsByOrderType
                  .find((order) => order.orderType === 'IN_STORE' || order.orderType === 'PICKUP_FROM_STORE')
                  ?.summary.map((order) => ({
                    title: order.title,
                    value: order.value,
                    color: order.color,
                  })) ?? []
              }
              isMoney={false}
            />
            <PieChartCard
              title="Total Customers - PICKUP"
              text={`${
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === 'PICKUP',
                )?.totalCustomers
              }`}
              total={
                totalCustomerAttendsByOrderType.find(
                  (order) => order.orderType === 'PICKUP',
                )?.totalCustomers ?? 0
              }
              data={
                totalCustomerAttendsByOrderType
                  .find((order) => order.orderType === 'PICKUP')
                  ?.summary.map((order) => ({
                    title: order.title,
                    value: order.value,
                    color: order.color,
                  })) ?? []
              }
              isMoney={false}
            />
          </M.Group>

          <CardContainer sx={{ marginBottom: 20 }}>
            <M.Accordion>
              <M.Accordion.Item value="salesReport-details">
                <M.Accordion.Control>
                  <M.Title order={4}>View details</M.Title>
                </M.Accordion.Control>
                <M.Accordion.Panel>
                  <M.Stack>
                    <M.Group align="start" noWrap>
                      <M.Stack justify="start" sx={{ flex: 1 }}>
                        <WidgetContainer>
                          <M.Stack>
                            <M.Title order={5} color="#4DABF7">
                              Orders
                            </M.Title>
                            <M.SimpleGrid cols={1}>
                              <M.Group spacing={20} position="apart">
                                <M.Text size="sm" color="gray.6">
                                  Completed Orders
                                </M.Text>
                                <M.Text size="sm" weight={600}>
                                  {data?.ordersQty}
                                </M.Text>
                              </M.Group>
                              <M.Group spacing={20} position="apart">
                                <M.Text size="sm" color="gray.6">
                                  Reversed Orders
                                </M.Text>
                                <M.Text size="sm" weight={600}>
                                  {
                                    data?.inventoryMovement.returned
                                      .totalCountProductsReturned
                                  }
                                </M.Text>
                              </M.Group>
                              <M.Group spacing={20} position="apart">
                                <M.Text size="sm" color="gray.6">
                                  New customers
                                </M.Text>
                                <M.Text size="sm" weight={600}>
                                  {data?.newCustomers}
                                </M.Text>
                              </M.Group>
                            </M.SimpleGrid>
                          </M.Stack>
                        </WidgetContainer>

                        <WidgetContainer>
                          <M.Stack justify="start" sx={{ flex: 1 }}>
                            <M.Title order={5} color="#4DABF7">
                              Inventory movement
                            </M.Title>
                            <M.Grid
                              columns={12}
                              gutter={0}
                              sx={{ rowGap: '0.55rem' }}
                            >
                              <M.Grid.Col span={4}>
                                <M.Text size="sm" color="gray.6" align="center">
                                  Count
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text size="sm" color="gray.6" align="center">
                                  Sold Items
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text size="sm" color="gray.6" align="center">
                                  Returned Items
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="center" size="sm" weight={600}>
                                  {data?.inventoryMovement.count}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="center" size="sm" weight={600}>
                                  {
                                    data?.inventoryMovement.noReturned
                                      .totalCountProductsTransacted
                                  }
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="center" size="sm" weight={600}>
                                  {
                                    data?.inventoryMovement.returned
                                      .totalCountProductsReturned
                                  }
                                </M.Text>
                              </M.Grid.Col>
                            </M.Grid>
                          </M.Stack>
                        </WidgetContainer>

                        <WidgetContainer>
                          <M.Stack justify="start" sx={{ flex: 1 }}>
                            <M.Title order={5} color="#4DABF7">
                              Activity
                            </M.Title>
                            <M.Grid
                              columns={12}
                              gutter={0}
                              sx={{ rowGap: '0.55rem' }}
                            >
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total order amount:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.activity.totalOrderAmount
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total tax amount:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.activity.totalTaxAmount
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total returned amount:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.activity.totalReturnedAmount
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total shipping:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.activity.totalShipping
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total activity amount:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.activity.totalActivityAmount
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total available for deposit:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.activity.totalAvaliableForDeposit
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                            </M.Grid>
                          </M.Stack>
                        </WidgetContainer>
                      </M.Stack>

                      <M.Stack justify="start" sx={{ flex: 1 }}>
                        <WidgetContainer>
                          <M.Stack justify="start" sx={{ flex: 1 }}>
                            <M.Title order={5} color="#4DABF7">
                              Payments
                            </M.Title>
                            <M.Grid
                              columns={12}
                              gutter={0}
                              sx={{ rowGap: '0.55rem' }}
                            >
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total paid in:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.payments.totalPaidIn
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total paid out:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.payments.totalPaidOut
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={8}>
                                <M.Text size="sm" color="gray.6">
                                  Total amount:
                                </M.Text>
                              </M.Grid.Col>
                              <M.Grid.Col span={4}>
                                <M.Text align="right" size="sm" weight={600}>
                                  $
                                  {data?.payments.totalAmount
                                    .toNumber()
                                    .toFixed(2)}
                                </M.Text>
                              </M.Grid.Col>
                            </M.Grid>

                            <M.Stack sx={{ marginLeft: '1rem' }}>
                              <M.Text weight={'bold'} size="sm">
                                Cash
                              </M.Text>
                              <M.Grid
                                columns={12}
                                gutter={0}
                                sx={{ rowGap: '0.55rem' }}
                              >
                                <M.Grid.Col span={8}>
                                  <M.Text size="sm" color="gray.6">
                                    Total paid in:
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={4}>
                                  <M.Text align="right" size="sm" weight={600}>
                                    $
                                    {data?.payments.paymentMethods
                                      .find(
                                        (payment) => payment.method === 'CASH',
                                      )
                                      ?.totalPaidIn.toNumber()
                                      .toFixed(2) || 0.0}
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={8}>
                                  <M.Text size="sm" color="gray.6">
                                    Total paid out:
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={4}>
                                  <M.Text align="right" size="sm" weight={600}>
                                    $
                                    {data?.payments.paymentMethods
                                      .find(
                                        (payment) => payment.method === 'CASH',
                                      )

                                      ?.totalPaidOut.toNumber()
                                      .toFixed(2) || 0.0}
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={8}>
                                  <M.Text size="sm" color="gray.6">
                                    Total amount:
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={4}>
                                  <M.Text align="right" size="sm" weight={600}>
                                    $
                                    {data?.payments.paymentMethods
                                      .find(
                                        (payment) => payment.method === 'CASH',
                                      )
                                      ?.totalAmount.toNumber()
                                      .toFixed(2) || 0.0}
                                  </M.Text>
                                </M.Grid.Col>
                              </M.Grid>
                            </M.Stack>

                            <M.Stack sx={{ marginLeft: '1rem' }}>
                              <M.Text weight={'bold'} size="sm">
                                All Credit/Debit cards
                              </M.Text>
                              <M.Grid
                                columns={12}
                                gutter={0}
                                sx={{ rowGap: '0.55rem' }}
                              >
                                <M.Grid.Col span={8}>
                                  <M.Text size="sm" color="gray.6">
                                    Total paid in:
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={4}>
                                  <M.Text align="right" size="sm" weight={600}>
                                    $
                                    {data?.payments.paymentMethods
                                      .filter(
                                        (payment) => payment.method !== 'CASH',
                                      )
                                      .reduce(
                                        (acc, payment) =>
                                          acc.plus(payment.totalPaidIn),
                                        new Decimal(0),
                                      )
                                      .toNumber()
                                      .toFixed(2) || 0}
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={8}>
                                  <M.Text size="sm" color="gray.6">
                                    Total paid out:
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={4}>
                                  <M.Text align="right" size="sm" weight={600}>
                                    $
                                    {data?.payments.paymentMethods
                                      .filter(
                                        (payment) => payment.method !== 'CASH',
                                      )
                                      .reduce(
                                        (acc, payment) =>
                                          acc.plus(payment.totalPaidOut),
                                        new Decimal(0),
                                      )
                                      .toNumber()
                                      .toFixed(2) || 0}
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={8}>
                                  <M.Text size="sm" color="gray.6">
                                    Total amount:
                                  </M.Text>
                                </M.Grid.Col>
                                <M.Grid.Col span={4}>
                                  <M.Text align="right" size="sm" weight={600}>
                                    $
                                    {data?.payments.paymentMethods
                                      .filter(
                                        (payment) => payment.method !== 'CASH',
                                      )
                                      .reduce(
                                        (acc, payment) =>
                                          acc.plus(payment.totalAmount),
                                        new Decimal(0),
                                      )
                                      .toNumber()
                                      .toFixed(2) || 0}
                                  </M.Text>
                                </M.Grid.Col>
                              </M.Grid>
                            </M.Stack>

                            {data?.payments.paymentMethods
                              .filter((payment) => payment.method !== 'CASH')
                              .map((payment) => (
                                <M.Stack
                                  sx={{ marginLeft: '1rem' }}
                                  key={payment.method}
                                >
                                  <M.Text weight={'bold'} size="sm">
                                    {payment.method}
                                  </M.Text>
                                  <M.Grid
                                    columns={12}
                                    gutter={0}
                                    sx={{ rowGap: '0.55rem' }}
                                  >
                                    <M.Grid.Col span={8}>
                                      <M.Text size="sm" color="gray.6">
                                        Total paid in:
                                      </M.Text>
                                    </M.Grid.Col>
                                    <M.Grid.Col span={4}>
                                      <M.Text
                                        align="right"
                                        size="sm"
                                        weight={600}
                                      >
                                        $
                                        {payment.totalPaidIn
                                          .toNumber()
                                          .toFixed(2)}
                                      </M.Text>
                                    </M.Grid.Col>
                                    <M.Grid.Col span={8}>
                                      <M.Text size="sm" color="gray.6">
                                        Total paid out:
                                      </M.Text>
                                    </M.Grid.Col>
                                    <M.Grid.Col span={4}>
                                      <M.Text
                                        align="right"
                                        size="sm"
                                        weight={600}
                                      >
                                        $
                                        {payment.totalPaidOut
                                          .toNumber()
                                          .toFixed(2)}
                                      </M.Text>
                                    </M.Grid.Col>
                                    <M.Grid.Col span={8}>
                                      <M.Text size="sm" color="gray.6">
                                        Total amount:
                                      </M.Text>
                                    </M.Grid.Col>
                                    <M.Grid.Col span={4}>
                                      <M.Text
                                        align="right"
                                        size="sm"
                                        weight={600}
                                      >
                                        $
                                        {payment.totalAmount
                                          .toNumber()
                                          .toFixed(2)}
                                      </M.Text>
                                    </M.Grid.Col>
                                  </M.Grid>
                                </M.Stack>
                              ))}
                          </M.Stack>
                        </WidgetContainer>

                        <M.Button
                          w="100%"
                          mt={4}
                          onClick={async () => {
                            if (data) {
                              await exportFilialReportToXLSX(data as any)
                            }
                          }}
                        >
                          Export Details to XLSX
                        </M.Button>
                      </M.Stack>
                    </M.Group>
                  </M.Stack>
                </M.Accordion.Panel>
              </M.Accordion.Item>
            </M.Accordion>
          </CardContainer>
        </>
      )}
    </M.Container>
  )
}
