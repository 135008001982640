import { S, css } from "#/s"
import * as M from "@mantine/core"
import { C } from "./form"
import { useState } from "react"
import { trpc } from "#/trpc.js"
import { debounce } from "lodash"
import { MultiSelect } from "#/components-ng"

interface TutorialFormFieldsProps {
	activeLanguage: "english" | "spanish"
}

export function TutorialFormFields({ activeLanguage }: TutorialFormFieldsProps) {
	const languageKey = activeLanguage === "english" ? "en" : "es"
	const [searchQuery, setSearchQuery] = useState("")

	// Use the itemSku search query
	const { data: searchResults, isFetching } = trpc.itemSku.search.useQuery(
		{
			query: searchQuery,
		},
		{
			enabled: searchQuery.length > 1, // Only search when query has at least 3 characters
			keepPreviousData: true,
		},
	)

	// Debounced search handler
	const debouncedSearch = debounce((value: string) => {
		setSearchQuery(value)
	}, 300)

	return (
		<>
			<S.div css={{ mb: "1.5rem" }}>
				<C.Controller
					key={`url-${languageKey}`} // Added key to reset on language change
					name={`url.${languageKey}`}
					render={({ field, fieldState }) => (
						<M.TextInput
							label="Link tutorial"
							placeholder="Enter tutorial link"
							description="Must be a valid YouTube link (youtube.com or youtu.be)"
							value={field.value}
							onChange={(e) => field.onChange(e.currentTarget.value)}
							className={css({ width: "100%" })}
							error={fieldState.error?.message}
						/>
					)}
				/>
			</S.div>

			<S.div css={{ mb: "1.5rem" }}>
				<C.Controller
					key={`title-${languageKey}`} // Added key to reset on language change
					name={`title.${languageKey}`}
					render={({ field }) => (
						<M.TextInput
							label="Title"
							placeholder="Enter tutorial title"
							value={field.value}
							onChange={(e) => field.onChange(e.currentTarget.value)}
							className={css({ width: "100%" })}
						/>
					)}
				/>
			</S.div>

			<S.div css={{ mb: "1.5rem" }}>
				<C.Controller
					key={`description-${languageKey}`} // Added key to reset on language change
					name={`description.${languageKey}`}
					render={({ field }) => (
						<M.Textarea
							label="Content"
							placeholder="Enter tutorial description"
							value={field.value}
							onChange={(e) => field.onChange(e.currentTarget.value)}
							minRows={4}
							className={css({ width: "100%" })}
						/>
					)}
				/>
			</S.div>

			<S.div css={{ mb: "2rem" }}>
				<C.Controller
					key={`itemSkus-${languageKey}`} // Added key to reset on language change
					name="itemSkus"
					render={({ field }) => (
						<MultiSelect
							label="SKUs"
							data={searchResults ?? []}
							placeholder="Search for products"
							value={field.value}
							entryId={(item) => item.id.toString()}
							entryLabel={(item) => `${item.sku} - ${item.title}`}
							onChange={field.onChange}
							searchable
							clearable
							onSearchChange={debouncedSearch}
							nothingFound={
								searchQuery.length > 1
									? "No products found"
									: "Type at least 2 characters to search"
							}
							className={css({ width: "100%" })}
							styles={{
								value: {
									backgroundColor: "#f1f3f5",
									color: "#495057",
									border: "none",
									borderRadius: "4px",
								},
							}}
							rightSection={isFetching ? <M.Loader size="xs" /> : null}
						/>
					)}
				/>
			</S.div>
		</>
	)
}
