import { RouterPrompt } from "#/components/index.js"
import { trpc } from "#/trpc.js"
import {
	cartEntriesAtom,
	cartEntriesReturnedAtom,
	customerAtom,
	submittedAtom,
} from "./state/index.js"
import { saveCartAtom } from "./state/cart.js"
import { reportAtom } from "./state/reporting.js"
import * as M from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { useAtomValue, useSetAtom } from "jotai/react"
import React from "react"
import { useNavigate } from "react-router"
import { ChevronDownOutline } from "#/components-ng/icons.js"

const RouterPromptConfirmation = ({ extraButtons }) => {
	const customer = useAtomValue(customerAtom)
	const cartEntries = useAtomValue(cartEntriesAtom)
	const cartEntriesReturned = useAtomValue(cartEntriesReturnedAtom)
	const submitted = useAtomValue(submittedAtom)
	const when =
		(!!customer || cartEntries.length > 0 || cartEntriesReturned.length > 0) &&
		!submitted

	return <RouterPrompt when={when} extraButtons={extraButtons} />
}

export function PutOnHoldButton() {
	const navigate = useNavigate()
	const saveCart = useSetAtom(saveCartAtom)
	const report = useAtomValue(reportAtom)
	const customer = useAtomValue(customerAtom)
	const setSubmitted = useSetAtom(submittedAtom)
	const [loading, setLoading] = React.useState(false)
	const ctx = trpc.useContext()

	const putOnHold = async (
		cartStatus: "HOLD" | "WAITING_FOR_PAYMENT" | "READY_TO_PAY",
	) => {
		setSubmitted(true)
		setLoading(true)
		try {
			if (!customer) {
				report.error({
					id: "make-a-sale__put-on-hold__no-customer",
					title: "Failed to put cart on hold",
					description: "You must add a customer",
				})
				return
			}
			await saveCart(cartStatus)
			ctx.cart.invalidate()
			ctx.cartItemSku.invalidate()
      ctx.v2_5.cart.invalidate()
			navigate("/sales")
		} catch (e) {
			showNotification({
				title: "Failed to put cart on hold",
				message: e?.message,
				color: "red",
			})
		} finally {
			setLoading(false)
		}
	}

	return (
		<div style={{ width: "100%" }}>
			<RouterPromptConfirmation
				extraButtons={({ onConfirm }) => (
					<M.Button
						variant="light"
						color="darkGray"
						sx={(t) => ({ color: t.colors.gray[8] })}
						onClick={async () => {
							await putOnHold("HOLD")
							onConfirm()
						}}
					>
						Put on hold
					</M.Button>
				)}
			/>
			<M.Menu disabled={loading}>
				<M.Menu.Target>
					<M.Button
						variant="light"
						color="darkGray"
						sx={{ width: "100%" }}
						rightIcon={<ChevronDownOutline />}
					>
						Put on hold
					</M.Button>
				</M.Menu.Target>
				<M.Menu.Dropdown>
					<M.Menu.Item
						onClick={async () => {
							await putOnHold("HOLD")
						}}
					>
						Put on hold
					</M.Menu.Item>
					<M.Menu.Item
						onClick={async () => {
							await putOnHold("WAITING_FOR_PAYMENT")
						}}
					>
						Put on waiting for payment
					</M.Menu.Item>
					<M.Menu.Item
						onClick={async () => {
							await putOnHold("READY_TO_PAY")
						}}
					>
						Put on ready to pay
					</M.Menu.Item>
				</M.Menu.Dropdown>
			</M.Menu>
		</div>
	)
}
