import { S } from "#/s"
import { trpc, type RouterOutputs } from "#/trpc"
import { modals } from "@mantine/modals"
import { animations } from "@formkit/drag-and-drop"
import { useDragAndDrop } from "@formkit/drag-and-drop/react"
import { SortIcon } from "#/components-ng"
import { Button } from "@gt/ui"
import { reportUserSuccess } from "#/util"

type CatalogImage = RouterOutputs["v2_5"]["catalog"]["getAllCatalogImages"][0]

interface SortCatalogImagesModalProps {
	catalogImages: CatalogImage[]
}

export const openSortCatalogImagesModal = (
	props: SortCatalogImagesModalProps,
) => {
	modals.open({
		title: <S.h3>Sort Catalog Images</S.h3>,
		size: "md",
		children: <SortCatalogImagesModalChildren {...props} />,
		zIndex: 40,
		centered: true,
		modalId: "SORT_CATALOG_IMAGES_MODAL",
		closeOnClickOutside: false,
		closeOnEscape: false,
	})
}

const SortCatalogImagesModalChildren = (props: SortCatalogImagesModalProps) => {
	const [parent, images] = useDragAndDrop<HTMLUListElement, CatalogImage>(
		props.catalogImages,
		{ plugins: [animations()] },
	)

	const ctx = trpc.useContext()
	const { mutate, isLoading } = trpc.v2_5.catalog.sortCatalogImages.useMutation(
		{
			onSuccess: () => {
				reportUserSuccess({
					title: "Catalog images sorted",
					message: "Catalog images have been sorted successfully",
				})
				ctx.v2_5.catalog.getCatalogImagesByPage.invalidate()
				modals.close("SORT_CATALOG_IMAGES_MODAL")
			},
		},
	)

	const handleSave = () => {
		mutate({
			catalogImages: images.map((image, index) => ({
				id: image.id,
				position: index,
			})),
		})
	}

	return (
		<S.div>
			<S.div d="flex" justifyContent="space-between" alignItems="center" mb="2">
				<S.p fs="md" color="gray.500">
					Drag and drop to sort the images
				</S.p>
				<Button onClick={handleSave} isLoading={isLoading}>
					Save
				</Button>
			</S.div>
			<S.ul ref={parent} d="flex" flexDir="column" gap="2">
				{images.map((image) => (
					<S.li key={image.id} data-label={image} cursor="pointer">
						<S.div
							d="flex"
							justifyContent="space-around"
							gap="4"
							alignItems="center"
							borderWidth="1px"
							p="10px"
							w="full"
							borderStyle="solid"
							borderColor="gray.200"
							rounded="md"
						>
							<S.img src={image.url} w="80px" h="80px" />
							<SortIcon />
						</S.div>
					</S.li>
				))}
			</S.ul>
		</S.div>
	)
}
