import { S } from "#/s"
import { CustomerInfo } from "../../customer-info"
import { TwoColumnsLayout } from "../../two-columns-layout"
import { User } from "../../types"
import { Select } from "#/components-ng"
import { useMolecule } from "bunshi/react"
import { formMolecule } from "../../state"
import { trpc } from "#/trpc"

export function SelectCustomersStep() {
	return (
		<TwoColumnsLayout
			left={
				<S.div>
					<PrimaryAccountField />
					<S.div mt="6">
						<PrimaryAccountInfo />
					</S.div>
				</S.div>
			}
			right={
				<S.div>
					<SecondaryAccountField />
					<S.div mt="6">
						<SecondaryAccountInfo />
					</S.div>
				</S.div>
			}
		/>
	)
}

function PrimaryAccountInfo() {
	const form = useMolecule(formMolecule)
	const primaryAccount = useAtomValue(form.primaryAccountAtom)

	if (primaryAccount == null) return null

	return <CustomerInfo customer={primaryAccount} />
}

function SecondaryAccountInfo() {
	const form = useMolecule(formMolecule)
	const secondaryAccount = useAtomValue(form.secondaryAccountAtom)

	if (secondaryAccount == null) return null

	return <CustomerInfo customer={secondaryAccount} />
}

function PrimaryAccountField() {
	const form = useMolecule(formMolecule)
	const [primaryAccount, setPrimaryAccount] = useAtom(form.primaryAccountAtom)

	return (
		<AccountSelect
			label="Primary account"
			value={primaryAccount}
			onChange={setPrimaryAccount}
		/>
	)
}

function SecondaryAccountField() {
	const form = useMolecule(formMolecule)
	const [secondaryAccount, setSecondaryAccount] = useAtom(
		form.secondaryAccountAtom,
	)

	return (
		<AccountSelect
			label="Secondary account"
			value={secondaryAccount}
			onChange={setSecondaryAccount}
		/>
	)
}

interface AccountSelectProps {
	label: string
	value: User | null
	onChange: (user: User) => void
}

function AccountSelect({ label, value, onChange }: AccountSelectProps) {
	const [query, setQuery] = useState("")
	const { data } = trpc.v2_5.user.searchUserToMerge.useQuery({
		query,
	})

	return (
		<Select<User>
			data={data ?? []}
			label={label}
			searchable
      clearable
			entryId={(u) => u.id.toString()}
			entryLabel={(u) => `${u.email} | ${u.firstName} ${u.lastName}`}
			onSearchChange={setQuery}
			value={value ?? undefined}
			onChange={onChange}
		/>
	)
}
