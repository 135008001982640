import { BarChart } from "#/components-ng";
import GTLoader from "#/components-ng/loader";
import { trpc } from "#/trpc";
import {
  C,
  FormValues,
  generateProductsWithMostGPReportSchema,
  productsRange,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { FormProvider } from "react-hook-form";

export const ProductsWithMostGPReport = () => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().startOf("month").toDate(),
    dayjs().endOf("month").toDate(),
  ]);

  const { data: entities } = trpc.entity.getAll.useQuery();
  const { data: filials } = trpc.filial.getAll.useQuery();

  const form = C.useForm({
    resolver: zodResolver(generateProductsWithMostGPReportSchema),
    shouldUnregister: false,
    defaultValues: {
      dateRange: [dateRange[0]!, dateRange[1]!],
      productsRange: "10",
      departmentId: null,
      categoryId: null,
      seasonId: null,
      filter: {
        by: "entity",
        id: "1",
      },
    },
  });

  const {
    data: report,
    mutate: generateReport,
    isLoading,
  } = trpc.v2_5.report.getProductsWithMostGrossProfit.useMutation();

  useEffect(() => {
    generateReport({
      dateRange: {
        from: dateRange[0]!,
        to: dateRange[1]!,
      },
      productsRange: 10,
      categoryId: null,
      departmentId: null,
      seasonId: null,
      filter: {
        by: "entity",
        id: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entityOptions = useMemo(
    () =>
      entities?.map((entity) => ({
        label: entity.name,
        value: entity.id.toString(),
      })) ?? [],
    [entities]
  );

  const filialOptions = useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [filials]
  );

  const data = useMemo(() => {
    if (report) {
      return report;
    }
    return null;
  }, [report]);

  const handleSubmit = (values: FormValues) => {
    generateReport({
      dateRange: {
        from: values.dateRange[0],
        to: values.dateRange[1],
      },
      productsRange: Number(values.productsRange),
      categoryId: values.categoryId ? Number(values.categoryId) : null,
      departmentId: values.departmentId ? Number(values.departmentId) : null,
      seasonId: values.seasonId ? Number(values.seasonId) : null,
      filter: {
        by: values.filter.by,
        id: Number(values.filter.id),
      },
    });
  };

  const filterBy = form.watch("filter.by");

  return (
    <M.Container size="xl">
      <M.Stack spacing="md">
        <M.Title order={2}>Products with most gross profit</M.Title>
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              form.handleSubmit(handleSubmit)(e);
            }}
          >
            <M.Group>
              <C.M
                as={M.Select}
                label="Report type"
                name="filter.by"
                data={[
                  { label: "Entity", value: "entity" },
                  { label: "Filial", value: "filial" },
                ]}
                required
              />
              <C.M
                as={M.Select}
                label={
                  filterBy === "entity" ? "Select entity" : "Select filial"
                }
                name="filter.id"
                data={filterBy === "entity" ? entityOptions : filialOptions}
                required
              />
              <C.M
                as={DatePickerInput}
                label="Date range"
                name="dateRange"
                type="range"
                onChange={(v) => {
                  if(!v) return;
                  if (v[0] == null || v[1] == null) return;
                  setDateRange([v[0], v[1]]);
                }}
                numberOfColumns={2}
                popoverProps={{
                  zIndex: 9000,
                }}
                w={300}
                required
              />
              <C.M
                as={M.Select}
                label="Products range"
                name="productsRange"
                data={productsRange.map((r) => ({
                  label: r,
                  value: r,
                }))}
                className="w-[150px]"
              />
              <DepartmentField />
              <CategoryField />
              <SeasonField />
              <M.Button
                type="submit"
                loading={isLoading}
                className="mt-6 px-12 "
              >
                Generate
              </M.Button>
            </M.Group>
          </form>
        </FormProvider>
        {isLoading ? (
          <M.LoadingOverlay
            visible={isLoading}
            loader={<GTLoader width={100} height={100} />}
          />
        ) : (
          <BarChart
            pathname="/inventory/item-list/preview/"
            enableRedirect
            textLabel="Gross Profit $"
            data={
              data?.map((p) => ({
                id: p.itemSkuId,
                label: `${p.sku}`,
                total: p.grossProfit,
              })) ?? []
            }
          />
        )}
      </M.Stack>
    </M.Container>
  );
};

const DepartmentField = () => {
  const form = C.useFormContext();
  const controller = C.useController({
    name: "departmentId",
    control: form.control,
  });

  const { data } = trpc.department.getAll.useQuery();

  return (
    <C.M
      as={M.Select}
      label="Department"
      name={controller.field.name}
      data={data?.map((d) => ({ label: d.name, value: d.id.toString() })) ?? []}
      clearable
      searchable
      onChange={(e) => {
        controller.field.onChange(e);
      }}
    />
  );
};

const CategoryField = () => {
  const form = C.useFormContext();
  const controller = C.useController({
    name: "categoryId",
    control: form.control,
  });

  const { data } = trpc.category.getAll.useQuery();

  return (
    <C.M
      as={M.Select}
      label="Category"
      name={controller.field.name}
      data={data?.map((c) => ({ label: c.name, value: c.id.toString() })) ?? []}
      clearable
      searchable
      onChange={(e) => {
        controller.field.onChange(e);
      }}
    />
  );
};

const SeasonField = () => {
  const form = C.useFormContext();
  const controller = C.useController({
    name: "seasonId",
    control: form.control,
  });

  const { data } = trpc.season.getAll.useQuery();

  return (
    <C.M
      as={M.Select}
      label="Season"
      name={controller.field.name}
      data={data?.map((s) => ({ label: s.name, value: s.id.toString() })) ?? []}
      clearable
      searchable
      onChange={(e) => {
        controller.field.onChange(e);
      }}
    />
  );
};
