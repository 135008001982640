import { css } from "#/s"
import * as M from "@mantine/core"
import { useState } from "react"
import { SurveyListModal } from "./survey-list-modal"
import { useAtomValue } from "jotai/react"
import { customerAtom } from "../state/customer"

export function AnswerSurveyButton() {
	const [opened, setOpened] = useState(false)
	const customer = useAtomValue(customerAtom)

	return (
		<>
			<SurveyListModal
				opened={opened}
				onClose={() => setOpened(false)}
				setOpened={setOpened}
			/>
			<M.Button
				disabled={customer == null}
				onClick={() => setOpened(true)}
				className={css({
					gridColumn: "span 3",
				})}
			>
				Answer Survey
			</M.Button>
		</>
	)
}
