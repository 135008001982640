import { RouterInputs } from "#/trpc";

export type PurchaseOrderStatus = NonNullable<
  RouterInputs["v2_5"]["purchaseOrder"]["getByPage"]["filters"]
>["status"][number];

export const Filter: Record<PurchaseOrderStatus, string> = {
  UPCOMING: "Upcoming",
  PARTIALLY_RECEIVED: "HOLD",
  RECEIVED: "Received",
  DRAFT: "Draft",
  PROCESSING: "Processing",
  CANCELLED: "Cancelled",
} as const;

export type FilterKey = keyof typeof Filter;
export type Filter = (typeof Filter)[FilterKey];
