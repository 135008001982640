import { CustomersReport } from "./CustomersReport/index.js"
import { CustomersMostGPReport } from "./CustomerWithMostGP/index.js"
import { CustomersWithMostLVReport } from "./CustomerWithMostLV/index.js"
import { CustomersMostOrdersReport } from "./CustomerWithMostOrders/index.js"
import { EcommerceSalesReport } from "./EcommerceSales/index.js"
import { GeneralSalesReport } from "./GeneralSalesReport/index.js"
import { NewCustomersReport } from "./NewCustomers/index.js"
import { NotificationUpsellReport } from "./NotificationUpsell/index.js"
import { PerformanceReport } from "./PerformanceReport/index.js"
import { ProductsMostSoldReport } from "./ProductsMostSold/index.js"
import { ProductsMostSoldEcommerceReport } from "./ProductsMostSoldEcommerce/index.js"
import { ProductSegmentationReport } from "./ProductsSegmentation/index.js"
import { ProductsWithMostGPReport } from "./ProductsWithMostGP/index.js"
import { ProductsWithMostNPReport } from "./ProductsWithMostNP/index.js"
import { PromotionReport } from "./PromotionReport/index.js"
import { Reports } from "./Reports.js"
import { SalesFilialReport } from "./Sales/Filial/index.js"
import { SalesByCountriesReport } from "./SalesByCountriesReport/index.js"
import { SalesReportByCustomer } from "./SalesReportByCustomer/index.js"

export const reportsRoutes = [
	{
		index: true,
		element: <Reports />,
		breadcrumb: "Reports",
	},
	{
		path: "sales",
		element: <SalesFilialReport />,
		breadcrumb: "Sales",
	},
	{
		path: "ecommerce-sales",
		element: <EcommerceSalesReport />,
		breadcrumb: "E-commerce Sales",
	},
	{
		path: "new-customers",
		element: <NewCustomersReport />,
		breadcrumb: "New Customers",
	},
	{
		path: "products-most-sold",
		element: <ProductsMostSoldReport />,
		breadcrumb: "Products Most Sold",
	},
	{
		path: "products-most-sold-ecommerce",
		element: <ProductsMostSoldEcommerceReport />,
		breadcrumb: "Products Most Sold E-commerce",
	},
	{
		path: "products-with-most-gp",
		element: <ProductsWithMostGPReport />,
		breadcrumb: "Products With Most Gross Profit",
	},
	{
		path: "products-with-most-np",
		element: <ProductsWithMostNPReport />,
		breadcrumb: "Products With Most Net Profit",
	},
	{
		path: "products-segmentation",
		element: <ProductSegmentationReport />,
		breadcrumb: "Products Segmentation",
	},
	{
		path: "customers-with-most-orders",
		element: <CustomersMostOrdersReport />,
		breadcrumb: "Customers With Most Orders",
	},
	{
		path: "customers-with-most-gp",
		element: <CustomersMostGPReport />,
		breadcrumb: "Customers With Most Gross Profit",
	},
	{
		path: "customers-with-most-lv",
		element: <CustomersWithMostLVReport />,
		breadcrumb: "Customers With Most Lifetime Value",
	},
	{
		path: "notification-upsell",
		element: <NotificationUpsellReport />,
		breadcrumb: "Notification Upsell Report",
	},
	{
		path: "promotion-report",
		element: <PromotionReport />,
		breadcrumb: "Promotion Report",
	},
	{
		path: "general-sales-report",
		element: <GeneralSalesReport />,
		breadcrumb: "General Sales Report",
	},
	{
		path: "customers-report",
		element: <CustomersReport />,
		breadcrumb: "Customers Report",
	},
	{
		path: "sales-report-by-customer",
		element: <SalesReportByCustomer />,
		breadcrumb: "Sales Report By Customer",
	},
	{
		path: "sales-by-countries-report",
		element: <SalesByCountriesReport />,
		breadcrumb: "Sales By Countries Report",
	},
	{
		path: "performance-report",
		element: <PerformanceReport />,
		breadcrumb: "Performance Report",
	},
]
