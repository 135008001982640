import { makeController, PencilCustomIcon } from "#/components-ng"
import { S } from "#/s"
import { trpc, type RouterOutputs } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { zodResolver } from "@hookform/resolvers/zod"
import { ActionIcon, Select } from "@mantine/core"
import { modals } from "@mantine/modals"
import dayjs from "dayjs"
import { DefaultValues, FormProvider } from "react-hook-form"
import { z } from "zod"
import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"
import { Button } from "@gt/ui"
countries.registerLocale(enLocale)

type OrderShipping =
	RouterOutputs["v2_5"]["order"]["getByPage"]["entries"][number]["orderShipping"]

type OrderType =
	RouterOutputs["v2_5"]["order"]["getOrderForTracking"]["orderType"]

interface ShippingInformationProps {
	customer: string
	createdAt: Date
	orderId: number
	orderShipping?: OrderShipping
	orderType: OrderType
}

export const openShippingInformationModal = (
	props: ShippingInformationProps,
) => {
	modals.open({
		children: <ShippingInformationModal {...props} />,
		size: "lg",
		centered: true,
		modalId: "modal-shipping-information",
	})
}

const shippingAddressFormSchema = z.object({
	deliveryMode: z.enum(["SHIPPING", "PICKUP FROM STORE"]),
	shippingCompany: z.string().nullish(),
	shippingNumber: z.string().nullish(),
	includeAddress: z.boolean().default(false),
	address: z.string().nullish(),
	city: z.string().nullish(),
	state: z.string().nullish(),
	country: z.string().nullish(),
	zipCode: z.string().nullish(),
	apt: z.string().nullish(),
	poBox: z.string().nullish(),
})

type ShippingAddressFormSchema = z.infer<typeof shippingAddressFormSchema>

const C = makeController<ShippingAddressFormSchema>()

const ShippingInformationModal = (props: ShippingInformationProps) => {
	const ctx = trpc.useContext()
	const [edit, setEdit] = useState<boolean>(false)

	const { mutate, isLoading } =
		trpc.v2_5.order.updateShippingInformation.useMutation({
			onError: (error) => {
				reportUserError({
					title: "Failed to update shipping information",
					message: error.message,
				})
			},
			onSuccess: () => {
				reportUserSuccess({
					title: "Shipping information updated",
				})
				ctx.order.invalidate()
				ctx.v2_5.order.invalidate()
				modals.close("modal-shipping-information")
			},
		})

	const handleSubmit = (values: ShippingAddressFormSchema) => {
		if (values.deliveryMode === "PICKUP FROM STORE") {
			mutate({
				orderId: props.orderId,
				deliveryMode: "PICKUP_FROM_STORE",
			})
			return
		}

		if (values.deliveryMode === "SHIPPING") {
			if (!values.shippingCompany || !values.shippingNumber) {
				return reportUserError({
					title: "Failed to update shipping information",
					message: "Please fill required shipping information fields",
				})
			}

			mutate({
				orderId: props.orderId,
				deliveryMode: "SHIPPING",
				address: {
					address: values.address,
					city: values.city,
					state: values.state,
					country: values?.country ? values?.country : null,
					zipCode: values.zipCode,
					apt: values?.apt ?? null,
					poBox: values.poBox ?? null,
					shippingCompany: values.shippingCompany,
					shippingNumber: values.shippingNumber,
				},
			})
		}
	}

	return (
		<S.div d="flex" flexDir="column" gap="2">
			<S.div
				d="flex"
				gap="2"
				justifyContent="space-between"
				alignItems="center"
			>
				<S.div d="flex" flexDir="column">
					<S.p fw="500" fs="14px" c="neutral.400">
						Customer:
					</S.p>
					<S.p fw="500">{props.customer}</S.p>
				</S.div>
				<S.div d="flex" flexDir="column">
					<S.p fw="500" fs="14px" c="neutral.400">
						CreatedAt:
					</S.p>
					<S.p fw="500">
						{dayjs(props.createdAt).format("MM/DD/YYYY hh:mm A")}
					</S.p>
				</S.div>
			</S.div>
			<S.hr />
			{edit ? (
				<ShippingInformationForm
					isLoading={isLoading}
					onSubmit={handleSubmit}
					defaultValues={{
						address: props.orderShipping?.addressLine1 ?? "",
						city: props.orderShipping?.city ?? "",
						state: props.orderShipping?.state ?? "",
						country: props.orderShipping?.country ?? "",
						zipCode: props.orderShipping?.zipCode ?? "",
						apt: props.orderShipping?.apt ?? "",
						poBox: props.orderShipping?.poBox ?? "",
						shippingCompany: props.orderShipping?.shippingCompany ?? "",
						shippingNumber: props.orderShipping?.shippingNumber ?? "",
						deliveryMode:
							props?.orderType === "PICKUP_FROM_STORE"
								? "PICKUP FROM STORE"
								: "SHIPPING",
						includeAddress: props.orderShipping?.addressLine1 ? true : false,
					}}
				/>
			) : (
				<S.div
					w="100%"
					border="gray.200"
					p="4"
					bg="gray.100"
					borderWidth="1px"
					borderRadius="md"
					mt="1rem"
					position="relative"
				>
					{props.orderType === "IN_STORE" &&
						(props?.orderShipping?.addressLine1 ||
							props?.orderShipping?.shippingCompany) && (
							<S.div display="flex" gap="1" flexDir="column">
								<S.p fs="15px" c="neutral.700" fw="500">
									Shipping company:{" "}
									<S.span fw="400">
										{props?.orderShipping?.shippingCompany ?? ""}
									</S.span>
								</S.p>
								<S.p fs="15px" c="neutral.700" fw="500">
									Shipping number:{" "}
									<S.span fw="400">
										{props?.orderShipping?.shippingNumber ?? ""}
									</S.span>
								</S.p>
								<S.p fs="15px" c="neutral.700">
									{props.orderShipping?.addressLine1 ?? ""}
								</S.p>
								{props.orderShipping?.apt && (
									<S.p fs="15px" c="neutral.700">
										Apt {props.orderShipping?.apt ?? ""}
									</S.p>
								)}
								<S.p fs="15px" c="neutral.700">
									{props.orderShipping?.city ?? ""}{" "}
									{props.orderShipping?.state ?? ""}{" "}
									{props.orderShipping?.zipCode ?? ""}
								</S.p>
								{props.orderShipping?.country && (
									<S.p fs="15px" c="neutral.700">
										{countries.getName(props.orderShipping.country, "en")}
									</S.p>
								)}
							</S.div>
						)}
					{props.orderType === "PICKUP_FROM_STORE" && (
						<S.p fs="15px" c="neutral.700">
							Pickup from store
						</S.p>
					)}
					{!props.orderShipping?.addressLine1 &&
						!props.orderShipping?.shippingCompany &&
						props.orderType === "IN_STORE" && (
							<S.p fs="15px" c="neutral.700">
								No shipping information available
							</S.p>
						)}

					<S.div pos="absolute" top="3" right="2">
						<ActionIcon
							size="sm"
							variant="outline"
							onClick={() => setEdit(true)}
						>
							<PencilCustomIcon />
						</ActionIcon>
					</S.div>
				</S.div>
			)}
		</S.div>
	)
}

interface ShippingInformationFormProps {
	onSubmit: (values: ShippingAddressFormSchema) => void
	isLoading: boolean
	defaultValues?: DefaultValues<ShippingAddressFormSchema>
}

const ShippingInformationForm = (props: ShippingInformationFormProps) => {
	const form = C.useForm({
		resolver: zodResolver(shippingAddressFormSchema),
		shouldUnregister: false,
		defaultValues: {
			deliveryMode: "SHIPPING",
			address: "",
			city: "",
			state: "",
			country: "",
			zipCode: "",
			apt: "",
			poBox: "",
			shippingCompany: "",
			shippingNumber: "",
			includeAddress: false,
			...props.defaultValues,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const countriesSelectData = useMemo(
		() =>
			Object.entries(countries.getNames("en"))
				.map(([countryCode, countryName]) => ({
					label: countryName,
					value: countryCode,
				}))
				.sort((a, b) => {
					if (a.value === "US") return -1
					if (b.value === "US") return 1
					if (a.value === "CA") return -1
					if (b.value === "CA") return 1
					if (a.value === "MX") return -1
					if (b.value === "MX") return 1
					return a.value.localeCompare(b.value)
				}),
		[],
	)

	const deliveryMode = form.watch("deliveryMode")
	const includeAddress = form.watch("includeAddress")

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(props.onSubmit)}>
				<S.div gap="1rem" d="flex" flexDir="column" w="full" mt="2">
					<C.M
						as={Select}
						name="deliveryMode"
						label="Delivery mode"
						placeholder="Delivery mode"
						required
						data={[
							{ label: "Shipping", value: "SHIPPING" },
							{ label: "Pickup from store", value: "PICKUP FROM STORE" },
						]}
					/>
					{deliveryMode === "SHIPPING" && (
						<>
							<S.div
								gap="1rem"
								d="grid"
								gridTemplateColumns={{ base: 1, md: 2 }}
							>
								<C.InputField
									name="shippingCompany"
									label="Shipping company"
									placeholder="Shipping company"
									required={deliveryMode === "SHIPPING" && includeAddress}
								/>
								<C.InputField
									name="shippingNumber"
									label="Shipping number"
									placeholder="Shipping number"
									required={deliveryMode === "SHIPPING" && includeAddress}
								/>
							</S.div>
							<S.hr />
							<C.SwitchField name="includeAddress" label="Include Address?" />
							<C.InputField
								name="address"
								label="Address"
								placeholder="Address"
								required={deliveryMode === "SHIPPING" && includeAddress}
								disabled={!includeAddress}
							/>
							<S.div
								gap="1rem"
								d="grid"
								gridTemplateColumns={{ base: 1, md: 2 }}
							>
								<C.InputField
									name="city"
									label="City"
									placeholder="City"
									required={deliveryMode === "SHIPPING" && includeAddress}
									disabled={!includeAddress}
								/>
								<C.InputField
									name="state"
									label="State"
									placeholder="State"
									required={deliveryMode === "SHIPPING" && includeAddress}
									disabled={!includeAddress}
								/>
							</S.div>
							<C.M
								as={Select}
								name="country"
								label="Country"
								placeholder="Country"
								required={deliveryMode === "SHIPPING" && includeAddress}
								searchable
								withinPortal
								data={countriesSelectData}
								disabled={!includeAddress}
							/>
							<S.div
								gap="1rem"
								d="grid"
								gridTemplateColumns={{ base: 1, md: 3 }}
							>
								<C.InputField
									name="zipCode"
									label="Zip code"
									placeholder="Zip code"
									required={deliveryMode === "SHIPPING" && includeAddress}
									disabled={!includeAddress}
								/>
								<C.InputField
									name="apt"
									label="Apt"
									placeholder="Apt"
									disabled={!includeAddress}
								/>
								<C.InputField
									name="poBox"
									label="PO Box"
									placeholder="PO Box"
									disabled={!includeAddress}
								/>
							</S.div>
						</>
					)}

					<S.div d="flex" justifyContent="flex-end">
						<Button type="submit" variant="default" isLoading={props.isLoading}>
							Save
						</Button>
					</S.div>
				</S.div>
			</form>
		</FormProvider>
	)
}
