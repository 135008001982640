import { PencilCustomIcon } from "#/components-ng"
import { S } from "#/s"
import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { Button } from "@gt/ui"
import { ActionIcon, Textarea } from "@mantine/core"
import { modals } from "@mantine/modals"
import dayjs from "dayjs"
import { useState } from "react"

interface OpenNotePreviewModalProps {
	cartId?: number | null
	customerName: string
	updatedAt: Date
	note?: string | null
	onSubmit?: (note: string) => void
}

export const openNotePreviewModal = (props: OpenNotePreviewModalProps) => {
	modals.open({
		children: <NotePreviewModal {...props} />,
		size: "lg",
		centered: true,
		modalId: "modal-note-preview",
    zIndex: 1300,
	})
}

const NotePreviewModal = (props: OpenNotePreviewModalProps) => {
	const [edit, setEdit] = useState<boolean>(false)
	const [note, setNote] = useState<string>(() => props?.note ?? "")

	const ctx = trpc.useContext()

	const { mutate } = trpc.cart.updateNote.useMutation({
		onError: (error) => {
			reportUserError({
				title: "Failed to update note",
				message: error.message,
			})
		},
		onSuccess: () => {
			ctx.cart.invalidate()
			ctx.v2_5.cart.invalidate()
			reportUserSuccess({
				title: "Note updated",
			})
			setEdit(false)
			modals.close("modal-note-preview")
		},
	})

	const handleSave = () => {
		if (props.cartId) {
			mutate({
				id: props.cartId,
				note: note,
			})
		} else {
			props?.onSubmit?.(note)
      modals.close("modal-note-preview")
		}
	}

	return (
		<S.div d="flex" flexDir="column" gap="2">
			<S.div
				d="flex"
				gap="2"
				justifyContent="space-between"
				alignItems="center"
			>
				<S.div d="flex" flexDir="column">
					<S.p fw="500" fs="14px" c="neutral.400">
						Cart note:
					</S.p>
					<S.p fw="500">{props.customerName}</S.p>
				</S.div>
				<S.div d="flex" flexDir="column">
					<S.p fw="500" fs="14px" c="neutral.400">
						Cart last updated:
					</S.p>
					<S.p fw="500">
						{dayjs(props.updatedAt).format("MM/DD/YYYY hh:mm A")}
					</S.p>
				</S.div>
			</S.div>
			<S.hr />

			{edit ? (
				<S.div d="flex" flexDir="column" mt="1rem" gap="2">
					<Textarea
						value={note ?? ""}
						onChange={(e) => setNote(e.target.value)}
						rows={5}
					/>
					<S.div d="flex" alignItems="center" justifyContent="space-between">
						<Button variant="destructive" onClick={() => setEdit(false)}>
							Cancel
						</Button>
						<Button onClick={handleSave}>Save</Button>
					</S.div>
				</S.div>
			) : (
				<S.div
					w="100%"
					border="gray.200"
					p="4"
					bg="gray.100"
					borderWidth="1px"
					borderRadius="md"
					mt="1rem"
					position="relative"
          minH="50px"
				>
					<S.p fs="15px" c="neutral.700">
						{note}
					</S.p>
					<S.div pos="absolute" top="3" right="2">
						<ActionIcon
							size="sm"
							variant="outline"
							onClick={() => setEdit(true)}
						>
							<PencilCustomIcon />
						</ActionIcon>
					</S.div>
				</S.div>
			)}
		</S.div>
	)
}
