import {
	Avatar,
	AvatarFallback,
	AvatarImage,
	Button,
	ChevronDownOutline,
	makeController,
} from "#/components-ng/index.js"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "#/components-ng/ui/index.js"
import { useAuth } from "#/context/AuthContext.js"
import Logo from "#/images/colored-logo-invoice.png"
import { trpc } from "#/trpc.js"
import { reportUserError } from "#/util/index.js"
import * as M from "@mantine/core"
import { useMemo, useState } from "react"
import { FormProvider } from "react-hook-form"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { styled } from "#/css/jsx"
import { cn } from "#/lib/utils"
import _ from "lodash"

type FormValues = {
	email: string
}
const C = makeController<FormValues>()

export function HeaderAuth() {
	const navigate = useNavigate()
	const location = useLocation()
	const [showModal, setShowModal] = useState(false)
	const [defaultValue, setDefaultValue] = useState<string>("NOOP")

	const form = C.useForm()
	const [{ auth }, { logout, login }] = useAuth()
	const ctx = trpc.useContext()

	const loginWithPinMut = trpc.user.private.switchUser.useMutation({
		onError(err) {
			reportUserError({
				title: "Login failed",
				message: err.message,
			})
		},
		onSuccess(data) {
			logout()
			ctx.invalidate()
			login(data.session)
			const locationState = location.state as unknown
			setShowModal(false)
			setDefaultValue("NOOP")
			if (
				locationState &&
				typeof locationState === "object" &&
				"from" in locationState &&
				typeof locationState.from === "string" &&
				locationState.from !== location.pathname
			) {
				navigate(locationState.from)
			} else {
				navigate("/")
			}
		},
	})

	const { data } = trpc.user.getManyByRoleFilial.useQuery(
		{
			roleId: auth?.role.id ?? 0,
			filialId: auth?.user.filialId ?? 0,
		},
		{
			cacheTime: 0,
			enabled: !!auth?.role.id && !!auth?.user.filialId,
			refetchOnWindowFocus: false,
		},
	)

	const roleInLocalStorage = localStorage.getItem("role")

	const { data: usersEmployeesToFilialManager } =
		trpc.user.getManyByRoleFilial.useQuery(
			{
				roleId:
					auth?.role?.name !== "FILIAL MANAGER" && roleInLocalStorage != null
						? 3
						: 4,
				filialId: auth?.user.filialId ?? 0,
			},
			{
				enabled:
					auth?.role?.name === "FILIAL MANAGER" || roleInLocalStorage != null,
        refetchOnWindowFocus: false,
			},
		)

	const userOptions = useMemo(() => {
		if (!data) return []
		return _.uniqBy(
			data
				.filter((user) => user.id !== auth?.user.id)
				.map((user) => ({
					label: `${user.firstName} ${user.lastName ?? ""}`,
					value: user.email,
				}))
				.concat(
					usersEmployeesToFilialManager?.map((user) => ({
						label: `${user.firstName} ${user.lastName ?? ""}`,
						value: user.email,
					})) ?? [],
				),
			"value",
		)
	}, [auth?.user.id, data, usersEmployeesToFilialManager])

	function handleUserChange(e: string) {
		setDefaultValue(e)
		if (e === "LOGOUT") {
			logout()
			navigate("/login")
      localStorage.removeItem("role")
		} else if (e !== "NOOP") {
			setShowModal(true)
			form.setValue("email", e)
		}
	}

	async function handleSubmit(values: FormValues) {
		await loginWithPinMut.mutateAsync({
			email: values.email,
		})
	}

	return (
		<>
			<styled.div
				display={{
					base: "none",
					lg: "block",
				}}
			>
				<div className="flex gap-x-4">
					<Link to="/dashboard">
						<Avatar>
							<AvatarFallback>
								{auth?.user.firstName[0]}
								{auth?.user.lastName?.[0] ?? auth?.user.firstName?.[1] ?? ""}
							</AvatarFallback>
							<AvatarImage src={auth?.user.avatar ?? undefined} />
						</Avatar>
					</Link>
					<Select
						defaultValue="NOOP"
						value={defaultValue}
						onValueChange={(e) => handleUserChange(e)}
					>
						<SelectTrigger>
							<SelectValue />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="NOOP">
								{auth?.user.firstName} {auth?.user.lastName ?? ""}
							</SelectItem>
							{userOptions.map((user) => (
								<SelectItem key={user.value} value={user.value}>
									{user.label}
								</SelectItem>
							))}
							<SelectItem value="LOGOUT">Log out</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</styled.div>
			<styled.div
				display={{
					base: "block",
					lg: "none",
				}}
			>
				<M.Menu withinPortal>
					<M.Menu.Target>
						<styled.button
							type="button"
							aria-label="Open more menu"
							aria-labelledby="Open more menu"
							position="relative"
						>
							<Avatar className="relative">
								<AvatarFallback>
									{auth?.user.firstName[0]}
									{auth?.user.lastName?.[0] ?? auth?.user.firstName?.[1] ?? ""}
								</AvatarFallback>
								<AvatarImage src={auth?.user.avatar ?? undefined} />
							</Avatar>
							<ChevronDownOutline
								className={cn(
									"absolute -right-1 bottom-0 grid place-items-center rounded-full border",
									"border-white bg-slate-100 text-slate-800",
								)}
							/>
						</styled.button>
					</M.Menu.Target>
					<M.Menu.Dropdown>
						<M.Menu.Item value="NOOP" onClick={() => handleUserChange("NOOP")}>
							{auth?.user.firstName} {auth?.user.lastName ?? ""}
						</M.Menu.Item>
						{userOptions.map((user) => (
							<M.Menu.Item
								key={user.value}
								value={user.value}
								onClick={() => handleUserChange(user.value)}
							>
								{user.label}
							</M.Menu.Item>
						))}
						<M.Menu.Item
							value="LOGOUT"
							onClick={() => handleUserChange("LOGOUT")}
						>
							Log out
						</M.Menu.Item>
					</M.Menu.Dropdown>
				</M.Menu>
			</styled.div>
			<M.Modal
				opened={showModal}
				onClose={() => {
					setShowModal(false)
					setDefaultValue("NOOP")
					form.reset()
				}}
			>
				<div className="grid max-h-screen place-items-center">
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<FormProvider {...form}>
							<div className="grid min-w-[32ch] justify-items-center gap-y-2 rounded-md bg-white p-4">
								<img src={Logo} className="w-52" />
								<M.Title order={4}>
									Are you sure you want to switch user?
								</M.Title>
								<C.InputField
									name="email"
									placeholder="Email"
									required
									readOnly
									classNames={{ field: "w-full" }}
								/>
								<Button variant="primary" className="w-full" type="submit">
									Switch user
								</Button>
							</div>
						</FormProvider>
					</form>
				</div>
			</M.Modal>
		</>
	)
}
