import GTLoader from "#/components-ng/loader.js";
import { makeMantineController } from "#/components/Form/v3/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import {
  useS3PresignedUrl,
  reportUserError,
  reportUserSuccess,
} from "#/util/index.js";
import * as M from "@mantine/core";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import countryTelData from "country-telephone-data";
import { uniqBy } from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import CameraIcon from "~icons/ion/camera-outline";
import EditIcon from "~icons/ion/create-outline";

const createInputStyles = ({ labelSize }: { labelSize: number | string }) => {
  return {
    root: { display: "flex", alignItems: "center" },
    label: { width: labelSize },
    disabled: {
      background: "transparent !important",
      color: "#222 !important",
      border: "none",
      cursor: "default !important",
    },
  };
};

const basicInfoInputStyles = createInputStyles({
  labelSize: "12ch",
});

const settingsInputStyles = createInputStyles({
  labelSize: "20ch",
});

const shopSettingsInputStyles = createInputStyles({
  labelSize: "20ch",
});

interface FormValues {
  name: string;
  phoneNumber: string;
  email: string;
  disclosure: string;
  termsAndConditions: string;
  verified: boolean;
  avatar: string;
  areaCode: string;
  countryCode: string;
  shopSettings: string[];
}

const Mc = makeMantineController({ ctx: {} as FormValues });

const SectionTitle: React.FC<{
  editable?: boolean;
  onEditClick?: () => void;
  children?: any;
}> = ({ children, editable = true, onEditClick }) => {
  return (
    <M.Group noWrap w={300}>
      <M.Title order={2} size="md">
        {/* @ts-ignore */}
        {children}
      </M.Title>
      {editable && (
        <M.ActionIcon onClick={onEditClick}>
          <EditIcon />
        </M.ActionIcon>
      )}
    </M.Group>
  );
};

export const Entity = () => {
  const form = useForm<FormValues>();
  const [uploadPhoto, { isLoading: isPhotoLoading, fileUrl: avatar }] =
    useS3PresignedUrl({
      onSuccess: ({ fileUrl }) => {
        form.setValue("avatar", fileUrl);
      },
    });

  const [{ auth }] = useAuth();

  const { data, isLoading } = trpc.entity.getById.useQuery(
    {
      id: 1,
    },
    {
      onSuccess: (data) => {
        form.reset({
          avatar: data?.avatar ?? "",
          name: data?.name ?? "",
          phoneNumber: data?.phoneNumber ?? "",
          email: data?.email ?? "",
          verified: data?.verified ?? false,
          disclosure: data?.disclosure ?? "",
          termsAndConditions: data?.termsAndConditions ?? "",
          areaCode: data?.areaCode ?? "",
          countryCode: data?.countryCode ?? "",
          shopSettings:
            data?.ShopSettings.map((s) => s.filialId.toString()) ?? [],
        });
      },
      onError: (error) => {
        reportUserError({
          title: "Failed to load entity",
          message: error.message,
        });
      },
    }
  );

  const { data: filialsData } = trpc.filial.getAll.useQuery(undefined, {
    cacheTime: 0,
  });

  const filials = React.useMemo(
    () =>
      filialsData?.map((f) => ({
        label: f.name,
        value: f.id.toString(),
      })) ?? [],
    [filialsData]
  );

  const joinedAreaCode = `${form.watch("countryCode")}/${form.watch(
    "areaCode"
  )}`;
  const areaCodes = uniqBy(
    countryTelData.allCountries.map((c) => ({
      label: `${getUnicodeFlagIcon(c.iso2)} +${
        c.dialCode
      } (${c.iso2.toUpperCase()})`,
      value: `${c.iso2.toUpperCase()}/+${c.dialCode}`,
    })),
    (c) => c.value
  );

  const navigate = useNavigate();

  const { mutate, isLoading: isLoadingUpdate } = trpc.entity.update.useMutation(
    {
      onSuccess: (data) => {
        reportUserSuccess({
          title: "Entity updated successfully",
          message: "",
        });
        navigate("/settings");
      },
      onError: (error) => {
        reportUserError({
          title: "Failed to update entity",
          message: error.message,
        });
      },
    }
  );

  const [basicInfoEditable, setBasicInfoEditable] = React.useState(false);
  const [settingsEditable, setSettingsEditable] = React.useState(false);
  const [shopSettingsEditable, setShopSettingsEditable] = React.useState(false);

  const handleSubmit = (values: FormValues) => {
    if (auth?.role?.name !== "ENTITY MANAGER") {
      reportUserError({
        title: "Failed to update entity",
        message: "You don't have permission to update entity",
      });
      return;
    }

    mutate({
      id: 1,
      input: {
        avatar: values.avatar,
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        verified: values.verified,
        disclosure: values.disclosure ?? null,
        termsAndConditions: values.termsAndConditions ?? null,
        areaCode: values.areaCode,
        countryCode: values.countryCode,
        shopSettings:
          values.shopSettings.length > 0
            ? values.shopSettings.map((s) => ({
                entity: 1,
                filial: Number(s),
              }))
            : [],
      },
    });
  };

  return isLoading ? (
    <M.LoadingOverlay
      visible={isLoading}
      loader={<GTLoader width={100} height={100} />}
    />
  ) : (
    <M.Box bg="white" py="lg" px={38}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <M.Title order={1}>Entity</M.Title>
          <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
          <M.Stack>
            <M.Group noWrap align="start">
              <SectionTitle
                editable={auth?.role?.name === "ENTITY MANAGER" ? true : false}
                onEditClick={() => setBasicInfoEditable((prev) => !prev)}
              >
                Basic information
              </SectionTitle>
              <M.Stack>
                <M.Box>
                  <M.Box w={84} pos="relative">
                    <M.Avatar radius={48} size="xl" src={form.watch("avatar")}>
                      GT
                    </M.Avatar>
                    <M.FileButton
                      accept={IMAGE_MIME_TYPE.join(",")}
                      onChange={(file) => {
                        if (file) {
                          uploadPhoto(file, "entities");
                        }
                      }}
                    >
                      {(props) => {
                        return (
                          <M.ActionIcon
                            color="brand"
                            variant="filled"
                            radius="xl"
                            size="sm"
                            pos="absolute"
                            bottom={0}
                            right={0}
                            {...props}
                          >
                            <CameraIcon fontSize={12} />
                          </M.ActionIcon>
                        );
                      }}
                    </M.FileButton>
                  </M.Box>
                </M.Box>
                <Mc
                  as={M.TextInput}
                  name="name"
                  label="Name"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                />
                <Mc
                  as={M.Select}
                  name="areaCode"
                  label="Area Code"
                  data={areaCodes}
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                  value={
                    areaCodes.find((c) => c.value === joinedAreaCode)?.value
                  }
                  onChange={(value) => {
                    const parts = value!.split("/");
                    const countryCode = parts[0];
                    const areaCode = parts[1];
                    form.setValue("countryCode", countryCode);
                    form.setValue("areaCode", areaCode);
                  }}
                  searchable
                  nothingFound="No options"
                  clearable
                />
                <Mc
                  as={M.TextInput}
                  name="phoneNumber"
                  label="PhoneNumber"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                  onInput={(e) => {
                    // @ts-ignore
                    const value = e.target.value;
                    const onlyNumbers = value.replace(/[^0-9]/g, "");
                    // @ts-ignore
                    e.target.value = onlyNumbers;
                  }}
                />

                <Mc
                  as={M.TextInput}
                  name="email"
                  label="Email"
                  styles={basicInfoInputStyles}
                  disabled={!basicInfoEditable}
                />
              </M.Stack>
            </M.Group>
            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start">
              <SectionTitle
                editable={auth?.role?.name === "ENTITY MANAGER" ? true : false}
                onEditClick={() => setSettingsEditable((prev) => !prev)}
              >
                Settings
              </SectionTitle>
              <M.Stack>
                <Mc
                  as={M.TextInput}
                  name="disclosure"
                  label="Disclosure"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                />
                <Mc
                  as={M.TextInput}
                  name="termsAndConditions"
                  label="Terms And Conditions"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                />
                <Mc
                  as={M.Switch}
                  name="verified"
                  label="Verified"
                  styles={settingsInputStyles}
                  disabled={!settingsEditable}
                  labelPosition="left"
                />
              </M.Stack>
            </M.Group>
            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start">
              <SectionTitle
                editable={auth?.role?.name === "ENTITY MANAGER" ? true : false}
                onEditClick={() => setShopSettingsEditable((prev) => !prev)}
              >
                Shop Settings
              </SectionTitle>
              <M.Stack>
                <Mc
                  as={M.MultiSelect}
                  name="shopSettings"
                  label="Select locations"
                  styles={shopSettingsInputStyles}
                  disabled={!shopSettingsEditable}
                  data={filials}
                />
              </M.Stack>
            </M.Group>
            <M.Divider color="gray.2" mx={-38} mt="md" mb="lg" />
            <M.Group noWrap align="start" mt="lg">
              <SectionTitle editable={false} />
              <M.Stack>
                {auth?.role?.name === "ENTITY MANAGER" && (
                  <M.Button type="submit" px="6ch" loading={isLoadingUpdate}>
                    Save
                  </M.Button>
                )}
              </M.Stack>
            </M.Group>
          </M.Stack>
        </form>
      </FormProvider>
    </M.Box>
  );
};
