import { modals } from "@mantine/modals"
import { DiscountsSection } from "../preview/sections"
import { S } from "#/s"

interface OpenDiscountsModalByUserProps {
	userId: number
}

export const openDiscountsModalByUser = (
	props: OpenDiscountsModalByUserProps,
) => {
	modals.open({
		children: <DiscountsModalByUser {...props} />,
		size: "lg",
		centered: true,
		modalId: "modal-discounts-by-user",
	})
}

const DiscountsModalByUser = (props: OpenDiscountsModalByUserProps) => {
	return (
		<S.div d="flex" flexDir="column" gap="4">
			<S.h3>Discounts</S.h3>
			<DiscountsSection customerId={props.userId} />
		</S.div>
	)
}
