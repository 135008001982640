import { BarChart } from "#/components-ng";
import GTLoader from "#/components-ng/loader";
import placeholderImage from "#/placeholder-image.jpg";
import { RouterOutputs, trpc } from "#/trpc";
import {
  C,
  FormValues,
  generateReportProductSegmentationSchema,
} from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { FormProvider } from "react-hook-form";
import { MdVisibility } from "react-icons/md";
import { Link } from "react-router-dom";

export const ProductSegmentationReport = () => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(7, "day").startOf("day").toDate(),
    dayjs().endOf("day").toDate(),
  ]);

  const { data: filials } = trpc.filial.getAll.useQuery();

  const form = C.useForm({
    resolver: zodResolver(generateReportProductSegmentationSchema),
    shouldUnregister: false,
    defaultValues: {
      dateRange: [dateRange[0]!, dateRange[1]!],
      filialId: "1",
      categoryId: null,
      departmentId: null,
      seasonId: null,
    },
  });

  const {
    data: report,
    mutate: generateReport,
    isLoading,
  } = trpc.v2_5.report.getProductsSegmentation.useMutation();

  useEffect(() => {
    generateReport({
      dateRange: {
        from: dateRange[0]!,
        to: dateRange[1]!,
      },
      filialId: 1,
      categoryId: null,
      departmentId: null,
      seasonId: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filialOptions = useMemo(
    () =>
      filials?.map((filial) => ({
        label: filial.name,
        value: filial.id.toString(),
      })) ?? [],
    [filials]
  );

  const data = useMemo(() => {
    if (report) {
      return report;
    }
    return null;
  }, [report]);

  const handleSubmit = (values: FormValues) => {
    generateReport({
      dateRange: {
        from: values.dateRange[0],
        to: values.dateRange[1],
      },
      filialId: Number(values.filialId),
      categoryId: values.categoryId ? Number(values.categoryId) : null,
      departmentId: values.departmentId ? Number(values.departmentId) : null,
      seasonId: values.seasonId ? Number(values.seasonId) : null,
    });
  };

  return (
    <M.Container size="xl">
      <M.Stack spacing="md">
        <M.Title order={2}>Beta: Products segmentation</M.Title>
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              form.handleSubmit(handleSubmit)(e);
            }}
          >
            <M.Group>
              <C.M
                as={M.Select}
                label={"Select filial"}
                name="filialId"
                data={filialOptions}
                required
              />
              <C.M
                as={DatePickerInput}
                label="Date range"
                name="dateRange"
                type="range"
                onChange={(v) => {
                  if(!v) return;
                  if (v[0] == null || v[1] == null) return;
                  setDateRange([v[0], v[1]]);
                }}
                numberOfColumns={2}
                popoverProps={{
                  zIndex: 9000,
                }}
                w={300}
                required
              />
              <M.Button
                type="submit"
                loading={isLoading}
                className="mt-6 px-12 "
              >
                Generate
              </M.Button>
            </M.Group>
            <M.Group mt="lg">
              <DepartmentField />
              <CategoryField />
              <SeasonField />
            </M.Group>
          </form>
        </FormProvider>
        {isLoading ? (
          <M.LoadingOverlay
            visible={isLoading}
            loader={<GTLoader width={100} height={100} />}
          />
        ) : (
          <M.Stack spacing="lg">
            <M.Title order={5}>Product Hot Sales</M.Title>
            <BarChart
              pathname="/inventory/item-list/preview/"
              enableRedirect
              textLabel="Sold items"
              data={
                data?.itemSkusHotSales?.map((p) => ({
                  id: p.itemSkuId,
                  label: `${p.sku}`,
                  total: p.total,
                })) ?? []
              }
            />
            <M.Divider className="my-5" />
            <M.Title order={5}>Product Clearance</M.Title>
            <ProductClearanceTable items={data?.itemSkusClearance ?? []} />
          </M.Stack>
        )}
      </M.Stack>
    </M.Container>
  );
};

type ProductsClearance =
  RouterOutputs["v2_5"]["report"]["getProductsSegmentation"]["itemSkusClearance"][number];

interface ProductClearanceTableProps {
  items?: ProductsClearance[] | null;
}

const ProductClearanceTable = ({ items }: ProductClearanceTableProps) => {
  const table = useMantineReactTable({
    data: items ?? [],
    columns: columns,
    enableColumnFilters: false,
    enableTopToolbar: false,
    enableFilters: false,
  });

  return <MantineReactTable table={table} />;
};

const columns: Array<MRT_ColumnDef<ProductsClearance>> = [
  {
    id: "actions",
    header: "Actions",
    Cell(props) {
      return (
        <M.ActionIcon
          component={Link}
          to={`/inventory/item-list/preview/${props.row.original.id}`}
          target="_blank"
        >
          <MdVisibility fontSize="0.8rem" />
        </M.ActionIcon>
      );
    },
  },
  {
    accessorKey: "defaultImage",
    header: "Image",
    enableSorting: false,
    enableColumnFilter: false,
    Cell(props) {
      const image = props.row.original?.defaultImage;

      return (
        <img src={image ?? placeholderImage} className="w-11 cursor-pointer" />
      );
    },
  },
  {
    accessorKey: "sku",
    header: "Sku",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: "title",
    header: "Name",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: "category",
    header: "Category",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: "department",
    header: "Department",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: "season",
    header: "Season",
    enableSorting: false,
    enableColumnFilter: false,
  },
];

const DepartmentField = () => {
  const form = C.useFormContext();
  const controller = C.useController({
    name: "departmentId",
    control: form.control,
  });

  const { data } = trpc.department.getAll.useQuery();

  return (
    <C.M
      as={M.Select}
      label="Department"
      name={controller.field.name}
      data={data?.map((d) => ({ label: d.name, value: d.id.toString() })) ?? []}
      clearable
      searchable
      onChange={(e) => {
        controller.field.onChange(e);
      }}
    />
  );
};

const CategoryField = () => {
  const form = C.useFormContext();
  const controller = C.useController({
    name: "categoryId",
    control: form.control,
  });

  const { data } = trpc.category.getAll.useQuery();

  return (
    <C.M
      as={M.Select}
      label="Category"
      name={controller.field.name}
      data={data?.map((c) => ({ label: c.name, value: c.id.toString() })) ?? []}
      clearable
      searchable
      onChange={(e) => {
        controller.field.onChange(e);
      }}
    />
  );
};

const SeasonField = () => {
  const form = C.useFormContext();
  const controller = C.useController({
    name: "seasonId",
    control: form.control,
  });

  const { data } = trpc.season.getAll.useQuery();

  return (
    <C.M
      as={M.Select}
      label="Season"
      name={controller.field.name}
      data={data?.map((s) => ({ label: s.name, value: s.id.toString() })) ?? []}
      clearable
      searchable
      onChange={(e) => {
        controller.field.onChange(e);
      }}
    />
  );
};
