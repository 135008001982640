import {
	BannerIcon,
	ListBoxIcon,
	NotePadIcon,
	QuizIcon,
	RouletteIcon,
	TimerOutlineIcon,
} from "#/components-ng/icons.js"
import Card from "#/components/Card"
import CategoryGridLayout from "#/components/CategoryGridLayout"
import * as M from "@mantine/core"

export const CrmScene = () => {
	return (
		<M.Stack>
			<CategoryGridLayout category={"CRM"}>
				<Card
					icon={<NotePadIcon className="size-8" />}
					text="Blog"
					path="blog"
					module="crm.blog"
				/>
				<Card
					icon={<BannerIcon />}
					text="Home page banner"
					path="banner"
					module="crm.banner"
				/>
				<Card
					icon={<QuizIcon className="size-8" />}
					text="Quiz"
					path="quiz"
					module="crm.quiz"
				/>
				<Card
					icon={<RouletteIcon />}
					text="Roulette"
					path="roulette"
					module="crm.roulette"
				/>
				<Card
					icon={<ListBoxIcon fontSize={36} />}
					text="Forms"
					path="forms"
					module="crm.form"
				/>
				<Card
					icon={<TimerOutlineIcon fontSize={36} />}
					text="Timer"
					path="timer"
					module="crm.timer"
				/>
				<Card
					icon={<TutorialsIcon />}
					text="Tutorials"
					path="tutorials"
					module="crm.tutorials"
				/>
			</CategoryGridLayout>
		</M.Stack>
	)
}

const TutorialsIcon = () => {
	return (
		<svg
			width={40}
			height={41}
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.25 15.5L20 5.5L38.75 15.5L20 25.5L1.25 15.5Z"
				stroke="#1D2024"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.375 38V20.5L20 15.5"
				stroke="#1D2024"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M34.375 17.8281V26.3594C34.3739 26.6249 34.2861 26.8827 34.125 27.0937C33.0781 28.5 28.6406 33.625 20 33.625C11.3594 33.625 6.92187 28.5 5.875 27.0937C5.7139 26.8827 5.62612 26.6249 5.625 26.3594V17.8281"
				stroke="#1D2024"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
