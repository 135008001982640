import { css } from "#/css/css"
import { type RouterOutputs, trpc } from "#/trpc"
import { Button } from "@gt/ui"
import { Badge, IconButton, Tooltip } from "@radix-ui/themes"
import dayjs from "dayjs"
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
	type MRT_SortingState,
} from "mantine-react-table"
import { EyeIcon } from "#/components"
import { useMemo } from "react"
import { openReturnDetailsModal } from "./return-details-modal"
import { reportUserSuccess } from "#/util"
import { MdOutlineDownload } from "react-icons/md"
import { S } from "#/s"

interface ReturnHistorySectionProps {
	customerId: number
}

type Refund =
	RouterOutputs["v2_5"]["orderReturn"]["getRefundsByUserAndPage"]["entries"][number]

export const ReturnHistorySection = (props: ReturnHistorySectionProps) => {
	return (
		<div
			className={css({
				marginTop: 5,
			})}
		>
			<ReturnHistorySectionTable customerId={props.customerId} />
		</div>
	)
}

interface ReturnHistoryTableSectionProps {
	customerId: number
}

const ReturnHistorySectionTable = (props: ReturnHistoryTableSectionProps) => {
	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	})

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([])
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			}
		}
		return null
	}, [sorting])

	const { data, isLoading } =
		trpc.v2_5.orderReturn.getRefundsByUserAndPage.useQuery(
			{
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				sorting: querySorting,
				filters: {
					customerId: props.customerId,
				},
			},
			{
				keepPreviousData: true,
			},
		)

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.orderReturn.exportReturnHistoryByUserTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				})
			},
		})

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			filters: {
				customerId: props.customerId,
			},
		})
	}

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderEmptyRowsFallback() {
			return (
				<S.div
					d="flex"
					justifyContent="center"
					alignItems="center"
					h={200}
					fontSize={20}
					color="gray"
				>
					No returns found
				</S.div>
			)
		},
		renderBottomToolbarCustomActions: () => (
			<div
				className={css({
					display: "flex",
					alignItems: "center",
					gap: 2,
				})}
			>
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</div>
		),
	})

	return <MantineReactTable table={table} />
}

const columns: MRT_ColumnDef<Refund>[] = [
	{
		id: "actions",
		header: "Actions",
		size: 100,
		enableSorting: false,
		Cell: (table) => {
			const reference = table.row.original.reference

			return (
				<div>
					<Button
						variant="secondary"
						size="icon"
						onClick={() => openReturnDetailsModal(reference)}
						aria-label="open"
					>
						<EyeIcon />
					</Button>
				</div>
			)
		},
	},
	{
		id: "createdAt",
		header: "Date",
		accessorKey: "createdAt",
		Cell: (table) => {
			const createdAt = table.row.original.createdAt
			if (!createdAt) return ""
			return dayjs(createdAt).format("MM/DD/YYYY").toString()
		},
	},
	{
		id: "reference",
		header: "Reference",
		accessorKey: "reference",
		Cell: (table) => {
			return `R${table.row.original.reference}`
		},
	},
	{
		id: "items",
		header: "Items",
		accessorKey: "items",
	},
	{
		id: "isReversed",
		header: "Status",
		accessorKey: "isReversed",
		Cell: (table) => {
			const status = table.row.original.isReversed ? "Reversed" : "Complete"
			return (
				<Badge
					size="3"
					radius="full"
					color={table.row.original.isReversed ? "red" : "green"}
				>
					{status}
				</Badge>
			)
		},
	},
	{
		id: "total",
		header: "Total",
		accessorKey: "total",
		Cell: (table) => {
			return `$${table.row.original.total.toFixed(2)}`
		},
	},
]
