import { useAuth } from "#/context/AuthContext.js"
import { Select } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import dayjs from "dayjs"
import { useState, useMemo } from "react"
import { MdFilterAlt } from "react-icons/md/index.js"
import { Link } from "react-router-dom"
import { FilialFilter, PendingCartsTable } from "./table.js"
import { Button, Checkbox, Dropdown } from "@gt/ui"
import { css } from "#/css/css"
import { HStack } from "#/css/jsx"
import {
	Filter,
	FilterKey,
	CustomerFilter,
	CustomerFilterKey,
} from "./$common.js"
import { DropdownMenu } from "@radix-ui/themes"
import CalendarIcon from "~icons/ion/calendar-clear-outline"
import { SearchButton } from "#/components@v2_5/search-button.js"

type RangeDateFilter = "Today" | "Yesterday" | "Last 30 days" | "Custom"

export const PendingCarts = () => {
	const [{ auth }] = useAuth()

	const [dateRangeKind, setDateRangeKind] =
		useState<RangeDateFilter>("Last 30 days")

	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().subtract(1, "M").toDate(),
		new Date(),
	])

	const [filters, setFilters] = useState<Record<FilterKey, boolean>>(
		() =>
			Object.fromEntries(
				Object.keys(Filter).map((key) => [
					key,
					key === "HOLD" ||
					key === "READY_TO_PAY" ||
					key === "WAITING_FOR_PAYMENT"
						? true
						: false,
				]),
			) as any,
	)

	const [query, setQuery] = useState<string | null>(null)

	const [filterWithCustomer, setFilterWithCustomer] = useState<
		Record<CustomerFilterKey, boolean>
	>(
		() =>
			Object.fromEntries(
				Object.keys(CustomerFilter).map((key) => [
					key,
					key === "withCustomer" ? true : false,
				]),
			) as any,
	)

	const filialFilter: FilialFilter = useMemo(() => {
		const ecommerceFilialActive = filters.ECOMMERCE

		if (ecommerceFilialActive) {
			return {
				by: "alias",
				alias: "ECOMMERCE",
			}
		} else if (auth?.user?.filialId != null) {
			return { by: "id", id: auth.user.filialId }
		}
	}, [auth, filters])

	function selectFilter(filter: FilterKey) {
		setFilters((old) => ({
			...old,
			[filter]: !old[filter],
		}))
	}

	function selectCustomerFilter(filter: CustomerFilterKey) {
		setFilterWithCustomer((old) => ({
			...old,
			[filter]: !old[filter],
		}))
	}

	return (
		<>
			<HStack justify="space-between" gap={3} flexWrap="nowrap">
				<HStack width="100%" gap={3}>
					<DrowndownButton />
					<CartStatusDropdown filters={filters} selectFilter={selectFilter} />
					<CustomerDrowndownButton
						filterWithCustomer={filterWithCustomer}
						selectCustomerFilter={selectCustomerFilter}
					/>
					<SelectDateDropdown
						dateRangeKind={dateRangeKind}
						setDateRangeKind={setDateRangeKind}
						setDateRange={setDateRange}
					/>
					<DatePickerInput
						type="range"
						value={dateRange}
						onChange={(dateRange) => {
							setDateRangeKind("Custom")
							setDateRange(dateRange)
						}}
						numberOfColumns={2}
						popoverProps={{
							zIndex: 9999,
							withinPortal: false,
						}}
						clearable={false}
						icon={<CalendarIcon color="#424242" />}
					/>
				</HStack>
				<SearchButton setQuery={setQuery} />
			</HStack>
			<div className={css({ marginTop: 15 })}>
				<PendingCartsTable
					filter={
						Object.entries(filters)
							.filter(([, active]) => active)
							.map(([filter]) => filter) as any
					}
					search={query}
					dateRange={dateRange}
					filialFilter={filialFilter}
					customer={
						Object.entries(filterWithCustomer)
							.filter(([, active]) => active)
							.map(([filter]) => filter) as any
					}
				/>
			</div>
		</>
	)
}

interface CartStatusDropdownProps {
	filters: Record<FilterKey, boolean>
	selectFilter: (filter: FilterKey) => void
}

const CartStatusDropdown = (props: CartStatusDropdownProps) => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button>
					Filter
					<MdFilterAlt
						className={css({
							marginLeft: 2,
						})}
					/>
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content
				className={css({
					display: "grid",
					gridGap: "0.1rem",
				})}
				loop
			>
				{Object.entries(props.filters).map(([filterKey, isFilterActive], i) => (
					<Dropdown.Item
						key={i}
						className={css({
							display: "flex",
							alignItems: "center",
							gap: 2,
						})}
						onSelect={(e) => {
							e.preventDefault()
							props.selectFilter(filterKey as FilterKey)
						}}
					>
						<Checkbox checked={isFilterActive} />
						<span>{Filter[filterKey]}</span>
					</Dropdown.Item>
				))}
			</Dropdown.Content>
		</Dropdown.Root>
	)
}

const DrowndownButton = () => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button
					className={css({
						gap: 4,
					})}
				>
					I want to
					<DropdownMenu.TriggerIcon />
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				<Dropdown.Item>
					<Link to="delete-on-hold">Delete carts on hold</Link>
				</Dropdown.Item>
				<Dropdown.Item>
					<Link to="notifications">Pending carts notifications</Link>
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown.Root>
	)
}

interface SelectDateDropdownProps {
	setDateRange: (dateRange: [Date | null, Date | null]) => void
	dateRangeKind: RangeDateFilter
	setDateRangeKind: (dateRangeKind: RangeDateFilter) => void
}

const SelectDateDropdown = (props: SelectDateDropdownProps) => {
	return (
		<Select
			data={[
				"Today",
				"Yesterday",
				"Last 30 days",
				{ value: "Custom", label: "Custom", disabled: true },
			]}
			value={props.dateRangeKind}
			zIndex={3000}
			onChange={(v) => {
				props.setDateRangeKind(v! as "Today")
				if (v === "Today") {
					props.setDateRange([new Date(), new Date()])
				} else if (v === "Yesterday") {
					props.setDateRange([
						dayjs(new Date()).subtract(1, "d").toDate(),
						dayjs(new Date()).subtract(1, "d").toDate(),
					])
				} else if (v === "Last 30 days") {
					props.setDateRange([
						dayjs(new Date()).subtract(30, "d").toDate(),
						new Date(),
					])
				}
			}}
			className={css({
				width: "15ch",
			})}
		/>
	)
}

interface CustomerDrowndownButtonProps {
	filterWithCustomer: Record<CustomerFilterKey, boolean>
	selectCustomerFilter: (filter: CustomerFilterKey) => void
}

const CustomerDrowndownButton = (props: CustomerDrowndownButtonProps) => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button variant="secondary">
					Filter With Customer
					<MdFilterAlt
						className={css({
							marginLeft: 2,
						})}
					/>
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				{Object.entries(props.filterWithCustomer).map(
					([filterKey, isFilterActive], i) => (
						<Dropdown.Item
							key={i}
							className={css({
								display: "flex",
								alignItems: "center",
								gap: 2,
							})}
							onSelect={(e) => {
								e.preventDefault()
								props.selectCustomerFilter(filterKey as CustomerFilterKey)
							}}
						>
							<Checkbox checked={isFilterActive} />
							<span>{CustomerFilter[filterKey]}</span>
						</Dropdown.Item>
					),
				)}
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
