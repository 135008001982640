// @ts-nocheck
import { useAuth } from "../context/AuthContext.js"
import Layout from "../modules/App/Layout/index.jsx"
import AuthScene from "./Auth/index.js"
import { CrmLayout } from "./Crm/Layout.js"
import { crmRoutes } from "./Crm/routes.js"
import Customers, {
	CustomersList,
	CreateCustomer,
	EditCustomer,
} from "./Customers/index.js"
import { MergeCustomersScene } from "./Customers/merge-customers/index.js"
import PreviewCustomer from "./Customers/preview/PreviewCustomer.js"
import { DashboardLayout } from "./Dashboard/Layout.js"
import { dashboardRoutes } from "./Dashboard/routes.js"
import HomeScene from "./Home/index.jsx"
import { InventoryLayout } from "./Inventory/Layout.js"
import {
	inventoryRoutesFreestanding,
	inventoryRoutes,
} from "./Inventory/routes.js"
import { PrintTable } from "./Print/index.js"
import { ReportsLayout } from "./Reports/Layout.js"
import { reportsRoutes } from "./Reports/routes.js"
import { SalesLayout } from "./Sales/Layout.js"
import { salesRoutes, salesRoutesFreestanding } from "./Sales/routes.js"
import { SettingsLayout } from "./Settings/Layout.js"
import { settingsRoutes } from "./Settings/routes.js"
import { QRForm } from "./UserFacing/index.js"
import PrintLab from "./lab.jsx"
import * as Sentry from "@sentry/react"
import { Helmet } from "react-helmet"
import { Navigate, Outlet, useLocation } from "react-router"
import { createBrowserRouter } from "react-router-dom"

function RequireAuth({ children }) {
	const [{ hasSession }] = useAuth()
	const location = useLocation()

	if (!hasSession) {
		return <Navigate to="/login" state={{ from: location }} replace />
	}

	return children
}

export const routes = [
	{
		path: "/login",
		element: <AuthScene />,
	},
	{
		path: "/",
		element: (
			<RequireAuth>
				<Layout />
			</RequireAuth>
		),
		children: [
			{
				path: "/ecommerce-crm",
				element: <CrmLayout />,
				children: crmRoutes,
			},
			{
				path: "/dashboard",
				element: <DashboardLayout />,
				children: dashboardRoutes,
			},
			{
				path: "/sales",
				element: <SalesLayout />,
				children: salesRoutes,
			},
			{
				path: "/inventory",
				element: <InventoryLayout />,
				children: inventoryRoutes,
			},
			{
				path: "/reports",
				element: <ReportsLayout />,
				children: reportsRoutes,
			},
			{
				path: "/settings",
				element: <SettingsLayout />,
				children: settingsRoutes,
			},
			{
				index: true,
				element: <HomeScene />,
				breadcrumb: "Home",
			},
			{
				path: "/customers",
				element: <Customers />,
				breadcrumb: "Customers",
			},
			{
				path: "/customers/list",
				element: <CustomersList />,
				breadcrumb: "Customers list",
			},
			{
				path: "/customers/list/create",
				element: <CreateCustomer />,
				breadcrumb: "Create customer",
			},
			{
				path: "/customers/list/edit/:id",
				element: <EditCustomer />,
				breadcrumb: "Edit customer",
			},
			{
				path: "/customers/list/preview/:id",
				element: <PreviewCustomer />,
				breadcrumb: "Preview customer",
			},
			{
				path: "/customers/list/merge",
				element: <MergeCustomersScene />,
				breadcrumb: "Merge customers",
			},
			{
				path: "/lab/print",
				element: <PrintLab />,
				breadcrumb: "Print laboratory",
			},
		],
	},
	{
		path: "/sales",
		element: <Outlet />,
		children: salesRoutesFreestanding,
	},
	{
		path: "/inventory",
		element: <Outlet />,
		children: inventoryRoutesFreestanding,
	},
	{
		path: "/print/table",
		element: <PrintTable />,
	},
	{
		path: "/qr-signup",
		element: <QRForm />,
	},
	{
		path: "*",
		element: (
			<RequireAuth>
				<Helmet>
					<title>Not found</title>
				</Helmet>
				<main>There is nothing here</main>
			</RequireAuth>
		),
	},
]

export const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter)

let browserRouter: ReturnType<typeof sentryCreateBrowserRouter>

// Necesitamos crear el router después de que se inicializó Sentry, por eso esta
// función inicializa `browserRouter` hasta el primer render cuando es llamada
// por el componente `AppScenes`.
export const getBrowserRouter = () => {
	if (browserRouter == null) {
		browserRouter = sentryCreateBrowserRouter(routes)
	}
	return browserRouter
}

function flattenRoutes(routes, base = "") {
	return [
		...routes.map((route) => ({
			...route,
			path: !route.path?.startsWith("/") ? `${base}/${route.path}` : route.path,
		})),
		...routes.reduce(
			(accum, route) => [
				...accum,
				...(route.children
					? flattenRoutes(
							route.children,
							!route.path?.startsWith("/")
								? `${base}/${route.path}`
								: route.path,
						)
					: []),
			],
			[],
		),
	]
}

export const flattenedRoutes = flattenRoutes(routes)
