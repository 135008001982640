import { styled } from "#/css/jsx"
import { v4 as uuidv4 } from "uuid"
import { CatalogFormImage } from "./catalog-form-image"
import {
	type CatalogImageFormValues,
	CI,
	createCatalogImageSchema,
} from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	type DefaultValues,
	FormProvider,
	type SubmitHandler,
} from "react-hook-form"
import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"

interface CatalogImageFormModalProps {
	opened: boolean
	setOpened: (opened: boolean) => void
	onSubmit: SubmitHandler<CatalogImageFormValues>
	isLoading: boolean
	defaultValues?: DefaultValues<CatalogImageFormValues>
	catalogItemImageId?: number | null
	textButtonDelete: string
}
interface ImageUploadProps {
	url: string
	uid: string
	isLoading: boolean
}

export function CatalogImageFormModal(props: CatalogImageFormModalProps) {
	const ctx = trpc.useContext()

	const { mutate: deleteCatalogImage } =
		trpc.v2_5.catalog.deleteCatalogImage.useMutation({
			onError(error) {
				reportUserError({
					title: "Error deleting catalog image",
					message: error.message,
				})
			},
			onSuccess() {
				ctx.v2_5.catalog.getCatalogImagesByPage.invalidate()
				reportUserSuccess({
					title: "Catalog image deleted",
					message: "Catalog image has been deleted successfully",
				})
				props?.setOpened(false)
			},
		})

	const form = CI.useForm({
		resolver: zodResolver(createCatalogImageSchema),
		shouldUnregister: false,
		defaultValues: {
			image: null,
			...props.defaultValues,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
		shouldFocusError: false,
	})

	const [image, setImage] = useState<ImageUploadProps | null>(() =>
		form.getValues("image")
			? {
					url: form.getValues("image")!.url,
					uid: uuidv4(),
					isLoading: false,
				}
			: null,
	)

	const removeImage = () => {
		if (props.defaultValues?.image && props.catalogItemImageId) {
			deleteCatalogImage({ id: props.catalogItemImageId })
			form.setValue("image", null)
			setImage(null)
		}
	}

	return (
		<FormProvider {...form}>
			<form
				id="catalog-image-form"
				onSubmit={form.handleSubmit((values) => {
					props.onSubmit(values)
					setImage(null)
					form.reset()
				})}
			>
				<styled.div>
					{image && (
						<CatalogFormImage
							key={image.uid}
							index={0}
							img={image}
							removeImage={removeImage}
							isEditorOpen={props.opened}
							setIsEditorOpen={props.setOpened}
							textButtonDelete={props.textButtonDelete}
							handleSubmit={() => {
								const formElement = document.getElementById(
									"catalog-image-form",
								) as HTMLFormElement
								if (formElement) {
									formElement.requestSubmit()
								}
							}}
							handleDelete={removeImage}
						/>
					)}
				</styled.div>
			</form>
		</FormProvider>
	)
}
