import * as M from "@mantine/core"
import { ChevronForwardOutline } from "#/components-ng"
import { css } from "#/s"
import { formMolecule } from "./state"
import { useMolecule } from "bunshi/react"

export function NextButton({
	step,
	nextStep,
}: { step: number; nextStep: () => void }) {
	const form = useMolecule(formMolecule)
	const areRequiredConditionsMet = useAtomValue(
		form.areRequiredConditionsMetAtom,
	)

	const label = step === 0 ? "Next" : "Merge"

	return (
		<M.Button
			rightIcon={<ChevronForwardOutline />}
			onClick={nextStep}
			className={css({ display: step === 2 ? "none" : undefined })}
			disabled={!areRequiredConditionsMet}
		>
			{label}
		</M.Button>
	)
}
