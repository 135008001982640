import { type RouterOutputs, trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import BarcodeReader from "react-barcode-reader"
import { useSetAtom } from "jotai/react"
import { lastEntryAddedIndexAtom } from "./state"
import { MobileScannerVerifyItems } from "./mobile-scan"

type OrderItemSku =
	RouterOutputs["v2_5"]["order"]["getOrderItemSkus"]["orderItemSku"][number]

interface ScanProductProps {
	orderId: number
	orderItemSkus: OrderItemSku[]
}

export const ScanProduct = (props: ScanProductProps) => {
	const ctx = trpc.useContext()
	const trpcClient = ctx.client
	const setLastEntryAddedIndex = useSetAtom(lastEntryAddedIndexAtom)

	const { mutate: verifyItem } = trpc.v2_5.order.item.verifyItem.useMutation({
		onSuccess() {
			ctx.v2_5.order.invalidate()
			reportUserSuccess({
				title: "Item verified",
			})
		},
	})

	const handleScanProduct = useCallback(
		(dataPreprocessed) => {
			const dataSkuNumber = parseInt(dataPreprocessed)
			if (!dataSkuNumber) {
				return
			}
			;(async () => {
				try {
					const foundProduct = await trpcClient.itemSku.findFirstBySku.query({
						sku: dataSkuNumber,
					})
					if (foundProduct) {
						const cartItemSku = props?.orderItemSkus.find(
							(cartItemSku) => cartItemSku.itemSku.sku === dataSkuNumber,
						)

						if (cartItemSku) {
							const verifiedQuantity = (cartItemSku?.verifiedQuantity ?? 0) + 1

							setLastEntryAddedIndex({
								sku: cartItemSku.itemSku.sku,
							})

							verifyItem({
								orderId: props.orderId,
								orderItemSkuId: cartItemSku.id,
								verifiedQuantity,
							})
						} else {
							reportUserError({
								title: "Product not found in order",
								message: "The scanned product is not in the order",
							})
						}
					}
				} catch (e) {
					reportUserError({
						title: "Failed to scan product",
						message: e.message,
					})
				}
			})()
		},
		[
			trpcClient.itemSku.findFirstBySku,
			props.orderItemSkus,
			props.orderId,
			verifyItem,
		],
	)

	return (
		<>
			<BarcodeReader
				onScan={handleScanProduct}
				minLength={4}
				avgTimeByChar={25}
			/>
			<MobileScannerVerifyItems onScan={handleScanProduct} minLength={4} />
		</>
	)
}
