import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const generateSalesReportByCustomerSchema = z.object({
	dateRange: z.array(z.date()),
	customerId: z.string().optional().nullish(),
})

export type FormValues = z.infer<typeof generateSalesReportByCustomerSchema>

export const C = makeController<FormValues>()
