import { AccountSwitchOutlineIcon } from "#/components-ng"
import SceneLayout from "#/components/SceneLayout"
import { S } from "#/s"
import * as M from "@mantine/core"
import { SelectCustomersStep } from "./steps/select-accounts"
import { VerifyStep } from "./steps/verify"
import { ScopeProvider, useMolecule } from "bunshi/react"
import { formMolecule, formScope } from "./state"
import { NextButton } from "./next-button"
import { PreviousButton } from "./previous-button"
import { CompleteStep } from "./steps/complete"
import { useSubmit } from "./submit"

export function MergeCustomersScene() {
	return (
		<ScopeProvider scope={formScope}>
			<MergeCustomersSceneInner />
		</ScopeProvider>
	)
}

function MergeCustomersSceneInner() {
	const form = useMolecule(formMolecule)
	const [step, setStep] = useAtom(form.stepAtom)
	const submit = useSubmit()

	const nextStep = () => {
		if (step < 3) {
			const newStep = step + 1
			setStep(step + 1)

			if (newStep === 2) {
				submit()
			}
		}
	}

	const prevStep = () => {
		if (step > 0) {
			setStep(step - 1)
		}
	}

	return (
		<SceneLayout icon={<AccountSwitchOutlineIcon />}>
			<S.div w="100%" maxW="860px" mx="auto">
				<M.Stepper active={step} size="sm">
					<M.Stepper.Step label="Select accounts" />
					<M.Stepper.Step label="Verify" />
					<M.Stepper.Step label="Complete" />
				</M.Stepper>
				<S.div h="8" />
				<StepOutlet step={step} />
				<S.div d="flex" justifyContent="space-between" mt="6">
					<PreviousButton step={step} previousStep={prevStep} />
					<NextButton step={step} nextStep={nextStep} />
				</S.div>
			</S.div>
		</SceneLayout>
	)
}

function StepOutlet({ step }: { step: number }) {
	switch (step) {
		case 0:
			return <SelectCustomersStep />
		case 1:
			return <VerifyStep />
		case 2:
			return <CompleteStep />
		default:
			return null
	}
}
