import { cartEntriesAtom } from "./cart-entries.js";
import {
  discountsAtom,
  discountsWithMinQuantityAtom,
  globalDiscountsAtom,
  promotionsAtom,
} from "./discounts.js";
import { trpcAtom } from "./trpc.js";
import { waitingListsClearAtom } from "./waiting-list.js";
import { atom } from "jotai/vanilla"
import type { PublicUser } from "server";

type Customer = PublicUser;
export interface Associated {
  id: number;
  filialId: number;
  firstName: string;
  lastName?: string | null;
}

export const customerBaseAtom = atom<Customer | null>(null);
export const salesAssociateAtom = atom<Customer | null>(null);
export const cashierAtom = atom<Customer | null>(null);
export const helperAtom = atom<Customer | null>(null);
export const associatedAtom = atom<Associated | null>(null);

export const customerAtom = atom(
  (get) => get(customerBaseAtom),
  (get, set, newCustomer: Customer) => {
    const oldCustomer = get(customerBaseAtom);

    set(customerBaseAtom, newCustomer);
    const trpc = get(trpcAtom);
    (async () => {
      // Reload available discounts and promotions
      const discountsResult = await trpc.client.discount.getManyByUserId.query({
        userId: newCustomer.id,
      });

      const promotionsResult =
        await trpc.client.promotion.getManyActive.query();

      const discountWithMinQuantity = await trpc.client.v2_5.discounts.getDiscountWithMinQuantity.query();
      
      set(promotionsAtom, promotionsResult);

      set(discountsAtom, discountsResult);

      set(discountsWithMinQuantityAtom, discountWithMinQuantity);

      const globalDiscountsResult = await trpc.client.discount.getAll.query();
      set(globalDiscountsAtom, globalDiscountsResult);

      if (oldCustomer && oldCustomer.id !== newCustomer.id) {
        set(cartEntriesAtom, {
          type: "reloadDiscountsWithOverride",
        });
      }

      // clear waiting lists
      set(waitingListsClearAtom);

      const salesAssociate = get(salesAssociateAtom);
      const cashier = get(cashierAtom);
      const helper = get(helperAtom);
      if (salesAssociate == null) {
        set(salesAssociateAtom, newCustomer);
      }
      if (cashier == null) {
        set(cashierAtom, newCustomer);
      }
      if (helper == null) {
        set(helperAtom, newCustomer);
      }
    })();
  },
);

export const receiptNameAtom = atom<"customerName" | "companyName">(
  "companyName",
);

type PeopleValidationDiagnostic = {
  code: "SAME_CUSTOMER_AND_ASSOCIATE";
};

export const peopleValidationAtom = atom<Array<PeopleValidationDiagnostic>>(
  (get) => {
    const customer = get(customerBaseAtom);
    const associated = get(associatedAtom);

    if (customer == null || associated == null) {
      return [];
    }

    if (customer.id === associated.id) {
      return [{ code: "SAME_CUSTOMER_AND_ASSOCIATE" }];
    }

    return [];
  },
);
