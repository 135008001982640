import { S } from "#/s"
import * as M from "@mantine/core"
import { User } from "./types"
import { openOrdersModalByUser } from "./orders-modal"
import { openDiscountsModalByUser } from "./discounts-modal"

export interface CustomerInfoProps {
	customer: User
	dimmed?: boolean
	diffs?: {
		discounts: number
		orders: number
	}
}

export function CustomerInfo({ customer, dimmed, diffs }: CustomerInfoProps) {
	return (
		<S.div c={dimmed ? "slate.400" : "slate.600"}>
			<S.div d="grid" gridTemplateColumns="auto 1fr" gapX="4">
				<M.Avatar radius="xl" size="lg" src={customer.avatar ?? undefined} />
				<S.div>
					<S.p fw="medium">
						{customer.firstName} {customer.lastName}
					</S.p>
					<S.p fs="sm">{customer.email}</S.p>
				</S.div>
			</S.div>
			<S.div mt="6">
				<S.p fw="medium">Address</S.p>
				<S.p>
					<em>
						{customer.street == null &&
							customer.country == null &&
							"No address"}
					</em>

					{customer.street && `${customer.street}, `}
					{customer.city && `${customer.city}, `}
					{customer.state && `${customer.state}, `}
					{customer.country}
				</S.p>
			</S.div>
			<S.div mt="6">
				<S.p fw="medium">Stats</S.p>
				<S.ul listStyleType="disc" listStylePosition="inside" pl="0">
					<S.li>
						Registered on {new Date(customer.createdAt).toLocaleDateString()}
					</S.li>
					<S.li>{customer.credits.toString()} credits</S.li>
					<S.li>
						{customer?._count?.userDiscounts ?? 0} discounts{" "}
						{diffs?.discounts != null && (
							<S.span c="green.500" fw="medium">
								({diffs.discounts} new)
							</S.span>
						)}
						<S.button
							bg={{ base: "slate.100", _hover: "slate.200" }}
							rounded="md"
							px="1"
							fs="xs"
							ml="2"
							onClick={() =>
								openDiscountsModalByUser({
									userId: customer.id,
								})
							}
						>
							View
						</S.button>
					</S.li>
					<S.li>
						{customer?._count?.orderCustomer ?? 0} orders{" "}
						{diffs?.orders != null && (
							<S.span c="green.500" fw="medium">
								({diffs.orders} new)
							</S.span>
						)}
						<S.button
							bg={{ base: "slate.100", _hover: "slate.200" }}
							rounded="md"
							px="1"
							fs="xs"
							ml="2"
							onClick={() =>
								openOrdersModalByUser({
									userId: customer.id,
								})
							}
						>
							View
						</S.button>
					</S.li>
				</S.ul>
			</S.div>
		</S.div>
	)
}
