import { trpc } from "#/trpc";
import { useMolecule } from "bunshi/react";
import { useSetAtom, useAtom, useAtomValue } from "jotai/react";
import { formMolecule } from "./state";
import { reportUserError } from "#/util";
import { useNavigate } from "react-router";

export function useSubmit() {
	const form = useMolecule(formMolecule);
	const setStep = useSetAtom(form.stepAtom);
	const setStatus = useSetAtom(form.submissionStatusAtom);
	const [submittedAt, setSubmittedAt] = useAtom(form.submittedAtAtom);
	const primaryAccount = useAtomValue(form.primaryAccountAtom);
	const secondaryAccount = useAtomValue(form.secondaryAccountAtom);
	const navigate = useNavigate();

	const { mutate } = trpc.v2_5.user.mergeAccounts.useMutation({
		onMutate() {
			setStep(2);
			setStatus("loading");
			setSubmittedAt(new Date());
		},
		onSettled: async (_data, error) => {
			const MIN_WAIT_TIME_MS = 1000;

			const now = new Date();
			const submittedAtMs = submittedAt?.getTime() ?? now.getTime();
			const responseTimeMs = now.getTime() - submittedAtMs;

			if (responseTimeMs < MIN_WAIT_TIME_MS) {
				await new Promise((resolve) =>
					setTimeout(resolve, MIN_WAIT_TIME_MS - responseTimeMs),
				);
			}

			if (error != null) {
				setStatus("error");
				setStep(1);
				reportUserError({
					title: "Failed to merge accounts",
					message: error.message,
				});
			} else {
				setStatus("success");
				setTimeout(() => {
					navigate("/customers/list");
				}, 1800);
			}
		},
		meta: {
			skipDefaultErrorMessage: false,
		},
	});

	return useCallback(() => {
		if (primaryAccount == null || secondaryAccount == null) {
			reportUserError({
				title: "Failed to merge accounts",
				message: "Primary or secondary account is missing",
			});
			return;
		}

		mutate({
			primaryAccountId: primaryAccount.id,
			secondaryAccountId: secondaryAccount.id,
		});
	}, [mutate, primaryAccount, secondaryAccount]);
}
