import { S } from "#/s"
import type { RouterOutputs } from "#/trpc"
import { Badge } from "@radix-ui/themes"
import dayjs from "dayjs"
import { match } from "ts-pattern"

type OrderStatus = NonNullable<
	RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderStatus"]

interface CustomerInfoProps {
	customerName: string
	createdAt: Date
	orderStatus: OrderStatus
}

export const CustomerInfo = ({
	customerName,
	createdAt,
	orderStatus,
}: CustomerInfoProps) => {
	return (
		<S.div d="flex" flexDir="column" gap="2">
			<S.p color="neutral.700" fw="500">
				Customer: <S.span fw="400">{customerName}</S.span>
			</S.p>
			<S.p color="neutral.700" fw="500">
				Created At:{" "}
				<S.span fw="400">
					{dayjs(createdAt).format("MM/DD/YYYY hh:mm A")}
				</S.span>
			</S.p>
			<S.p color="neutral.700" fw="500">
				Order status:{" "}
				<Badge
					size="3"
					radius="full"
					color={
						match(orderStatus)
							.with("PENDING", () => "blue")
							.with("PROCESSING", () => "orange")
							.with("CANCELLED", () => "red")
							.with("COMPLETE", () => "violet")
							.with("FAILED", () => "amber")
							.with("READY_TO_PICKUP", () => "gold")
							.otherwise(() => "gray") as any
					}
				>
					{orderStatus}
				</Badge>
			</S.p>
		</S.div>
	)
}
