import { S } from "#/s"
import type { RouterOutputs } from "#/trpc"
import { ActionIcon } from "@mantine/core"
import CheckIcon from "~icons/ic/baseline-check"
import CloseIcon from "~icons/ic/baseline-close"
import { openVerifyItemManuallyModal } from "./verify-item-manually-modal"
import { PencilCustomIcon } from "#/components-ng"
import { useMemo } from "react"
import { DisplayTable } from "#/components"
import { useAtomValue } from "jotai/react"
import { lastEntryAddedIndexAtom } from "./state"
import { Box } from "#/css/jsx"

type CartItemSku =
	RouterOutputs["v2_5"]["cart"]["getCartItemSkus"]["cartItemSku"][number]

interface VerifiedItemsPendingCartsProps {
	cartItemSkus: CartItemSku[]
}

export const VerifiedItemsPendingCartsTable = (
	props: VerifiedItemsPendingCartsProps,
) => {
	const lastEntryAdded = useAtomValue(lastEntryAddedIndexAtom)

	const cartItemSkusWithoutVerified = useMemo(
		() =>
			props.cartItemSkus?.filter((c) => {
				return c.verifiedQuantity !== c.quantity
			}) ?? [],
		[props.cartItemSkus],
	)

	const cartItemSkusVerified = useMemo(
		() =>
			props.cartItemSkus?.filter((c) => {
				return c.verifiedQuantity === c.quantity
			}) ?? [],
		[props.cartItemSkus],
	)

	const lastEntryUnverifiedAddedIndex = useMemo(() => {
		if (lastEntryAdded.sku !== -1) {
			const index = cartItemSkusWithoutVerified.findIndex(
				(c) => c.itemSku.sku === lastEntryAdded.sku,
			)
			return index
		}
		return -1
	}, [lastEntryAdded, cartItemSkusWithoutVerified])

	const lastEntryVerifiedAddedIndex = useMemo(() => {
		if (lastEntryAdded.sku !== -1) {
			const index = cartItemSkusVerified.findIndex(
				(c) => c.itemSku.sku === lastEntryAdded.sku,
			)
			return index
		}
		return -1
	}, [lastEntryAdded, cartItemSkusVerified])

	return (
		<>
			<S.p c="neutral.700" mt={4} fw="500" fs="md">
				Items unverified
			</S.p>
			<Box mt={4} overflowY="auto">
				<DisplayTable
					columns={columns}
					data={cartItemSkusWithoutVerified}
					highlightedRow={{
						index: lastEntryUnverifiedAddedIndex,
					}}
					pagination={false}
				/>
			</Box>
			<S.p c="neutral.700" mt={4} fw="500" fs="md">
				Items verified:
			</S.p>
			<Box mt={4} overflowY="auto">
				<DisplayTable
					columns={columns}
					data={cartItemSkusVerified}
					pagination={false}
					highlightedRow={{
						index: lastEntryVerifiedAddedIndex,
					}}
				/>
			</Box>
		</>
	)
}

const columns = [
	{
		Header: "Sku",
		accessor: "itemSku.sku",
	},
	{
		Header: "Title",
		accessor: "itemSku.title",
	},
	{
		Header: "Quantity",
		accessor: "quantity",
	},
	{
		Header: "Verified quantity",
		enableSorting: false,
		Cell: ({ row: { original } }) => {
			return `${original?.verifiedQuantity ?? 0}/${original?.quantity ?? 0}`
		},
	},
	{
		Header: "Verified",
		Cell: ({ row: { original } }) => {
			const isVerified = original?.verifiedQuantity === original?.quantity
			return (
				<S.div color={isVerified ? "lime.800" : "red.700"}>
					{isVerified ? <CheckIcon /> : <CloseIcon />}
				</S.div>
			)
		},
	},
	{
		Header: "Verify manually",
		Cell: ({ row: { original } }) => {
			return (
				<S.div d="flex" alignItems="center">
					<ActionIcon
						size="sm"
						variant="subtle"
						onClick={() =>
							openVerifyItemManuallyModal({
								cartItemSku: original,
							})
						}
					>
						<PencilCustomIcon />
					</ActionIcon>
				</S.div>
			)
		},
	},
]
