import { S, css } from "#/s"
import type { TutorialFormSchema } from "../tutorial-form-component/form"
import { trpc } from "#/trpc"
import { TutorialForm } from "../tutorial-form-component"
import { reportUserError, reportUserSuccess } from "#/util"
import { useNavigate, useParams } from "react-router"
import * as M from "@mantine/core"

export function EditTutorialScene() {
	const navigate = useNavigate()
	const params = useParams()
	const id = params.id ? Number(params.id) : null

	const { data, isLoading } = trpc.v2_5.tutorial.getById.useQuery(
		{
			id: id!,
		},
		{
			enabled: id != null,
		},
	)

	const { mutate } = trpc.v2_5.tutorial.update.useMutation({
		onError: (error) => {
			reportUserError({
				title: "Failed to edit tutorial",
				message: error.message,
			})
		},
		onSuccess: () => {
			// Redirect to list
			navigate("/ecommerce-crm/tutorials/")
			reportUserSuccess({
				title: "Success",
				message: "Tutorial saved successfully",
			})
		},
	})

	const handleSaveTutorial = (values: TutorialFormSchema) => {
		mutate({
			...values,
			itemSkus: values.itemSkus.map((item) => item.id),
			id: id!,
		})
	}

	return (
		<S.div
			css={{
				maxWidth: "600px",
				mx: "auto",
				px: "3rem",
				py: "2.5rem",
				bg: "white",
				rounded: "1rem",
			}}
		>
			<S.h1 css={{ fontSize: "1.75rem", fontWeight: "bold", mb: "2rem" }}>
				Edit Tutorial
			</S.h1>

			<TutorialFormWithDefaultValues
				defaultValues={
					data
						? {
								...data,
								itemSkus: data.itemSkusRelation.map(
									(iskuRel) => iskuRel.itemSku,
								),
							}
						: null
				}
				isLoading={isLoading}
				onSubmit={handleSaveTutorial}
			/>
		</S.div>
	)
}

function TutorialFormWithDefaultValues(props: {
	isLoading: boolean
	defaultValues: TutorialFormSchema | null
	onSubmit: (values: TutorialFormSchema) => void
}) {
	if (props.isLoading) {
		return (
			<S.div d="grid" gapY="1.5rem" mt="2rem">
				<M.Skeleton h="2rem" />
				<M.Skeleton h="2rem" />
				<M.Skeleton h="2rem" />
				<M.Skeleton h="6rem" />
				<M.Skeleton h="2rem" />
				<M.Skeleton h="2.75em" w="12rem" />
			</S.div>
		)
	}

	if (props.defaultValues == null) {
		return (
			<S.p fw="600">
				This tutorial does not exist or there was a problem retrieving it.
			</S.p>
		)
	}

	return (
		<TutorialForm defaultValues={props.defaultValues} onSubmit={props.onSubmit} />
	)
}
