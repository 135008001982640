import { css, S } from "#/s"
import { trpc } from "#/trpc"
import {
	C,
	type FormValues,
	generateSalesReportByCustomerSchema,
} from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import { DatePickerInput } from "@mantine/dates"
import dayjs from "dayjs"
import { FormProvider } from "react-hook-form"
import { reportUserError } from "#/util"
import { useState, useMemo } from "react"
import { Select } from "#/components-ng"
import { Button } from "@gt/ui"
import { PieChartCard } from "../common"
import { generateRandomHexColor } from "#/util/color"

export const SalesReportByCustomer = () => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	])

	const form = C.useForm({
		resolver: zodResolver(generateSalesReportByCustomerSchema),
		shouldUnregister: false,
		defaultValues: {
			dateRange: [dateRange[0]!, dateRange[1]!],
			customerId: null,
		},
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getSalesReportByCustomer.useMutation()

	const data = useMemo(() => {
		if (report) {
			return report
		}
		return null
	}, [report])

	const handleSubmit = (values: FormValues) => {
		if (!values.customerId) {
			reportUserError({
				title: "Customer is required",
				message: "Please select a customer to generate the report",
			})
			return
		}

		generateReport({
			dateRange: {
				from: values.dateRange[0],
				to: values.dateRange[1],
			},
			customerId: Number(values.customerId),
		})
	}

	const totalSalesByFilial = useMemo(() => {
		return (
			data?.filials.map((filial) => ({
				filial: filial.name,
				totalSales: filial.totalAmount,
				color: generateRandomHexColor(),
			})) ?? []
		)
	}, [data])

	const totalOrdersByFilial = useMemo(() => {
		return (
			data?.filials.map((filial) => ({
				filial: filial.name,
				totalSales: filial.totalOrders,
				color: generateRandomHexColor(),
			})) ?? []
		)
	}, [data])

	return (
		<S.div gap="1rem" d="flex" flexDir="column">
			<S.h2>Sales Report By Customer</S.h2>
			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.stopPropagation()
						form.handleSubmit(handleSubmit)(e)
					}}
				>
					<S.div
						display="flex"
						gap="1rem"
						flexDir={{
							base: "column",
							md: "row",
						}}
					>
						<CustomersField />
						<C.M
							as={DatePickerInput}
							label="Date range"
							name="dateRange"
							type="range"
							onChange={(v) => {
								if (!v) return
								if (v[0] == null || v[1] == null) return
								setDateRange([v[0], v[1]])
							}}
							numberOfColumns={2}
							popoverProps={{
								zIndex: 9000,
							}}
							w={300}
							required
						/>
						<Button
							type="submit"
							isLoading={isLoading}
							className={css({
								marginTop: "1.25rem",
								paddingX: "3rem",
							})}
						>
							Generate
						</Button>
					</S.div>
				</form>
			</FormProvider>
			<S.hr />
			{data && !isLoading ? (
				<>
					<PieChartCard
						title="Total Sales by filial"
						text={`$${data?.totalAmount?.toFixed(2) ?? 0}`}
						total={data?.totalAmount ?? 0}
						data={
							totalSalesByFilial?.map((filial) => ({
								title: filial.filial,
								value: filial.totalSales,
								color: filial.color,
							})) ?? []
						}
						isMoney={true}
					/>
					<PieChartCard
						title="Total Orders by filial"
						text={`${data?.totalOrders ?? 0}`}
						total={data?.totalOrders ?? 0}
						data={
							totalOrdersByFilial?.map((filial) => ({
								title: filial.filial,
								value: filial.totalSales,
								color: filial.color,
							})) ?? []
						}
						isMoney={false}
					/>
				</>
			) : (
				<S.p fs="14px" color="neutral.700">
					Please select an customer and a date range to generate the report.
				</S.p>
			)}
		</S.div>
	)
}

const CustomersField = () => {
	const [query, setQuery] = useState<string>("")
	const { data } = trpc.user.search.useQuery(
		{
			query,
		},
		{
			enabled: query.length > 0,
			refetchOnWindowFocus: false,
		},
	)

	const customers = useMemo(
		() =>
			data?.map((c) => ({
				label: `${c.firstName} ${c.lastName ?? ""} (${c.email})`,
				value: c.id.toString(),
			})) ?? [],
		[data],
	)

	const customer = C.useController({
		name: "customerId",
		control: C.useFormContext().control,
	})

	return (
		<Select
			label="Customer"
			data={customers}
			searchable
			clearable
			entryId={(u: any) => u.value}
			entryLabel={(u: any) => u.label}
			onSearchChange={setQuery}
			onChange={(v: any) => {
				if (v) {
					customer.field.onChange(v.value)
				} else {
					customer.field.onChange(null)
				}
			}}
			className="w-1/4"
		/>
	)
}
