import { CrmScene } from "."
import BlogList from "./Blog/BlogList"
import CreatePost from "./Blog/CreatePost"
import UpdatePost from "./Blog/UpdatePost"
import { FormCreateFormPage } from "./form/create/index.page"
import { FormPage } from "./form/index.page"
import { FormEditFormPage } from "./form/edit/index.page"
import { HomePageBanners } from "./HomePageBanner"
import { CreateHomePageBanner } from "./HomePageBanner/Create"
import { UpdateHomePageBanner } from "./HomePageBanner/Update"
import { QuizScene } from "./quiz"
import { CreateQuizForm } from "./quiz/Create"
import UpdateQuiz from "./quiz/Update"
import { Roulette } from "./Roulette"
import { CreateRouletteForm } from "./Roulette/Create"
import { UpdateRouletteForm } from "./Roulette/Update"
import { FormSubmissionPage } from "./form/submission/index.page"
import { TimerModule } from "./Timer"
import { TutorialsScene } from "./Tutorials/index.scene"
import { CreateTutorialScene } from "./Tutorials/create-tutorial-scene/index.scene"
import { EditTutorialScene } from "./Tutorials/edit-tutorial-scene/index.scene"

export const crmRoutes = [
	{
		index: true,
		element: <CrmScene />,
		breadcrumb: "Ecommerce CRM",
	},
	{
		path: "blog",
		element: <BlogList />,
		breadcrumb: "Blog",
	},
	{
		path: "blog/create",
		element: <CreatePost />,
		breadcrumb: "create Post",
	},
	{
		path: "blog/edit/:id",
		element: <UpdatePost />,
		breadcrumb: "Update Post",
	},
	{
		path: "banner",
		element: <HomePageBanners />,
		breadcrumb: "Home page banner",
	},
	{
		path: "banner/create",
		element: <CreateHomePageBanner />,
		breadcrumb: "Create home page banner",
	},
	{
		path: "banner/edit/:id",
		element: <UpdateHomePageBanner />,
		breadcrumb: "Edit home page banner",
	},
	{
		path: "timer",
		element: <TimerModule />,
		breadcrumb: "Timer",
	},
	{
		path: "quiz",
		element: <QuizScene />,
		breadcrumb: "Quiz",
	},
	{
		path: "quiz/create",
		element: <CreateQuizForm />,
		breadcrumb: "Create Quiz",
	},
	{
		path: "quiz/edit/:id",
		element: <UpdateQuiz />,
		breadcrumb: "Edit Quiz",
	},
	{
		path: "roulette",
		element: <Roulette />,
		breadcrumb: "Roulette",
	},
	{
		path: "roulette/create",
		element: <CreateRouletteForm />,
		breadcrumb: "Create Roulette",
	},
	{
		path: "roulette/edit/:id",
		element: <UpdateRouletteForm />,
		breadcrumb: "Edit Roulette",
	},
	{
		path: "forms",
		element: <FormPage />,
		breadcrumb: "Forms",
	},
	{
		path: "forms/create",
		element: <FormCreateFormPage />,
		breadcrumb: "Create",
	},
	{
		path: "forms/edit/:uuid",
		element: <FormEditFormPage />,
		breadcrumb: "Update",
	},
	{
		path: "forms/submissions/:uuid",
		element: <FormSubmissionPage />,
		breadcrumb: "Submissions",
	},
	{
		path: "tutorials",
		element: <TutorialsScene />,
		breadcrumb: "Tutorials",
	},
	{
		path: "tutorials/create-tutorial/:id",
		element: <CreateTutorialScene />,
		breadcrumb: "Create Tutorial",
	},
	{
		path: "tutorials/edit-tutorial/:id",
		element: <EditTutorialScene />,
		breadcrumb: "Edit Tutorial",
	},
]
