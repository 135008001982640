import { applyDiscountV2 } from "../../util/index.js";
import {
	cartEntriesAtom,
	cartEntriesReturnedAtom,
} from "./cart-entries.js"
import { customerAtom } from "./customer.js";
import {
  cardPaymentMethodsAtom,
  cashPaymentMethodAtom,
  giftPaymentMethodAtom,
  userCreditsPaymentMethodAtom,
} from "./payment-methods.js";
import { Decimal } from "decimal.js";
import { atom } from "jotai/vanilla"
import { seasonalDiscountAtom } from "./discounts.js";
import { filialAtom } from "./filial.js";

export const filialTaxAtom = atom<Decimal>(new Decimal("0.07"));

// Tax in decimal form
// For now, the default value is from Florida (7%)
export const taxAtom = atom((get) => {
	const filialTax = get(filialTaxAtom)

	return {
		apply: (value: Decimal) => value.times(filialTax.plus(1)),
		calculate: (value: Decimal) => value.times(filialTax),
		value: filialTax,
		displayValue: filialTax.times(100).toString() + '%',
	}
})

export const totalPaidAtom = atom((get) => {
  const cashPayment = get(cashPaymentMethodAtom);
  const cardPayments = get(cardPaymentMethodsAtom);
  const userCreditsPayment = get(userCreditsPaymentMethodAtom);
  let totalPaid = userCreditsPayment.plus(cashPayment);
  for (const cardPayment of cardPayments) {
    totalPaid = totalPaid.plus(cardPayment.paidIn.sub(cardPayment.paidOut));
  }

  return totalPaid;
});

export const subtotalAtom = atom((get) => {
  const cartEntries = get(cartEntriesAtom)
  const cartEntriesReturned = get(cartEntriesReturnedAtom);
  const filial = get(filialAtom);

  let subtotal = new Decimal(0);
  for (const entry of cartEntries) {
    subtotal = subtotal.plus(
      applyDiscountV2(
        new Decimal(entry.quantity).times(
          filial?.type === "WAREHOUSE"
            ? entry.itemWithVariant.itemSku.cost
            : entry.itemWithVariant.itemSku.price,
        ),
        entry.discount,
      ),
    );
  }
  for (const entry of cartEntriesReturned) {
    subtotal = subtotal.sub(
      applyDiscountV2(
        new Decimal(entry.quantity).times(entry.itemWithVariant.itemSku.price),
        entry.discount,
      ),
    );
  }
  return subtotal;
});

subtotalAtom.debugLabel = "subtotalAtom"

export const subtotalTaxAtom = atom((get) => {
  const subtotal = get(subtotalAtom);

  return new Decimal(
    get(taxAtom)
      .calculate(subtotal)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN),
  );
});

export const cartTotalAtom = atom((get) => {
  const subtotal = get(subtotalAtom)
		let total = subtotal.sub(get(saleDiscountAtom))
		const seasonalDiscount = get(seasonalDiscountAtom)

		if (seasonalDiscount != null) {
			if (seasonalDiscount.discount.type === 'ORDER_SUBTOTAL_AMOUNT') {
				total = total.sub(seasonalDiscount.discount.amount)
			} else if (
				seasonalDiscount.discount.type === 'ORDER_SUBTOTAL_PERCENTAGE'
			) {
				total = total.times(
					new Decimal(1).sub(
						seasonalDiscount.discount.amount.div(100),
					),
				)
			}
		}

		const isTaxable = !!get(customerAtom)?.taxable
		const tax = get(taxAtom)
		if (isTaxable && total.gte(0)) {
			total = total.plus(tax.calculate(total))
		}
		return new Decimal(total.toDecimalPlaces(2, Decimal.ROUND_HALF_DOWN))
});

cartTotalAtom.debugLabel = "cartTotalAtom"

export const saleDiscountAtom = atom((get) => {
  const subtotal = get(subtotalAtom);
  const gift = get(giftPaymentMethodAtom);

  if (gift.type === "Fixed") {
    return gift.amount;
  } else {
    return subtotal.times(gift.amount.div("100"));
  }
});

export const orderTotalAtom = atom((get) => {
  const subtotal = get(cartTotalAtom);
  let total = new Decimal(subtotal)

  total = total.plus(get(shippingCostAtom));

  return total;
});

orderTotalAtom.debugLabel = "orderTotalAtom"

export const amountDueAtom = atom((get) => {
  const total = get(orderTotalAtom);
  const totalPaid = get(totalPaidAtom);
  const amountDue = total.sub(totalPaid);

  return amountDue.gt(0) ? amountDue : new Decimal(0);
});

export const cashPaidOutAtom = atom((get) => {
  const total = get(orderTotalAtom);
  const totalPaid = get(totalPaidAtom);
  const cashPaidOut = totalPaid.sub(total);
  return cashPaidOut.gt(0) ? cashPaidOut : new Decimal(0);
});

export const shippingCostAtom = atom(new Decimal(0));
