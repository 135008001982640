import { S } from "#/s"
import type { RouterOutputs } from "#/trpc"
import { ActionIcon } from "@mantine/core"
import CheckIcon from "~icons/ic/baseline-check"
import CloseIcon from "~icons/ic/baseline-close"
import { openVerifyItemManuallyModal } from "./verify-item-manually-modal"
import { PencilCustomIcon } from "#/components-ng"
import { useMemo } from "react"
import { DisplayTable } from "#/components"
import { useAtomValue } from "jotai/react"
import { lastEntryAddedIndexAtom } from "./state"
import { Box } from "#/css/jsx"

type OrderItemSku =
	RouterOutputs["v2_5"]["order"]["getOrderItemSkus"]["orderItemSku"][number]

interface VerifiedItemsPendingCartsProps {
	orderItemSkus: OrderItemSku[]
}

export const VerifiedItemsPendingCartsTable = (
	props: VerifiedItemsPendingCartsProps,
) => {
	const lastEntryAdded = useAtomValue(lastEntryAddedIndexAtom)

	const orderItemSkusWithoutVerified = useMemo(
		() =>
			props.orderItemSkus?.filter((o) => {
				const totalQuantityReturned = o.refundOrderItemSku.reduce(
					(acc, curr) => acc + curr.quantity,
					0,
				)
				const quantity = o.quantity - totalQuantityReturned

				return (o?.verifiedQuantity ?? 0) !== quantity
			}) ?? [],
		[props.orderItemSkus],
	)

	const orderItemSkusVerified = useMemo(
		() =>
			props.orderItemSkus?.filter((o) => {
				const totalQuantityReturned = o.refundOrderItemSku.reduce(
					(acc, curr) => acc + curr.quantity,
					0,
				)
				const quantity = o.quantity - totalQuantityReturned

				return (o?.verifiedQuantity ?? 0) === quantity
			}) ?? [],
		[props.orderItemSkus],
	)

	const lastEntryUnverifiedAddedIndex = useMemo(() => {
		if (lastEntryAdded.sku !== -1) {
			const index = orderItemSkusWithoutVerified.findIndex(
				(c) => c.itemSku.sku === lastEntryAdded.sku,
			)
			return index
		}
		return -1
	}, [lastEntryAdded, orderItemSkusWithoutVerified])

	const lastEntryVerifiedAddedIndex = useMemo(() => {
		if (lastEntryAdded.sku !== -1) {
			const index = orderItemSkusVerified.findIndex(
				(c) => c.itemSku.sku === lastEntryAdded.sku,
			)
			return index
		}
		return -1
	}, [lastEntryAdded, orderItemSkusVerified])

	return (
		<>
			<S.p c="neutral.700" mt={4} fw="500" fs="md">
				Items unverified
			</S.p>
			<Box mt={4} overflowY="auto">
				<DisplayTable
					columns={columns}
					data={orderItemSkusWithoutVerified}
					highlightedRow={{
						index: lastEntryUnverifiedAddedIndex,
					}}
					pagination={false}
				/>
			</Box>
			<S.p c="neutral.700" mt={4} fw="500" fs="md">
				Items verified:
			</S.p>
			<Box mt={4} overflowY="auto">
				<DisplayTable
					columns={columns}
					data={orderItemSkusVerified}
					pagination={false}
					highlightedRow={{
						index: lastEntryVerifiedAddedIndex,
					}}
				/>
			</Box>
		</>
	)
}

const columns = [
	{
		Header: "Sku",
		accessor: "itemSku.sku",
	},
	{
		Header: "Title",
		accessor: "itemSku.title",
	},
	{
		Header: "Quantity",
		accessor: "quantity",
	},
	{
		Header: "Verified quantity",
		enableSorting: false,
		Cell: ({ row: { original } }) => {
			const totalQuantityReturned = original?.refundOrderItemSku.reduce(
				(acc, curr) => acc + curr.quantity,
				0,
			)
			const quantity = original?.quantity - totalQuantityReturned
			return (
				<S.div
					d="flex"
					flexDir="column"
					gap="0.5"
					alignItems="center"
					justifyContent="center"
				>
					<S.p c="neutral.700">
						{original?.verifiedQuantity ?? 0}/{quantity}
					</S.p>
					{totalQuantityReturned > 0 && (
						<S.p fs="12px" c="red.600">
							Items returned: {totalQuantityReturned}
						</S.p>
					)}
				</S.div>
			)
		},
	},
	{
		Header: "Verified",
		Cell: ({ row: { original } }) => {
			const totalQuantityReturned = original?.refundOrderItemSku.reduce(
				(acc, curr) => acc + curr.quantity,
				0,
			)
			const quantity = original?.quantity - totalQuantityReturned

			const isVerified = (original?.verifiedQuantity ?? 0) === quantity
			return (
				<S.div color={isVerified ? "lime.800" : "red.700"}>
					{isVerified ? <CheckIcon /> : <CloseIcon />}
				</S.div>
			)
		},
	},
	{
		Header: "Verify manually",
		Cell: ({ row: { original } }) => {
			return (
				<S.div d="flex" alignItems="center">
					<ActionIcon
						size="sm"
						variant="subtle"
						onClick={() =>
							openVerifyItemManuallyModal({
								orderItemSku: original,
							})
						}
					>
						<PencilCustomIcon />
					</ActionIcon>
				</S.div>
			)
		},
	},
]
