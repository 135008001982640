import { ExtractProps } from "#/util/types"
import Decimal from "decimal.js"

export interface ProductLabelInfo {
	title: string
	sku?: number | null
	price: Decimal
	presentationValue?: number | null
	presentationType?: string | null
	productLocationStore?: string | null
	unitPrice: Decimal
	priceTag: "priceTag" | "withoutPriceTag" | "shippingTag"
	showQuantity?: boolean | null
	qty?: number | null
}

export function footerLeft(
	title: string,
	locationStore?: string | null,
	qty?: number | null,
	showQuantity?: boolean | null,
) {
	if (title.length < 17) {
		return `
          ^FB233,,,C^FO0,142^FD${title}\\&^FS
          ^FB233,,,C^FO0,167^FD${locationStore ?? ""}\\&^FS
		  ${showQuantity ? `^FB233,,,C^FO0,190^FDQty: x${qty ?? 0}^FS` : ""}
        `
	} else {
		return `
          ^FB233,,,C^FO0,142^FD${title.substring(0, 17)}\\&^FS
          ^FB233,,,C^FO0,160^FD${title.substring(17, 34)}\\&^FS
          ^FB233,,,C^FO0,186^FD${locationStore ?? ""}\\&^FS
		  ${showQuantity ? `^FB233,,,C^FO0,210^FDQty: x${qty ?? 0}^FS` : ""}
        `
	}
}

export function footerRight(
	title: string,
	locationStore?: string | null,
	qty?: number | null,
	showQuantity?: boolean | null,
) {
	if (title.length < 17) {
		return `
          ^FB700,,,C^FO0,142^FD${title}\\&^FS
          ^FB700,,,C^FO0,167^FD${locationStore ?? ""}\\&^FS
		  ${showQuantity ? `^FB700,,,C^FO0,190^FDQty: x${qty ?? 0}^FS` : ""}
        `
	} else {
		return `
          ^FB700,,,C^FO0,142^FD${title.substring(0, 17)}\\&^FS
          ^FB700,,,C^FO0,160^FD${title.substring(17, 34)}\\&^FS
          ^FB700,,,C^FO0,186^FD${locationStore ?? ""}\\&^FS
		  ${showQuantity ? `^FB700,,,C^FO0,210^FDQty: x${qty ?? 0}^FS` : ""}
        `
	}
}

export function getPrintTemplate(product0: ProductLabelInfo): string {
	return `^XA
      ^FX Price and size
      ^CFA,15
      ^FO15,10^FD${product0?.priceTag === "priceTag" ? "$" : ""}${
				product0?.priceTag !== "shippingTag"
					? product0?.unitPrice && product0.unitPrice.greaterThan(0)
						? product0.unitPrice.toNumber().toFixed(2)
						: product0.price
								.dividedBy(product0?.presentationValue ?? 1)
								.toNumber()
								.toFixed(2)
					: ""
			}^FS
      ^FO218,10,1^FD${`${product0.presentationValue ?? ""} ${
				product0.presentationType ?? ""
			}`}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB233,,,C^FO0,30^FD${product0.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO25,55^BC,,N^FD${product0.sku}^FS
      
      ^FX Product name
      ^CFA,15
      ^FB233,,,C^FO0,115^FD${product0?.priceTag !== "shippingTag" ? `MSRP XDR${product0.price.toNumber().toFixed(2)}` : ""}\\&^FS
      ^CFL,20
      ${footerLeft(product0.title, product0?.productLocationStore ?? null, product0?.qty, product0?.showQuantity)}
      
      ^XZ`
}

export function getPrintTemplate2(
	product0: ProductLabelInfo,
	product1: ProductLabelInfo,
): string {
	return `^XA
      ^FX Price and size
      ^CFA,15
      ^FO15,10^FD${product0?.priceTag === "priceTag" ? "$" : ""}${
				product0?.priceTag !== "shippingTag"
					? product0?.unitPrice && product0.unitPrice.greaterThan(0)
						? product0.unitPrice.toNumber().toFixed(2)
						: product0.price
								.dividedBy(product0?.presentationValue ?? 1)
								.toNumber()
								.toFixed(2)
					: ""
			}^FS
      ^FO218,10,1^FD${`${product0.presentationValue ?? ""} ${
				product0.presentationType ?? ""
			}`}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB233,,,C^FO0,30^FD${product0.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO25,55^BC,,N^FD${product0.sku}^FS
      
      ^FX Product name
      ^CFA,15
      ^FB233,,,C^FO0,115^FD${product0?.priceTag !== "shippingTag" ? `MSRP XDR${product0.price.toNumber().toFixed(2)}` : ""}\\&^FS
      ^CFL,20
      ${footerLeft(product0.title, product0?.productLocationStore ?? "", product0?.qty, product0?.showQuantity)}

      ^FX Price and size
      ^CFA,15
      ^FO248,10^FD${product1?.priceTag === "priceTag" ? "$" : ""}${
				product1?.priceTag !== "shippingTag"
					? product1?.unitPrice && product1.unitPrice.greaterThan(0)
						? product1.unitPrice.toNumber().toFixed(2)
						: product1.price
								.dividedBy(product1?.presentationValue ?? 1)
								.toNumber()
								.toFixed(2)
					: ""
			}^FS
      ^FO451,10,1^FD${`${product0.presentationValue ?? ""} ${
				product0.presentationType ?? ""
			}`}^FS
      
      ^FX SKU
      ^CF0,20
      ^FB700,,,C^FO0,30^FD${product1.sku}\\&^FS
      
      ^FX Barcode
      ^BY2,2,50
      ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
      ^FO258,55^BC,,N^FD${product1.sku}^FS
      
      ^FX Product name
      ^CFA,15
      ^FB700,,,C^FO0,115^FD${product1?.priceTag !== "shippingTag" ? `MSRP XDR${product1.price.toNumber().toFixed(2)}` : ""}\\&^FS
      ^CFL,20
      ${footerRight(product1.title, product1?.productLocationStore ?? null, product1?.qty, product1?.showQuantity)}
      
      ^XZ`
}

export class NewZplLabelFormat {
	topLineFontName!: string
	topLineFontSize!: number
	topLineOffset!: number
	skuFontName!: string
	skuFontSize!: number
	skuOffset!: number
	barcodeHeight!: number
	barcodeOffset!: number
	NONE!: number
	msrpFontName!: string
	msrpFontSize!: number
	msrpOffset!: number
	titleFontName!: string
	titleFontSize!: number
	titleOffset!: number
	locationFontName!: string
	locationFontSize!: number
	locationRelativeOffset!: number
	qtyFontName!: string
	qtyFontSize!: number
	qtyOffset!: number

	static DEFAULT_VALUES: ExtractProps<NewZplLabelFormat> = {
		topLineFontName: "A",
		topLineFontSize: 15,
		topLineOffset: 25,
		skuFontName: "0",
		skuFontSize: 20,
		skuOffset: 55,
		barcodeHeight: 40,
		barcodeOffset: 80,
		NONE: 0,
		msrpFontName: "A",
		msrpFontSize: 15,
		msrpOffset: 135,
		titleFontName: "L",
		titleFontSize: 20,
		titleOffset: 160,
		locationFontName: "L",
		locationFontSize: 20,
		locationRelativeOffset: 25,
		qtyFontName: "L",
		qtyFontSize: 20,
		qtyOffset: 25,
	}

	constructor(props: ExtractProps<NewZplLabelFormat>) {
		Object.assign(this, props)
	}

	footerLeft(
		title: string,
		locationStore?: string | null,
		qty?: number | null,
		showQuantity?: boolean | null,
	) {
		if (title.length < 17) {
			return `
            ^CF${this.titleFontName},${this.titleFontSize}
            ^FB233,,,C^FO0,${this.titleOffset}^FD${title}\\&^FS
            ${showQuantity ? "" : `^CF${this.locationFontName},${this.locationFontSize}`}
            ${showQuantity ? "" : `^FB233,,,C^FO0,${this.titleOffset + this.locationRelativeOffset}^FD${locationStore ?? ""}`}\\&^FS
			      ${showQuantity ? `^CF${this.qtyFontName},${this.qtyFontSize}` : ""}
			      ${showQuantity ? `^FB233,,,C^FO0,${this.qtyOffset + this.titleOffset}^FDQty: x${qty ?? 0}^FS` : ""}\\&^FS
          `
		} else {
			return `
            ^CF${this.titleFontName},${this.titleFontSize}
            ^FB233,,,C^FO0,${this.titleOffset}^FD${title.substring(0, 17)}\\&^FS
            ^FB233,,,C^FO0,${this.titleOffset + 18}^FD${title.substring(17, 34)}\\&^FS
            ${showQuantity ? "" : `^CF${this.locationFontName},${this.locationFontSize}`}
            ${showQuantity ? "" : `^FB233,,,C^FO0,${this.titleOffset + 18 + this.locationRelativeOffset}^FD${locationStore ?? ""}`}\\&^FS
			      ${showQuantity ? `^CF${this.qtyFontName},${this.qtyFontSize}` : ""}
			      ${showQuantity ? `^FB233,,,C^FO0,${this.titleOffset + 18 + this.qtyOffset}^FDQty: x${qty ?? 0}^FS` : ""}\\&^FS
          `
		}
	}

	footerRight(
		title: string,
		locationStore?: string | null,
		qty?: number | null,
		showQuantity?: boolean | null,
	) {
		if (title.length < 17) {
			return `
            ^CF${this.titleFontName},${this.titleFontSize}
            ^FB700,,,C^FO0,${this.titleOffset}^FD${title}\\&^FS
            ${showQuantity ? "" : `^CF${this.locationFontName},${this.locationFontSize}`}
            ${showQuantity ? "" : `^FB700,,,C^FO0,${this.titleOffset + this.locationRelativeOffset}^FD${locationStore ?? ""}`}\\&^FS
			      ${showQuantity ? `^CF${this.qtyFontName},${this.qtyFontSize}` : ""}
			      ${showQuantity ? `^FB700,,,C^FO0,${this.qtyOffset + this.titleOffset}^FDQty: x${qty ?? 0}^FS` : ""}\\&^FS
          `
		} else {
			return `
            ^CF${this.titleFontName},${this.titleFontSize}
            ^FB700,,,C^FO0,${this.titleOffset}^FD${title.substring(0, 17)}\\&^FS
            ^FB700,,,C^FO0,${this.titleOffset + 18}^FD${title.substring(17, 34)}\\&^FS
            ${showQuantity ? "" : `^CF${this.locationFontName},${this.locationFontSize}`}
            ${showQuantity ? "" : `^FB700,,,C^FO0,${this.titleOffset + 18 + this.locationRelativeOffset}^FD${locationStore ?? ""}`}\\&^FS
            ${showQuantity ? `^CF${this.qtyFontName},${this.qtyFontSize}` : ""}
			      ${showQuantity ? `^FB700,,,C^FO0,${this.titleOffset + 18 + this.qtyOffset}^FDQty: x${qty ?? 0}^FS` : ""}\\&^
          `
		}
	}

	getPrintTemplate(product0: ProductLabelInfo): string {
		return `^XA
        ^FX Price and size
        ^CF${this.topLineFontName},${this.topLineFontSize}
        ^FO15,${this.topLineOffset}^FD${product0?.priceTag === "priceTag" ? "$" : ""}${
					product0?.priceTag !== "shippingTag"
						? product0?.unitPrice && product0.unitPrice.greaterThan(0)
							? product0.unitPrice.toNumber().toFixed(2)
							: product0.price
									.dividedBy(product0?.presentationValue ?? 1)
									.toNumber()
									.toFixed(2)
						: ""
				}^FS
        ^FO218,${this.topLineOffset},1^FD${`${product0.presentationValue ?? ""} ${
					product0.presentationType ?? ""
				}`}^FS
        
        ^FX SKU
        ^CF${this.skuFontName},${this.skuFontSize}
        ^FB233,,,C^FO0,${this.skuOffset}^FD${product0.sku}\\&^FS
        
        ^FX Barcode
        ^BY2,2,${this.barcodeHeight}
        ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
        ^FO25,${this.barcodeOffset}^BC,,N^FD${product0.sku}^FS
        
        ^FX Product name
        ^CF${this.msrpFontName},${this.msrpFontSize}
        ^FB233,,,C^FO0,${this.msrpOffset}^FD${product0?.priceTag !== "shippingTag" ? `MSRP XDR${product0.price.toNumber().toFixed(2)}` : ""}\\&^FS
        ${this.footerLeft(product0.title, product0?.productLocationStore ?? "", product0?.qty, product0?.showQuantity)}
        
        ^XZ`
	}

	getPrintTemplate2(
		product0: ProductLabelInfo,
		product1: ProductLabelInfo,
	): string {
		return `^XA
        ^FX Price and size
        ^CF${this.topLineFontName},${this.topLineFontSize}
        ^FO15,${this.topLineOffset}^FD${product0?.priceTag === "priceTag" ? "$" : ""}${
					product0?.priceTag !== "shippingTag"
						? product0?.unitPrice && product0.unitPrice.greaterThan(0)
							? product0.unitPrice.toNumber().toFixed(2)
							: product0.price
									.dividedBy(product0?.presentationValue ?? 1)
									.toNumber()
									.toFixed(2)
						: ""
				}^FS
        ^FO218,${this.topLineOffset},1^FD${`${product0.presentationValue ?? ""} ${
					product0.presentationType ?? ""
				}`}^FS
        
        ^FX SKU
        ^CF${this.skuFontName},${this.skuFontSize}
        ^FB233,,,C^FO0,${this.skuOffset}^FD${product0.sku}\\&^FS
        
        ^FX Barcode
        ^BY2,2,${this.barcodeHeight}
        ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
        ^FO25,${this.barcodeOffset}^BC,,N^FD${product0.sku}^FS
        
        ^FX Product name
        ^CF${this.msrpFontName},${this.msrpFontSize}
        ^FB233,,,C^FO0,${this.msrpOffset}^FD${product0?.priceTag !== "shippingTag" ? `MSRP XDR${product0.price.toNumber().toFixed(2)}` : ""}\\&^FS
        ${this.footerLeft(product0.title, product0?.productLocationStore ?? "", product0?.qty, product0?.showQuantity)}
  
        ^FX Price and size
        ^CF${this.topLineFontName},${this.topLineFontSize}
        ^FO248,${this.topLineOffset}^FD${product1?.priceTag === "priceTag" ? "$" : ""}${
					product1?.priceTag !== "shippingTag"
						? product1?.unitPrice && product1.unitPrice.greaterThan(0)
							? product1.unitPrice.toNumber().toFixed(2)
							: product1.price
									.dividedBy(product1?.presentationValue ?? 1)
									.toNumber()
									.toFixed(2)
						: ""
				}^FS
        ^FO451,${this.topLineOffset},1^FD${`${product0.presentationValue ?? ""} ${
					product0.presentationType ?? ""
				}`}^FS
        
        ^FX SKU
        ^CF${this.skuFontName},${this.skuFontSize}
        ^FB700,,,C^FO0,30^FD${product1.sku}\\&^FS
        
        ^FX Barcode
        ^BY2,2,${this.barcodeHeight}
        ^FX ---- Change BC,130,N to BC,130,Y to print the code number ----
        ^FO258,${this.barcodeOffset}^BC,,N^FD${product1.sku}^FS
        
        ^FX Product name
        ^CF${this.msrpFontName},${this.msrpFontSize}
        ^FB700,,,C^FO0,${this.msrpOffset}^FD${product1?.priceTag !== "shippingTag" ? `MSRP XDR${product1.price.toNumber().toFixed(2)}` : ""}\\&^FS
        ${this.footerRight(product1.title, product1?.productLocationStore ?? null, product1?.qty, product1?.showQuantity)}
        
        ^XZ`
	}
}
