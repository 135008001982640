import { DisplayTable } from "#/components";
import { cn } from "#/lib/utils";
import { RouterOutputs } from "#/trpc";
import { selectedOrderAtom } from "./state";
import { ViewIcon } from "@chakra-ui/icons";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import dayjs from "dayjs";

interface OrdersTableContentProps {
  orders: NonNullable<RouterOutputs["order"]["getAllByCustomerId"]> | undefined;
  isLoading: boolean;
}

type OrderStatus =
  RouterOutputs["order"]["getAllByCustomerId"][number]["orderStatus"];

export function OrdersTable({ orders, isLoading }: OrdersTableContentProps) {
  const rows = useMemo(
    () =>
      orders?.map((order) => ({
        ...order,
        customerFullName: `${order?.customer?.firstName ?? ""} ${
          order?.customer?.lastName ?? ""
        }`,
        associatedFullName: `${order?.associated?.firstName ?? ""} ${
          order?.associated?.lastName ?? ""
        }`,
      })) ?? [],
    [orders]
  );

  return (
    <>
      <DisplayTable
        isLoading={isLoading}
        data={rows ?? []}
        columns={orderListColumns}
        pagination={!!orders?.length}
      />

      {!orders?.length && (
        <C.Center>
          <M.Text mt={4} color="gray.500">
            No order history yet
          </M.Text>
        </C.Center>
      )}

      {isLoading && (
        <C.Center p={4}>
          <C.Spinner />
        </C.Center>
      )}
    </>
  );
}

const orderListColumns = [
  {
    id: "actions",
    Header: "Actions",
    accessor: "id",
    Cell: ({ row: { original } }) => {
      const setSelectedOrder = useSetAtom(selectedOrderAtom);

      return (
        <M.Flex justify={"center"}>
          <C.IconButton
            aria-label="open"
            icon={<ViewIcon />}
            colorScheme="gray"
            onClick={() => setSelectedOrder(original)}
          />
        </M.Flex>
      );
    },
  },
  {
    Header: "Guide",
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text className="text-center">{original.receiptNumber}</M.Text>
        </M.Box>
      );
    },
  },
  {
    Header: "Customer",
    accessor: "customerFullName",
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text className="text-center">{original.customerFullName}</M.Text>
        </M.Box>
      );
    },
  },
  {
    Header: "Associated",
    accessor: "associatedFullName",
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text className="text-center">{original.associatedFullName}</M.Text>
        </M.Box>
      );
    },
  },
  {
    Header: "Status",
    accessor: "orderStatus",
    Cell: ({ row: { original } }) => {
      return (
        <M.Flex justify={"center"}>
          <StatusBadge status={original.orderStatus} cnRoot="text-sm" />
        </M.Flex>
      );
    },
  },
  {
    Header: "Total",
    accessor: "total",
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text className="text-center">
            ${original.total.toNumber().toFixed(2)}
          </M.Text>
        </M.Box>
      );
    },
  },
  {
    Header: "Date",
    accesor: "createdAt",
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text className="text-center">
            {dayjs(original.createdAt).format("MM/DD/YYYY")}
          </M.Text>
        </M.Box>
      );
    },
  },
  {
    Header: "Origin",
    accesor: "orderType",
    Cell: ({ row: { original } }) => {
      return (
        <M.Box>
          <M.Text className="text-center">
            {original.orderType !== "IN_STORE" && original.orderType !== "PICKUP_FROM_STORE" ? "E-commerce" : "POS"}
          </M.Text>
        </M.Box>
      );
    
    }
  }
];

interface StatusBadgeProps {
  status: OrderStatus;
  cnRoot?: string;
}

function StatusBadge(props: StatusBadgeProps) {
  const colors: Record<OrderStatus, string> = {
    COMPLETE: "bg-green-50 text-green-600",
    REVERSED: "bg-red-100 text-red-600",
    FAILED: "bg-orange-100 text-orange-600",
    CANCELLED: "bg-slate-100 text-slate-600",
    PENDING: "bg-yellow-100 text-yellow-600",
    PROCESSING: "bg-yellow-100 text-yellow-600",
    SHIPPED: "bg-blue-100 text-blue-600",
    READY_TO_PICKUP: "bg-purple-100 text-purple-600",
  };
  return (
    <span
      className={cn(
        "inline-block rounded-full px-3 py-[0.4em]",
        colors[props.status],
        props.cnRoot
      )}
    >
      {props.status}
    </span>
  );
}
