import {
	ImageUpload,
	UseS3UploadObject,
	UseS3UploadResult,
} from "#/components-ng/ui"
import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { CatalogImageFormValues } from "./types"
import { v4 as uuidv4 } from "uuid"
import { Loader } from "@mantine/core"
import { CatalogImageFormModal } from "./catalog-image-form-modal"

interface CreateCatalogImageInput {
	catalogId: number
	textButtonDelete: string
}

interface ImageUploadProps {
	url: string
	uid: string
	isLoading: boolean
}

export function CreateCatalogImageInput(props: CreateCatalogImageInput) {
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [image, setImage] = useState<ImageUploadProps | null>(null)
	const ctx = trpc.useContext()

	const {
		data: catalogImage,
		mutate: createCatalogImage,
		isLoading: isLoadingCreateCatalogImage,
	} = trpc.v2_5.catalog.createCatalogImage.useMutation({
		onError(error) {
			reportUserError({
				title: "Error creating catalog image",
				message: error.message,
			})
		},
		onSuccess() {
			setIsLoading(false)
			setOpenModal(true)
			ctx.v2_5.catalog.invalidate()
			reportUserSuccess({
				title: "Catalog image created",
				message: "Catalog image has been created successfully",
			})
		},
	})

	const { mutate: createTags, isLoading: isLoadingCreateTags } =
		trpc.v2_5.catalog.createCatalogImagesTags.useMutation({
			onError(error) {
				reportUserError({
					title: "Error creating catalog image tags",
					message: error.message,
				})
			},
			onSuccess() {
				setOpenModal(false)
				setImage(null)
				ctx.v2_5.catalog.invalidate()
				reportUserSuccess({
					title: "Catalog image tags created",
					message: "Catalog image tags have been created successfully",
				})
			},
		})

	const handleSubmit = (imageUrl?: string | null) => {
		if (!imageUrl) {
			reportUserError({
				title: "Error creating catalog image",
				message: "Image is required",
			})
			return
		}

		createCatalogImage({
			catalogId: props.catalogId,
			image: {
				url: imageUrl,
			},
		})
	}

	const handleSubmitTags = (values: CatalogImageFormValues) => {
		if (!values.image || !values.image.url) {
			reportUserError({
				title: "Error creating catalog image tags",
				message: "Image is required",
			})
			return
		}

		if (!catalogImage) {
			reportUserError({
				title: "Error creating catalog image tags",
				message: "Catalog image is required",
			})
			return
		}
		createTags({
			catalogItemImageId: catalogImage.id,
			tags: values.image.tags.map((tag) => ({
				title: tag.title,
				url: tag.url,
				x: tag.x,
				y: tag.y,
				itemSkuId: tag.itemSkuId,
			})),
		})
	}

	const handleS3UploadImageStart = (upload: UseS3UploadObject) => {
		setIsLoading(true)
		setImage({
			url: upload.url!,
			uid: uuidv4(),
			isLoading: true,
		})
	}

	const handleS3UploadImageSuccess = (upload: UseS3UploadResult) => {
		setImage({
			url: upload.url!,
			uid: uuidv4(),
			isLoading: false,
		})

		handleSubmit(upload.url!)
	}

	return (
		<>
			{isLoadingCreateCatalogImage || isLoading ? (
				<Loader />
			) : (
				<ImageUpload
					directory="catalog-images-dev"
					onS3UploadingStart={handleS3UploadImageStart}
					onS3UploadSuccess={handleS3UploadImageSuccess}
					supports={{
						image: true,
					}}
				/>
			)}
			{openModal && (
				<CatalogImageFormModal
					opened={openModal}
					setOpened={setOpenModal}
					onSubmit={handleSubmitTags}
					isLoading={isLoadingCreateTags}
					defaultValues={{
						image: {
							...image,
							tags: [],
						},
					}}
					catalogItemImageId={catalogImage?.id}
					textButtonDelete={props.textButtonDelete}
				/>
			)}
		</>
	)
}
