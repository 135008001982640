import { css, S } from "#/s"
import { Button } from "@gt/ui"
import * as M from "@mantine/core"

type SurveySentModalProps = {
	backToAvailableSurveys: () => void
} & M.ModalProps

export function SurveySentModal(props: SurveySentModalProps) {
	return (
		<M.Modal
			title="Available Surveys"
			centered
			{...props}
			classNames={{
				title: css({
					fontWeight: "medium",
				}),
			}}
		>
			<S.div d="grid" gap="4">
				<S.p textAlign="center" c="#181D27" fw={600}>
					Survey sent!
				</S.p>
				<S.p c="#535862" fs="15px">
					The survey link has been successfully emailed to your customer. You
					can send another survey if needed.
				</S.p>
				<S.div d="flex" gap="4" w="100%">
					<Button
						className={css({
							flex: 1,
						})}
						variant="secondary"
						onClick={props.onClose}
					>
						Cancel
					</Button>
					<Button
						className={css({
							flex: 1,
						})}
						onClick={props.backToAvailableSurveys}
					>
						Send another survey
					</Button>
				</S.div>
			</S.div>
		</M.Modal>
	)
}
