import {
	BankTransferCustomIcon,
	CashPaymentMethodCustomIcon,
	CreditDebitPaymentMethodCustomIcon,
	DiscountPaymentMethodCustomIcon,
	OthersPaymentMethodCustomIcon,
	PaypalCustomIcon,
	UserCreditsPaymentMethodCustomIcon,
	ZelleCustomIcon,
} from "#/components-ng/index.js"
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from "#/components-ng/ui/index.js"
import {
	AmericanExpressIcon,
	DinersClubIcon,
	DiscoverIcon,
	MastercardIcon,
	VisaIcon,
} from "#/components/icons/index.jsx"
import { css } from "#/css/css"
import { Center } from "#/css/jsx"
import { cn } from "#/lib/utils.js"
import { reportUserError } from "#/util/index.js"
import { paymentMethodsMap } from "./payment-methods-map.js"
import { amountDueAtom } from "./state/cost-breakdown.js"
import { customerAtom } from "./state/index.js"
import {
	cardPaymentMethodAddAtom,
	cardPaymentMethodRemoveAtom,
	cardPaymentMethodsAtom,
	cashPaymentMethodAtom,
	giftPaymentMethodAtom,
	hasAnyNonTerminalPaymentMethodAtom,
	paymentMethodsErrorMessagesAtom,
	payWithTerminalMethodAtom,
	userCreditsPaymentMethodAtom,
} from "./state/payment-methods.js"
import * as M from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import Decimal from "decimal.js"
import { useAtom, useAtomValue, useSetAtom } from "jotai/react"
import React, { ReactNode } from "react"
import { PaymentType } from "server"
import { styled as s } from "#/css/jsx"
import { useTerminal } from "#/state/terminal.hook.js"
import { useSearchParams } from "react-router-dom"

export function PaymentMethods() {
	const [searchParams] = useSearchParams()
	return (
		<div className="grid gap-y-2">
			<div className="flex flex-wrap gap-2">
				<CashPaymentMethod />
				<CardPaymentMethod />
				<ApplyDiscountPaymentMethod />
				<UserCreditsPaymentMethod />
				<OtherPaymentMethods />
				{searchParams.get("terminal") != null && <PayWithTerminalMethod />}
			</div>
			<ErrorMessages />
		</div>
	)
}

function ErrorMessages() {
	const paymentMethodsErrorMessages = useAtomValue(
		paymentMethodsErrorMessagesAtom,
	)

	return (
		<div>
			<s.ul>
				{paymentMethodsErrorMessages.map((msg) => {
					return (
						<s.li key={msg} fs="sm">
							{msg}
						</s.li>
					)
				})}
			</s.ul>
		</div>
	)
}

interface PaymentMethodButtonProps {
	label?: string
	icon: React.ReactNode
	onOpen?: () => void
	className?: string
}

function PaymentMethodButton({
	label,
	icon,
	onOpen,
	className,
}: PaymentMethodButtonProps) {
	return (
		<M.Center
			onClick={onOpen}
			className={className}
			p="xl"
			sx={(t) => ({
				width: 116,
				height: 115,
				backgroundColor: t.colors.gray[0],
				cursor: "pointer",
			})}
		>
			<M.Stack align="center">
				{icon}
				<M.Text
					color="gray.7"
					weight={500}
					align="center"
					size="sm"
					className="whitespace-nowrap"
				>
					{label}
				</M.Text>
			</M.Stack>
		</M.Center>
	)
}

const ApplyDiscountPaymentMethod = () => {
	const [opened, handlers] = useDisclosure(false)
	const [type, setType] = React.useState<"Fixed" | "Percentage">("Fixed")
	const [amount, setAmount] = React.useState("0")
	const setGift = useSetAtom(giftPaymentMethodAtom)

	const handleSave = () => {
		try {
			setGift({
				type,
				amount: new Decimal(amount),
			})
		} catch (e) {
			reportUserError({
				title: "Failed to set gift payment method",
				message: e.message ?? e,
			})
		}
		handlers.close()
	}

	return (
		<>
			<PaymentMethodButton
				label="Discount"
				icon={<DiscountPaymentMethodCustomIcon />}
				onOpen={handlers.open}
				className="rounded hover:bg-red-50 hover:ring-1 hover:ring-red-200"
			/>
			<M.Modal opened={opened} onClose={handlers.close} withCloseButton={false}>
				<M.Title order={3}>Discount</M.Title>
				<M.Text color="gray.6" mt="sm">
					Apply a discount on the price of the items added to the transaction
				</M.Text>
				<M.Radio.Group
					value={type}
					mt="lg"
					onChange={(v) =>
						setType(v === "Fixed" || v === "Percentage" ? v : "Fixed")
					}
				>
					<M.Radio name="type" value="Fixed" label="Fixed" />
					<M.Radio name="type" value="Percentage" label="Percentage" />
				</M.Radio.Group>
				<M.Input
					value={amount}
					mt="md"
					onChange={(e) => setAmount(e.currentTarget.value)}
				/>
				<M.Group position="right" spacing="md" mt="md">
					<M.Button
						color="gray.1"
						onClick={handlers.close}
						sx={(t) => ({ color: t.colors.gray[8] })}
					>
						Cancel
					</M.Button>
					<M.Button onClick={handleSave}>Save</M.Button>
				</M.Group>
			</M.Modal>
		</>
	)
}

function CashPaymentMethod() {
	const [cashPaymentMethod, setCashPaymentMethod] = useAtom(
		cashPaymentMethodAtom,
	)
	const [opened, setOpened] = React.useState(false)

	const [amount, setAmount] = React.useState<string>("0")
	const amountDue = useAtomValue(amountDueAtom)

	const handleSubmit = React.useCallback(() => {
		try {
			const decimalAmount = new Decimal(amount)
			setCashPaymentMethod(decimalAmount)
		} catch (e) {
			reportUserError({
				title: "Failed to set cash payment method",
				message: e.message ?? e,
			})
		}
		setOpened(false)
	}, [amount, setCashPaymentMethod])

	React.useEffect(() => {
		setAmount(amountDue.plus(cashPaymentMethod).toString())
	}, [amountDue, cashPaymentMethod])

	return (
		<>
			<PaymentMethodButton
				label="Cash"
				icon={<CashPaymentMethodCustomIcon />}
				onOpen={() => setOpened(true)}
				className="rounded hover:bg-green-50 hover:ring-1 hover:ring-green-200"
			/>
			<M.Modal
				opened={opened}
				onClose={() => setOpened(false)}
				withCloseButton={false}
				size="auto"
			>
				<M.Stack>
					<M.Group spacing={6} position="apart" sx={{ width: "100%" }}>
						<M.Text>Amount</M.Text>
						<M.TextInput
							value={amount.toString()}
							onChange={(e) => {
								setAmount(e.currentTarget.value)
							}}
							sx={{ width: "100%" }}
						/>
					</M.Group>
					<M.Group sx={{ width: "100%" }}>
						{[1, 5, 10, 20].map((value, i) => (
							<M.Button
								key={i}
								px="md"
								py="sm"
								color="brand"
								variant="outline"
								onClick={() => {
									setAmount(value.toString())
								}}
								sx={{ height: "3rem", flex: 1 }}
							>
								<M.Text>${value}</M.Text>
							</M.Button>
						))}
					</M.Group>
					<M.Group position="right" sx={{ width: "100%" }}>
						<M.Group>
							<M.Button color="brand" onClick={handleSubmit}>
								Save
							</M.Button>
							<M.Button
								color="gray"
								variant="light"
								onClick={() => setOpened(false)}
							>
								Cancel
							</M.Button>
						</M.Group>
					</M.Group>
				</M.Stack>
			</M.Modal>
		</>
	)
}

function OtherPaymentMethods() {
	const paymentMethods = useAtomValue(cardPaymentMethodsAtom)
	const addCard = useSetAtom(cardPaymentMethodAddAtom)
	const removeCard = useSetAtom(cardPaymentMethodRemoveAtom)
	const amountDue = useAtomValue(amountDueAtom)
	const [amount, setAmount] = React.useState<string>(() => amountDue.toString())
	const [opened, setOpened] = React.useState(false)
	const [transactionType, setTransactionType] = React.useState("charge")
	const [cardSelected, setCardSelected] = React.useState<PaymentType | null>(
		null,
	)

	React.useEffect(() => {
		setAmount(amountDue.toString())
	}, [amountDue])

	const handleCardClick = React.useCallback(
		(type: PaymentType) => {
			try {
				addCard({
					type,
					paidIn:
						transactionType === "charge" ? new Decimal(amount) : new Decimal(0),
					paidOut:
						transactionType === "refund" ? new Decimal(amount) : new Decimal(0),
				})
			} catch (err) {
				reportUserError({
					title: "Failed to add card payment method",
					message: err.message,
				})
			}
		},
		[addCard, amount, transactionType],
	)

	const handleCardRemoveClick = React.useCallback(
		(index: number) => {
			removeCard(index)
		},
		[removeCard],
	)

	return (
		<>
			<Dialog open={opened} onOpenChange={setOpened}>
				<DialogTrigger>
					<PaymentMethodButton
						label="Others"
						icon={<OthersPaymentMethodCustomIcon />}
						className="rounded hover:bg-indigo-50 hover:ring-1 hover:ring-indigo-200"
					/>
				</DialogTrigger>
				<DialogContent className="w-auto">
					<DialogTitle>Other payment methods</DialogTitle>
					<M.Stack spacing={4} justify="space-between">
						{paymentMethods.length > 0 && (
							<M.Stack sx={{ width: "100%", maxHeight: 200, overflow: "auto" }}>
								{paymentMethods
									.slice()
									.reverse()
									.map((pm, reversedIndex) => {
										const i = paymentMethods.length - 1 - reversedIndex
										return (
											<M.Group
												key={i}
												spacing={12}
												position="apart"
												p={2}
												sx={{ width: "100%", background: "white" }}
											>
												<M.Group position="left">
													<M.Box
														sx={(t) => ({
															width: "3.85em",
															height: "2.5em",
															borderRadius: t.radius.md,
														})}
													>
														{React.createElement(
															paymentMethodsMap[pm.type].icon,
															{
																className: "w-16 h-auto",
															},
														)}
													</M.Box>
													<M.Text weight="bold">
														{paymentMethodsMap[pm.type].text}
													</M.Text>
												</M.Group>
												<M.Stack align="end" spacing={4}>
													<M.Text
														component="span"
														color={pm.paidOut.gt(0) ? "red" : "green"}
														size="sm"
													>
														{pm.paidOut.gt(0) ? "Refund" : "Charge"}{" "}
														<M.Text component="span" weight="bold" size="sm">
															$
															{(pm.paidOut.gt(0)
																? pm.paidOut
																: pm.paidIn
															)?.toFixed(2)}
														</M.Text>
													</M.Text>
													<M.Button
														size="xs"
														compact
														color="gray"
														variant="light"
														onClick={() => handleCardRemoveClick(i)}
													>
														Remove
													</M.Button>
												</M.Stack>
											</M.Group>
										)
									})}
							</M.Stack>
						)}
						<M.Stack>
							<M.Text color="gray" size="sm">
								Add another payment method
							</M.Text>
							<M.Group>
								<M.Radio.Group
									color="brand"
									size="sm"
									pb="xs"
									onChange={setTransactionType}
									value={transactionType}
								>
									<M.Radio value="charge" label="Charge" />
									<M.Radio value="refund" label="Refund" />
								</M.Radio.Group>
							</M.Group>
						</M.Stack>
						<M.Group sx={{ width: "100%" }}>
							<M.Text sx={{ minWidth: "10ch" }}>Amount</M.Text>
							<M.Input
								onChange={(e) => setAmount(e.currentTarget.value)}
								value={amount}
							/>
						</M.Group>
						<div className="my-2 flex flex-nowrap gap-x-4">
							<M.ActionIcon
								aria-label="PayPal"
								p={0}
								variant="transparent"
								onClick={() => setCardSelected("PAYPAL")}
								sx={(t) => ({
									width: "20%",
									minWidth: "auto",
									height: "auto",
									borderRadius: t.radius.md,
									outline:
										cardSelected === "PAYPAL"
											? `solid 2px ${t.colors.brand[4]} !important`
											: "none",
								})}
							>
								<PaypalCustomIcon className="h-auto w-auto" />
							</M.ActionIcon>

							<M.ActionIcon
								aria-label="Zelle"
								p={0}
								variant="transparent"
								onClick={() => setCardSelected("ZELLE")}
								sx={(t) => ({
									width: "20%",
									minWidth: "auto",
									height: "auto",
									borderRadius: t.radius.md,
									outline:
										cardSelected === "ZELLE"
											? `solid 2px ${t.colors.brand[4]} !important`
											: "none",
								})}
							>
								<ZelleCustomIcon className="h-auto w-auto" />
							</M.ActionIcon>

							<M.ActionIcon
								aria-label="Bank transfer"
								p={0}
								variant="transparent"
								onClick={() => setCardSelected("BANK_TRANSFER")}
								sx={(t) => ({
									width: "20%",
									minWidth: "auto",
									height: "auto",
									borderRadius: t.radius.md,
									outline:
										cardSelected === "BANK_TRANSFER"
											? `solid 2px ${t.colors.brand[4]} !important`
											: "none",
								})}
							>
								<BankTransferCustomIcon className="h-auto w-auto" />
							</M.ActionIcon>
						</div>
						<M.Group position="right" sx={{ width: "100%" }}>
							<M.Group>
								<Button variant="secondary" onClick={() => setOpened(false)}>
									Cancel
								</Button>
								<Button
									disabled={!cardSelected}
									onClick={() => {
										handleCardClick(cardSelected!)
										setOpened(false)
									}}
								>
									Save
								</Button>
							</M.Group>
						</M.Group>
					</M.Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}

function CardPaymentMethod() {
	const paymentMethods = useAtomValue(cardPaymentMethodsAtom)
	const addCard = useSetAtom(cardPaymentMethodAddAtom)
	const removeCard = useSetAtom(cardPaymentMethodRemoveAtom)
	const amountDue = useAtomValue(amountDueAtom)
	const [amount, setAmount] = React.useState<number>(() => amountDue.toNumber())
	const [opened, setOpened] = React.useState(false)
	const [transactionType, setTransactionType] = React.useState("charge")
	const [cardSelected, setCardSelected] = React.useState<PaymentType | null>(
		null,
	)

	React.useEffect(() => {
		setAmount(amountDue.toNumber())
	}, [amountDue])

	const handleCardClick = React.useCallback(
		(type: PaymentType) => {
			try {
				addCard({
					type,
					paidIn:
						transactionType === "charge" ? new Decimal(amount) : new Decimal(0),
					paidOut:
						transactionType === "refund" ? new Decimal(amount) : new Decimal(0),
				})
			} catch (err) {
				reportUserError({
					title: "Failed to add card payment method",
					message: err.message,
				})
			}
		},
		[addCard, amount, transactionType],
	)

	const handleCardRemoveClick = React.useCallback(
		(index: number) => {
			removeCard(index)
		},
		[removeCard],
	)

	return (
		<>
			<PaymentMethodButton
				label="Credit Debit"
				icon={<CreditDebitPaymentMethodCustomIcon />}
				onOpen={() => setOpened(true)}
				className="rounded hover:bg-blue-50 hover:ring-1 hover:ring-blue-200"
			/>
			<M.Modal
				opened={opened}
				onClose={() => setOpened(false)}
				withCloseButton={false}
				size="auto"
			>
				<M.Stack spacing={4} justify="space-between">
					{paymentMethods.length > 0 && (
						<M.Stack sx={{ width: "100%", maxHeight: 200, overflow: "auto" }}>
							{paymentMethods
								.slice()
								.reverse()
								.map((pm, reversedIndex) => {
									const i = paymentMethods.length - 1 - reversedIndex
									return (
										<M.Group
											key={i}
											spacing={12}
											position="apart"
											p={2}
											sx={{ width: "100%", background: "white" }}
										>
											<M.Group position="left">
												<M.Box
													sx={(t) => ({
														width: "3.85em",
														height: "2.5em",
														borderRadius: t.radius.md,
													})}
												>
													{React.createElement(
														paymentMethodsMap[pm.type].icon,
														{
															size: "giant",
														},
													)}
												</M.Box>
												<M.Text weight="bold">
													{paymentMethodsMap[pm.type].text}
												</M.Text>
											</M.Group>
											<M.Stack align="end" spacing={4}>
												<M.Text
													component="span"
													color={pm.paidOut.gt(0) ? "red" : "green"}
													size="sm"
												>
													{pm.paidOut.gt(0) ? "Refund" : "Charge"}{" "}
													<M.Text component="span" weight="bold" size="sm">
														$
														{(pm.paidOut.gt(0)
															? pm.paidOut
															: pm.paidIn
														)?.toFixed(2)}
													</M.Text>
												</M.Text>
												<M.Button
													size="xs"
													compact
													color="gray"
													variant="light"
													onClick={() => handleCardRemoveClick(i)}
												>
													Remove
												</M.Button>
											</M.Stack>
										</M.Group>
									)
								})}
						</M.Stack>
					)}
					<M.Stack>
						<M.Text color="gray" size="sm">
							Add another credit card
						</M.Text>
						<M.Group>
							<M.Radio.Group
								color="brand"
								size="sm"
								pb="xs"
								onChange={setTransactionType}
								value={transactionType}
							>
								<M.Radio value="charge" label="Charge" />
								<M.Radio value="refund" label="Refund" />
							</M.Radio.Group>
						</M.Group>
					</M.Stack>
					<M.Group sx={{ width: "100%" }}>
						<M.Text sx={{ minWidth: "10ch" }}>Amount</M.Text>
						<M.NumberInput
							type="number"
							max={
								transactionType === "charge" ? amountDue.toNumber() : undefined
							}
							onChange={(e) => e && setAmount(e)}
							value={amount}
							precision={2}
						/>
					</M.Group>
					<M.Group
						spacing={4}
						sx={{ minWidth: "40px", maxWidth: "400px" }}
						noWrap
					>
						<M.ActionIcon
							aria-label="Visa"
							p={0}
							variant="transparent"
							onClick={() => setCardSelected("VISA")}
							sx={(t) => ({
								width: "20%",
								minWidth: "auto",
								height: "auto",
								borderRadius: t.radius.md,
								border:
									cardSelected === "VISA"
										? `solid 2px ${t.colors.brand[4]}`
										: "none",
							})}
						>
							<VisaIcon size="giant" />
						</M.ActionIcon>

						<M.ActionIcon
							aria-label="Mastercard"
							p={0}
							variant="transparent"
							onClick={() => setCardSelected("MASTERCARD")}
							sx={(t) => ({
								width: "20%",
								minWidth: "auto",
								height: "auto",
								borderRadius: t.radius.md,
								border:
									cardSelected === "MASTERCARD"
										? `solid 2px ${t.colors.brand[4]}`
										: "none",
							})}
						>
							<MastercardIcon size="giant" />
						</M.ActionIcon>

						<M.ActionIcon
							aria-label="American Express"
							p={0}
							variant="transparent"
							onClick={() => setCardSelected("AMERICAN_EXPRESS")}
							sx={(t) => ({
								width: "20%",
								minWidth: "auto",
								height: "auto",
								borderRadius: t.radius.md,
								border:
									cardSelected === "AMERICAN_EXPRESS"
										? `solid 2px ${t.colors.brand[4]}`
										: "none",
							})}
						>
							<AmericanExpressIcon size="giant" />
						</M.ActionIcon>

						<M.ActionIcon
							aria-label="Discover"
							p={0}
							variant="transparent"
							onClick={() => setCardSelected("DISCOVER")}
							sx={(t) => ({
								width: "20%",
								minWidth: "auto",
								height: "auto",
								borderRadius: t.radius.md,
								border:
									cardSelected === "DISCOVER"
										? `solid 2px ${t.colors.brand[4]}`
										: "none",
							})}
						>
							<DiscoverIcon size="giant" />
						</M.ActionIcon>
						<M.ActionIcon
							aria-label="Diners club"
							p={0}
							variant="transparent"
							onClick={() => setCardSelected("DINERS_CLUB")}
							sx={(t) => ({
								width: "20%",
								minWidth: "auto",
								height: "auto",
								borderRadius: t.radius.md,
								border:
									cardSelected === "DINERS_CLUB"
										? `solid 2px ${t.colors.brand[4]}`
										: "none",
							})}
						>
							<DinersClubIcon size="giant" />
						</M.ActionIcon>
					</M.Group>
					<M.Group position="right" sx={{ width: "100%" }}>
						<M.Group>
							<M.Button
								color="brand"
								disabled={!cardSelected}
								onClick={() => {
									handleCardClick(cardSelected!)
									setOpened(false)
								}}
							>
								Save
							</M.Button>
							<M.Button
								color="darkGray"
								variant="light"
								onClick={() => setOpened(false)}
							>
								Cancel
							</M.Button>
						</M.Group>
					</M.Group>
				</M.Stack>
			</M.Modal>
		</>
	)
}

function UserCreditsPaymentMethod() {
	const customer = useAtomValue(customerAtom)
	const [userCreditsPaymentMethod, setUserCreditsPaymentMethod] = useAtom(
		userCreditsPaymentMethodAtom,
	)
	const [opened, setOpened] = React.useState(false)

	const [amount, setAmount] = React.useState<string>("0")
	const amountDue = useAtomValue(amountDueAtom)

	const handleSubmit = React.useCallback(() => {
		try {
			const decimalAmount = new Decimal(amount != "" ? amount : "0")
			setUserCreditsPaymentMethod(decimalAmount)
		} catch (e) {
			reportUserError({
				title: "Failed to set cash payment method",
				message: e.message ?? e,
			})
		}
		setOpened(false)
	}, [amount, setUserCreditsPaymentMethod])

	React.useEffect(() => {
		if (customer?.credits.gt(0) && amountDue.lte(customer?.credits)) {
			setAmount(amountDue.plus(userCreditsPaymentMethod).toString())
		}
	}, [amountDue, customer?.credits, userCreditsPaymentMethod])

	return (
		<>
			<PaymentMethodButton
				label="User Credits"
				icon={<UserCreditsPaymentMethodCustomIcon />}
				onOpen={() =>
					customer && customer?.credits?.toNumber() > 0 && setOpened(true)
				}
				className={cn(
					"rounded hover:bg-yellow-50 hover:ring-1 hover:ring-yellow-200",
					!customer || customer?.credits?.toNumber() === 0
						? "cursor-not-allowed opacity-50"
						: "",
				)}
			/>
			<M.Modal
				opened={opened}
				onClose={() => setOpened(false)}
				withCloseButton={false}
				size="auto"
			>
				<M.Stack>
					<M.Group spacing={6} position="apart" sx={{ width: "100%" }}>
						<M.Text>
							User credits available: ${customer?.credits.toNumber().toFixed(2)}
						</M.Text>
						<M.TextInput
							value={amount.toString()}
							onChange={(e) => {
								if (
									e.currentTarget.value.length > 0 &&
									new Decimal(e.currentTarget.value).gt(customer?.credits ?? 0)
								) {
									reportUserError({
										title: "Invalid amount",
										message:
											"Amount cannot be greater than user credits available",
									})
									setAmount(customer?.credits.toString() ?? "0")
									return
								}
								setAmount(e.currentTarget.value ?? 0)
							}}
							sx={{ width: "100%" }}
							disabled={customer?.credits.eq(0) ?? true}
							onInput={(e) => {
								const value = e.currentTarget.value
								const onlyNumbers = value.replace(/[^0-9]/g, "")
								e.currentTarget.value = onlyNumbers
							}}
						/>
					</M.Group>
					<M.Group position="right" sx={{ width: "100%" }}>
						<M.Group>
							<M.Button
								color="brand"
								onClick={handleSubmit}
								disabled={customer?.credits.eq(0) ?? true}
							>
								Save
							</M.Button>
							<M.Button
								color="gray"
								variant="light"
								onClick={() => setOpened(false)}
							>
								Cancel
							</M.Button>
						</M.Group>
					</M.Group>
				</M.Stack>
			</M.Modal>
		</>
	)
}

function PayWithTerminalMethod() {
	const terminal = useTerminal()

	const [payWithTerminalMethod, setPayWithTerminalMethod] = useAtom(
		payWithTerminalMethodAtom,
	)

	const selected = payWithTerminalMethod.selected
	const setSelected = (newSelected: boolean) => {
		setPayWithTerminalMethod((prev) => ({
			...prev,
			selected: newSelected,
		}))
	}

	useEffect(() => {
		if (terminal?.status !== "PAIRED") {
			setSelected(false)
		}
	}, [terminal])

	const disabled = terminal?.status !== "PAIRED"

	return (
		<Center
			bg="gray.50"
			width="116px"
			height="115px"
			position="relative"
			cursor={disabled ? "not-allowed" : "pointer"}
			userSelect="none"
			onClick={() => {
				if (!disabled) {
					setSelected(!selected)
				}
			}}
			rounded="sm"
			border="1px solid transparent"
			_hover={{
				bg: "orange.50",
				border: "1px solid token(colors.orange.200)",
			}}
		>
			<M.Checkbox
				checked={selected}
				size="xs"
				onChange={() => setSelected(!selected)}
				className={css({
					position: "absolute",
					right: 3,
					top: 3,
				})}
				disabled={disabled}
			/>
			<div
				className={css({
					display: "grid",
					rowGap: "2",
					justifyItems: "center",
				})}
			>
				<CardReaderIcon />
				<p
					className={css({
						fontSize: "sm",
						fontWeight: "500",
						color: "gray.600",
						textAlign: "center",
					})}
				>
					Pay with terminal
				</p>
			</div>
		</Center>
	)
}

function CardReaderIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="2em"
			height="2em"
			viewBox="0 0 24 24"
			className={css({
				color: "orange.400",
			})}
		>
			<g fill="none" stroke="currentColor" strokeWidth="1.5">
				<path d="M5 19V3h14v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2Z"></path>
				<path d="M5 6H3.5a1.5 1.5 0 1 1 0-3h17a1.5 1.5 0 0 1 0 3H19"></path>
				<path strokeLinecap="round" strokeLinejoin="round" d="M15 3v18"></path>
			</g>
		</svg>
	)
}
